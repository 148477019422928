








import Vue from "vue";
let $ = require("jquery");
require("@/../node_modules/summernote/dist/summernote-lite");
require("@/../node_modules/summernote/dist/summernote-lite.min.css");
interface Data {
  content: string;
}
export default Vue.extend({
  data(): Data {
    return {
      content: "",
    };
  },
  props: {
    inhalt: {
      type: String,
      default: "",
    },
    edit: {
      type: Boolean,
      default: false,
    },
  },
  created() {
    this.content = this.inhalt;
  },
  watch: {
    edit() {
      this.$nextTick(function () {
        if (this.edit) {
          this.initSummernote();
        }
      });
    },
  },
  mounted() {},
  methods: {
    initSummernote() {
      this.content = this.inhalt;
      let config = {
        height: 800,
        toolbar: [
          ["style", ["style"]],
          ["font", ["bold", "underline", "clear"]],
          ["fontname", ["fontname"]],
          ["fontsize", ["fontsize"]],
          ["color", ["color"]],
          ["para", ["ul", "ol", "paragraph"]],
          ["table", ["table"]],
          ["insert", ["link", "picture", "video"]],
          ["view", ["codeview"]],
        ],
        backColor: "#fafafa",
        foreColor: "red",
        fontNames: ["Roboto", "Roboto Condensed", "Roboto Slab"],
        fontNamesIgnoreCheck: ["Roboto", "Roboto Condensed", "Roboto Slab"],
        defaultFontName: "Roboto",

        colors: [
          ["#042f4c", "#299fe1", "#b22835", "#51b16c"], //first row
          ["black", "red", "green", "blue"], //second row
          ["yellow", "#cddc39", "#228c87", "white"],
        ],
        colorsName: [
          [
            "OnlineRehaBlau",
            "OnlineRehaHellBlau",
            "OnlineRehaRot",
            "OnlineRehaGrün",
          ], //first name row
          ["Schwarz", "Rot", "Grün", "Blau"], //second name row
          ["Gelb", "Hellgrün", "Dunkelgrün", "Weiß"],
        ],
      };
      let vm = this;
      let summernote = $("#summernote");
      summernote.on("summernote.change", function () {
        vm.content = summernote.summernote("code");
        vm.$emit("summernote", summernote.summernote("code"));
      });
      summernote.summernote(config);
    },
  },
});
