













































import AboPaket from "@/data/AboPaket";
import AboBuchenButton from "@/components/abo/patient/AboBuchenButton.vue";

export default {
  components: {
    AboBuchenButton,
  },
  props: {
    abo: {
      type: AboPaket,
      default: null,
    },
    checkBox: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    emitBuyMe(id: number | undefined) {
      this.$emit("abo-selected", id);
    },
  },
};
