














































































































































































































































































import Vue from "vue";
import AuthDialog from "./components/AuthDialog/AuthDialog.vue";
import ErrorDialog from "./components/ErrorDialog/ErrorDialog.vue";
import AlertDialog from "./components/AlertDialog/AlertDialog.vue";
import axios from "axios";
import NavigationsmenueDto from "@/data/inhaltsseite/NavigationsmenueDto";
import Api from "@/config/api";
import { Privilege } from "./data/user/Privilege";
import { mapGetters } from "vuex";
import { Role } from "./data/user/Role";
import helper from "@/helper/types";
import { UrlType } from "./dtos/UrlType";
import urlDto from "@/helper/urlDto";

interface Data {
  navigationsmenue: NavigationsmenueDto[];
  patient: Role;
  therapeut: Role;
  betreiber: Role;
  active: NavigationsmenueDto[];
  menues: boolean[];
  pageTitle: string;
}
export default Vue.extend({
  name: "App",

  components: {
    AuthDialog,
    ErrorDialog,
    AlertDialog,
  },

  data(): Data {
    return {
      navigationsmenue: [],
      patient: Role.PATIENT,
      therapeut: Role.THERAPEUT,
      betreiber: Role.BETREIBER,
      active: [],
      menues: [],
      pageTitle: "",
    };
  },
  head: {
    title: function () {
      return {
        inner: this.pageTitle,
      };
    },
  },
  created() {
    this.getNavigation();
    this.setPageTitle(this.$route.name);
  },
  computed: {
    therapeutMenu() {
      const res = [];
      res.push({
        titel: "Profil",
        id: "t1",
        urlDto: { urlType: UrlType.PROFIL },
        type: "eintrag",
      });
      res.push({
        titel: "Termine",
        id: "t2",
        urlDto: { urlType: UrlType.TERMIN_THERAPEUT },
        type: "eintrag",
      });
      res.push({
        titel: "Videos",
        id: "t3",
        urlDto: { urlType: UrlType.VIDEOS },
        type: "eintrag",
      });
      if (
        this.$store.getters.hasPrivilege(Privilege.UEBUNGEN_VERWALTEN_THERAPEUT)
      ) {
        res.push({
          titel: "Übungen verwalten",
          id: "t4",
          urlDto: { urlType: UrlType.UEBUNGEN_VERWALTEN },
          type: "eintrag",
        });
      }
      if (
        this.$store.getters.hasPrivilege(
          Privilege.TRAININGSPLAN_VORLAGEN_VERWALTEN
        )
      ) {
        res.push({
          titel: "Vorlagen verwalten",
          id: "t5",
          urlDto: { urlType: UrlType.TRAININGSPLAN_VORLAGEN_VERWALTEN },
          type: "eintrag",
        });
      }
      return res;
    },
    betreiberMenu() {
      const res = [];
      if (this.$store.getters.hasPrivilege(Privilege.DATEIEN_HOCHLADEN)) {
        res.push({
          titel: "Dateien hochladen",
          id: "b1",
          urlDto: { urlType: UrlType.DATEIEN_HOCHLADEN },
          type: "eintrag",
        });
      }
      if (this.$store.getters.hasPrivilege(Privilege.FRAGEBOGEN_EDITIEREN)) {
        res.push({
          titel: "Fragebogen editieren",
          id: "b2",
          urlDto: { urlType: UrlType.FRAGEBOGEN_EDITIEREN },
          type: "eintrag",
        });
      }
      if (this.$store.getters.hasPrivilege(Privilege.NAVIGATION_EDITIEREN)) {
        res.push({
          titel: "Navigation editieren",
          id: "b3",
          urlDto: { urlType: UrlType.NAVIGATION_EDITIEREN },
          type: "eintrag",
        });
      }
      if (this.$store.getters.hasPrivilege(Privilege.TERMINE_EDITIEREN)) {
        res.push({
          titel: "Termine editieren",
          id: "b4",
          urlDto: { urlType: UrlType.TERMIN_BETREIBER },
          type: "eintrag",
        });
      }
      if (this.$store.getters.hasPrivilege(Privilege.BENUTZER_VERWALTEN)) {
        res.push({
          titel: "Benutzer verwalten",
          id: "b5",
          urlDto: { urlType: UrlType.BENUTZER_VERWALTEN },
          type: "eintrag",
        });
      }
      if (this.$store.getters.hasPrivilege(Privilege.GUTHABEN_AENDERN)) {
        res.push({
          titel: "Rezepte Verwalten",
          id: "b6",
          urlDto: { urlType: UrlType.REZEPTE_VERWALTEN },
          type: "eintrag",
        });
      }
      if (this.$store.getters.hasPrivilege(Privilege.UEBUNGEN_VERWALTEN)) {
        res.push({
          titel: "Übungen verwalten",
          id: "b7",
          urlDto: { urlType: UrlType.UEBUNGEN_VERWALTEN },
          type: "eintrag",
        });
      }
      if (this.$store.getters.hasPrivilege(Privilege.ANWENDUNG_VERWALTEN)) {
        res.push({
          titel: "Anwendung verwalten",
          id: "b8",
          urlDto: { urlType: UrlType.ANWENDUNG_VERWALTEN },
          type: "eintrag",
        });
      }
      /* // deaktiviert da die Seite nicht belegt ist.
      if (this.$store.getters.hasPrivilege(Privilege.ZAHLUNGEN_VERWALTEN)) {
        res.push({
          titel: "Zahlungen verwalten",
          id: "b9",
          urlDto: { urlType: UrlType.ZAHLUNGEN_VERWALTEN },
          type: "eintrag",
        });
      }*/
      if (this.$store.getters.hasPrivilege(Privilege.ABOPAKETE_VERWALTEN)) {
        res.push({
          titel: "Abopakete verwalten",
          id: "b9",
          urlDto: { urlType: UrlType.ABOPAKETE_VERWALTEN },
          type: "eintrag",
        });
      }
      if (this.$store.getters.hasPrivilege(Privilege.BENUTZER_VERWALTEN)) {
        res.push({
          titel: "Firmen verwalten",
          id: "b10",
          urlDto: { urlType: UrlType.FIRMEN_VERWALTEN },
          type: "eintrag",
        });
      }
      if (this.$store.getters.hasPrivilege(Privilege.VIDEOS_BEARBEITEN)) {
        res.push({
          titel: "Videos verwalten",
          id: "b11",
          urlDto: { urlType: UrlType.VIDEOS },
          type: "eintrag",
        });
      }

      return res;
    },
    ...mapGetters(["loggedIn", "isRole"]),
    conditionalRendering: function () {
      return {
        landingBack:
          this.$route.path === "/" && this.$vuetify.breakpoint.mdAndUp,
        landingSmallBack:
          this.$route.path === "/" && !this.$vuetify.breakpoint.mdAndUp,
        noBorders: this.$route.path.includes("/video/therapie"),
      };
    },
    noBordersVideotherapie: function () {
      return {
        noBorders: this.$route.path.includes("/video/therapie"),
      };
    },
    appMode: function () {
      try {
        return (
          this.$route.path.includes("/video/therapie/app") ||
          window.self !== window.top
        ); // videoapp oder im iframe
      } catch (e) {
        return false;
      }
    },
  },
  watch: {
    $route(to, from) {
      if (to && to.name != "Seite") {
        this.setPageTitle(
          to.meta && to.meta.pageTitle ? to.meta.pageTitle : to.name
        );
      }
    },
    // Aktualisiere das Menü nach dem ein- und ausloggen
    "$store.state.authentication.token": function () {
      this.getNavigation();
    },
    "$store.state.menueChanged": function () {
      this.$store.state.menueChanged = false;
      this.navigationsmenue = [];
      this.getNavigation();
    },
    active() {
      if (this.active.length > 0) {
        const eintrag = this.active[0];
        if (helper.isEintrag(eintrag)) {
          const url = urlDto.getUrl(eintrag.urlDto);
          this.$router
            .push({
              path: url,
            })
            .catch(() => {});
          for (const i in this.menues) {
            this.menues[i] = false;
          }
        }
        this.active = [];
      }
    },
  },
  methods: {
    getNavigation() {
      axios
        .get<NavigationsmenueDto[]>("/navigation", Api.getRequestConfig())
        .then((response) => {
          this.navigationsmenue = response.data;
        })
        .catch(() => {
				  window.alert("Verbindungsfehler!")
        });
    },
    goHome() {
      this.$router.push("/").catch(() => {});
    },
    setPageTitle(titel: string) {
      this.pageTitle = titel;
      this.$emit("updateHead");
    },
  },
});
