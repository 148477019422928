






































import TerminApi from "@/services/api/Termin";
import axios from "axios";
import Api from "@/config/api";
import { Route } from "vue-router";

export default {
  props: {
    termin: Object,
    patient: Object,
    links: Boolean,
  },
  data: () => ({
    teilgenommen: false,
    dialogPatient: false,
    habenAbo: [],
    dialogSeite: "",
  }),
  created() {
    this.teilgenommen = this.patient.teilgenommen;
    this.habenAbo = [];
    axios
      .get(
        Api.getRequestConfig().baseURL +
          "/user/abo/" +
          this.patient.userId +
          "/privileges",
        {
          headers: Api.getRequestConfig().headers,
        }
      )
      .then((response) => {
        this.habenAbo.push({ id: this.patient.userId, hatAbo: response.data });
      })
      .catch(() => {
        window.alert("Verbindungsfehler!")
      });
  },
  computed: {
    usrIframeSrc: {
      get: function () {
        return this.dialogSeite == "trainingsplan"
          ? `./trainingsplan/${this.patient.patientId}`
          : `../profil/${this.patient.userId}`;
      },
    },
  },
  methods: {
    changeHatTeilgenommen() {
      const terminAnmeldung = this.termin.teilnehmerList.find((elem: any) => {
        return elem.patientId === this.patient.patientId;
      });
      terminAnmeldung.teilgenommen = this.teilgenommen;
      TerminApi.changeTeilgenommen(terminAnmeldung, this.termin.id);
    },
    showProfile() {
      this.dialogSeite = "profil";
      this.dialogPatient = true;
    },
    showTrainingsplan() {
      this.dialogSeite = "trainingsplan";
      this.dialogPatient = true;
      /*
      this.$router
        .push({
          name: "Therapeut: Trainingsplan",
          params: { patient: this.patient.patientId },
        })
        .catch(() => {}); */
    },
    hatAbo() {
      try {
        return this.habenAbo.find((x: any) => x.id === this.patient.userId)
          .hatAbo;
      } catch (error) {
        // Do nothing
      }
    },
    closeDialog() {
      this.dialogPatient = false;
      this.dialogSeite = "profil";
    },
    goBack() {
      let iframe = document.getElementById(
        "nutzerdatenframe"
      ) as HTMLIFrameElement;
      if (iframe.contentWindow != null) {
        iframe.contentWindow.history.back();
      }
    },
  }, //methods
};
