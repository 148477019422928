import WebsocketMessageOutgoing from "./WebsocketMessageOutgoing";

export default class LockConferenceMessage implements WebsocketMessageOutgoing {
  command: string;
  bearer: string = "";
  constructor(bearer: string) {
    this.command = "lockConference";
    this.bearer = bearer;
  }
}
