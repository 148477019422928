
































































import PatientTrainingsplanCard from "./PatientTrainingsplanCard.vue";
import PatientApi from "@/services/api/Patient";
import TrainingsplanApi from "@/services/api/Trainingsplan";
import UebungApi from "@/services/api/Uebung";
import axios from "axios";
import Api from "@/config/api";
export default {
  components: {
    PatientTrainingsplanCard,
  },
  data: () => ({
    trainingsplan: [],
    hatAbo: false,
    uebungen: [],
    userId: null,
    patientObject: null,
    uebungsIds: [],
    videoImgs: [],
    loading: true,
  }),
  created() {
    this.userId = this.$store.state.authentication.userId;
    axios
      .get(
        Api.getRequestConfig().baseURL +
          "/user/abo/" +
          this.userId +
          "/privileges",
        {
          headers: Api.getRequestConfig().headers,
        }
      )
      .then(response => {
        this.hatAbo = response.data;
        this.loading = false;
        PatientApi.getPatientByUserId(this.userId)
          .then(response => {
            this.patientObject = response?.data;
            TrainingsplanApi.getMyTrainingsplan()
              .then(response => {
                this.trainingsplan = response?.data;
                for (const uebungsset of this.trainingsplan) {
                  this.uebungsIds.push(uebungsset.uebungId);
                }
                UebungApi.getUebungenByIds(this.uebungsIds.toString())
                  .then(response => {
                    this.uebungen = response;
                    for (const uebung of this.uebungen) {
                      /*
                      UebungApi.getVideoInfo(uebung.videoUrl)
                        .then(responseImg => {
                          this.videoImgs.push({
                            id: uebung.id,
                            img: responseImg.data.thumbnail_url,
                          });
                        })
                        .catch(() => {
                          this.loading = false;
                        });
                        */
                    }
                  })
                  .catch(() => {
                    window.alert("Verbindungsfehler!")
                    this.loading = false;
                  });
              })
              .catch(() => {
                window.alert("Verbindungsfehler!")
                this.loading = false;
              });
          })
          .catch(() => {
            window.alert("Verbindungsfehler!")
            this.loading = false;
          });
      })
      .catch(() => {
        window.alert("Verbindungsfehler!")
        this.loading = false;
      });
  },
  methods: {
    getCorrespondingUebung(id: number) {
      return this.uebungen.find((uebung: any) => {
        return uebung.id === id;
      });
    },
  },
};
