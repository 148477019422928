import WebsocketMessageOutgoing from "./WebsocketMessageOutgoing";

export default class KickMemberMessage
  implements WebsocketMessageOutgoing {
  command = "";
  memberName = "";
  bearer = "";
  constructor(bearer: string, memberName : string) {
    this.command = "kickMember";
    this.memberName = memberName;
    this.bearer = bearer;
  }
}
