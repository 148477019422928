






























































import Vue from "vue";
import Api from "@/config/api";
import axios from "axios";
import AboPaketDto from "@/dtos/AboPaketDto";
import AboPaket from "@/data/AboPaket";
import AboBuchungCard from "@/components/abo/patient/AboBuchungCard.vue";
import SeiteComponent from "@/components/inhalt/Seite.vue";
import router from "@/router";

export default {
  components: {
    AboBuchungCard,
  },
  data() {
    return {
      abos: [] as Array<AboPaket>,
    };
  },
  methods: {
    aboCardEventHandler(aboId: number | undefined) {
      console.log("Booking for this id has been started: " + aboId);
      this.aboBuchen(aboId);
    },
    aboBuchen(aboId: number) {
      const url = "/abo/patient/placeOrder/" + aboId;
      axios
        .get(url, Api.getRequestConfig())
        .then((response) => {
          if (response.data === "") {
            this.$router.push("/profil");
          } else {
            window.location.replace(response.data);
          }
        })
        .catch((errorResponse) => {
				  window.alert("Verbindungsfehler!")
          const status = errorResponse.response.status;
          if (status == 404 || status == 400 || status == 500) {
            this.$store.state.error = {
              isVisible: true,
              headline: "Fehler bei der Buchung",
              text: errorResponse.response.data,
              okAction: function () {
                this.$store.state.error.isVisible = false;
              },
            };
          }
        });
    },
    getStandardAboSet() {
      const url = "/abo/patient/getStandard";
      axios
        .get<Array<AboPaketDto>>(url, Api.getRequestConfig())
        .then((response) => {
          response.data.forEach((abo) => {
            this.abos.push(
              new AboPaket(
                abo.aboPaketId,
                abo.trainingsplaene,
                abo.befundtermine,
                abo.einzelTermineProQuartal,
                abo.gruppenTermineProQuartal,
                abo.laufzeitMonate,
                abo.buchungMonate,
                abo.kostenProQuartalCent,
                abo.title,
                abo.kuendigungsfristMonate,
                abo.aktiv,
                abo.buchbar
              )
            );
          });
        })
        .catch(() => {
				  window.alert("Verbindungsfehler!")
        });
    },
  },
  created() {
    this.getStandardAboSet();
  },
};
