



































import { mapMutations } from "vuex";
import api from "../../config/api";
import Axios from "axios";
import ProfilBenutzerDto from "@/data/ProfilBenutzerDto";
export default {
  props: {
    info: {
      type: Object,
      default: null,
    },
    user: {
      type: Object,
      default: null,
    },
  },
  data: () => ({
    editPhone: false,
    dialogEditPhone: false,
    phoneModel: null,
  }),
  created() {
    this.phoneModel = this.info.telefon;
  },
  computed: {
    ...mapMutations(["setNetworkError"])
  },
  methods: {
    changePhone() {
      if (!this.editPhone) {
        this.editPhone = true;
      } else {
        this.dialogEditPhone = true;
      }
    },
    editPhoneSave() {
      this.info.telefon = this.phoneModel;
      this.dialogEditPhone = false;
      this.editPhone = false;

      const profil: ProfilBenutzerDto = new ProfilBenutzerDto(
        this.info.vorname,
        this.info.nachname,
        "",
        "",
        this.info.hausnummer,
        this.info.straße,
        this.info.plz,
        this.info.ort,
        this.info.land,
        this.info.profilbild,
        this.info.ausbildung,
        this.info.schwerpunkte,
        this.info.beschreibung,
        this.info.geburtsdatum,
        this.info.telefon,
        this.info.listenanzeige
      );
      let requestUrl = "/profil/change";
      if (this.user !== null) {
        requestUrl = "/profil/other/" + this.user.id + "/betreiber";
      }
      Axios.post(requestUrl, profil, api.getRequestConfig())
      .catch((error: Error) => {
        window.alert("Verbindungsfehler!")
        this.$store.commit("setNetworkError");
      })
    },
    editPhoneDiscard() {
      this.phoneModel = this.info.telefon;
      this.dialogeditPhone = false;
      this.editPhone = false;
    },
    editPhoneBack() {
      this.dialogEditPhone = false;
    },
  }
}
