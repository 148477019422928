




















export default {
  components: {},
  props: {
    video: {
      type: MediaStream,
      default: null,
    },
    active: {
      type: Boolean,
      default: false,
    },
    height: {
      type: String,
      default: "800px",
    },
    patientName: {
      type: String,
      default: "",
    },
  },
  data: () => ({}),
  methods: {},
};
