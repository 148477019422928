import WebsocketMessageOutgoing from "./WebsocketMessageOutgoing";

export default class EndMeldungByTherapeutMessage implements WebsocketMessageOutgoing {
	command: string;
	bearer: string = "";
	signallingMemberUuid: string = "";

	constructor(bearer: string, memberUuid: string) {
		this.command = "endMeldung";
		this.signallingMemberUuid = memberUuid;
		this.bearer = bearer;
	}
}
