














































































import GuthabenAngebot from "@/data/GuthabenAngebot";

export default {
  data: () => ({}),
  props: {
    angebot: {
      type: GuthabenAngebot,
      default: null,
    },
    checkBox: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    emitBuyMe(id: number | undefined) {
      if (this.checkBox) {
        this.$emit("angebot-selected", id);
      }
    },
  },
};
