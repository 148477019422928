







































import Files from "@/services/api/Files";

export default {
  data: () => ({
    profilePhoto: "",
  }),
  props: {
    info: {
      type: Object,
      default: null,
    },
    user: {
      type: Object,
      default: null,
    },
    profilViewType: {
      type: Number,
      default: null,
    },
  },
  created() {
    Files.getProfilePhoto(this.info.profilbild).then(response => {
      this.profilePhoto = response;
    });
  },
};
