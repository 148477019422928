import WebsocketMessageOutgoing from "./WebsocketMessageOutgoing";

export default class PingMessage implements WebsocketMessageOutgoing {
	command: string;
	bearer: string = "";

	constructor(bearer: string) {
		this.command = "ping";
		this.bearer = bearer;
	}
}
