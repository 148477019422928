























import FragebogenApi from "../../services/api/Fragebogen";
import PatientFragebogenList from "./PatientFragebogenList.vue";
import PatientFragebogenSingle from "./PatientFragebogenSingle.vue";
import PatientApi from "@/services/api/Patient";

export default {
  components: {
    PatientFragebogenList,
    PatientFragebogenSingle,
  },
  head: {
    title: {
      inner: "Befundfragebögen",
    },
  },
  data() {
    return {
      currentSelection: null,
      items: [],
      showList: true,
      patientId: null,
      patientObject: null,
    };
  },
  created() {
    if (this.$route.params.patientId) {
      this.patientId = this.$route.params.patientId;
      this.$store.commit("selectedPatientId", this.patientId);
    } else {
      this.patientId = this.$store.state.selectedPatientId;
    }
    PatientApi.getPatientByPatientId(this.patientId)
      .then(response => {
        this.patientObject = response?.data;
        FragebogenApi.getPatientFragebogen(this.patientId).then(response => {
          response.sort(function(x: any, y: any) {
            if (x.veroeffentlicht === null) {
              return -1;
            }
            if (x.veroeffentlicht < y.veroeffentlicht) {
              return 1;
            }
            if (x.veroeffentlicht > y.veroeffentlicht) {
              return -1;
            }
            return 0;
          });
          this.items = response;
        });
      })
      .catch(e => {
        console.log(e);
      });
  },
  methods: {
    changeSelection: function(event: any) {
      this.currentSelection = event;
    },

    changeDraftStatus: function(event: any) {
      for (const item of this.items) {
        if (item.id == event.id) {
          item.veroeffentlicht = event.veroeffentlicht;
        }
        this.showList = false;
        setTimeout(() => {
          this.showList = true;
        }, 1);
      }
      for (let item of this.items) {
        if (item.id == event.id) {
          item = event;
        }
      }
    },
  },
};
