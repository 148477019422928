<template>
  <v-dialog v-model="isVisible" max-width="50%">
    <v-card class="error-card">
      <v-card-title class="headline">
        <h3 class="error-title">
          {{
          this.$store.state.error.headline
          }}
        </h3>
      </v-card-title>
      <v-card-text>{{ this.$store.state.error.text }}</v-card-text>
      <v-card-actions>
        <v-btn color="primary" class="error-ok-button" @click="dismiss">OK</v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data: () => {
    return {
      email: "",
      password: "",
      dialog: false,
    };
  },
  computed: {
    isVisible() {
      return this.$store.state.error.isVisible;
    },
  },
  methods: {
    dismiss() {
      this.$store.state.error.isVisible = false;
      this.$store.state.error.okAction();
    },
  },
};
</script>

<style scoped>
.error-title {
  letter-spacing: 0.2em !important;
  color: rgb(0, 73, 100);
  font-size: 26px !important;
  font-weight: 500 !important;
}
.error-card {
  z-index: 999;
  border: 3px #042f4c solid !important;
  border-top-width: 20px !important;
}
.error-ok-button {
  margin-left: 3%;
}
</style>
