import { render, staticRenderFns } from "./Summernote.vue?vue&type=template&id=26247a5d&"
import script from "./Summernote.vue?vue&type=script&lang=ts&"
export * from "./Summernote.vue?vue&type=script&lang=ts&"
import style0 from "./Summernote.vue?vue&type=style&index=0&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports