


























import TrainingsplanApi from "@/services/api/Trainingsplan";
import draggable from "vuedraggable";
import TrainingsplanAnzeigeCardComponent from "./TrainingsplanAnzeigeCard.vue";

export default {
  props: {
    trainingsplan: Array,
  },
  components: {
    draggable,
    TrainingsplanAnzeigeCardComponent,
  },
  data: () => ({
    uebungenFuerPatient: [],
    uebungenIdsSortiert: [],
  }),
  mounted() {
    this.trainingsPlanUpdated();
  },
  watch: {
    trainingsplan: function (newVal: any, oldVal: any) {
      this.trainingsPlanUpdated();
    },
  },
  methods: {
    trainingsPlanUpdated() {
      this.uebungenFuerPatient = [];
      if (this.trainingsplan === undefined || this.trainingsplan.length == 0) return;
      for (const uebungsset of this.trainingsplan) {
        this.uebungenFuerPatient.push(uebungsset);
      }
      this.uebungenIdsSortiert = this.uebungenFuerPatient.map(
        (uebung: any) => uebung.id
      );
    },    
    bearbeiteUebung(uebung: any) {
      this.$emit("bearbeiteUebung", uebung);
    },
    callRemoveDialog(uebung: any) {
      this.$emit("callRemoveDialog", uebung);
    },
    itemDragged(event: any) {
      let i = 1;
      for (const uebungsset of this.uebungenFuerPatient) {
        if (uebungsset.id !== this.uebungenIdsSortiert[i - 1]) {
          TrainingsplanApi.setPositionForUebung(uebungsset.id, i)
            .then()
            .catch((error) => {
              throw error;
            });
        }
        this.uebungenIdsSortiert[i - 1] = uebungsset.id;
        i++;
      }
    },
  },
};
