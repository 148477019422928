export default class AboPaket {
	private aboPaketId: number | undefined;
	private trainingsplaene: number;
	private befundtermine: number;
	private einzelTermineProQuartal: number;
	private gruppenTermineProQuartal: number;
	private laufzeitMonate: number;
	private buchungMonate: number;
	private kostenProQuartalCent: number;
	private title: string;
	private kuendigungsfristMonate: number;
	private buchbar: boolean;
	private aktiv: boolean;

	constructor(aboPaketId: number | undefined,
		trainingsplaene: number,
		befundtermine: number,
		einzelTermineProQuartal: number,
		gruppenTermineProQuartal: number,
		laufzeitMonate: number,
		buchungMonate: number,
		kostenProQuartalCent: number,
		title: string,
		kuendigungsfristMonate: number,
		aktiv: boolean,
		buchbar: boolean) {
		this.aboPaketId = aboPaketId;
		this.trainingsplaene = trainingsplaene;
		this.befundtermine = befundtermine;
		this.einzelTermineProQuartal = einzelTermineProQuartal;
		this.gruppenTermineProQuartal = gruppenTermineProQuartal;
		this.laufzeitMonate = laufzeitMonate;
		this.buchungMonate = buchungMonate;
		this.kostenProQuartalCent = kostenProQuartalCent;
		this.title = title;
		this.kuendigungsfristMonate = kuendigungsfristMonate;
		this.buchbar = buchbar;
		this.aktiv = aktiv;
	}

}
