/**
 * Use this for adding new videos to send to the backend
 */
export default class VideoCreateDto {
	titel: string;
	kurzbeschreibung: string;
	vimeolink: string;
	vimeobild: string;
	langtext: string;
	firmen: [];


	constructor(
		titel: string,
		kurzbeschreibung: string,
		vimeolink: string,
		vimeobild: string,
		langtext: string,
		firmen: []) {
		this.titel = titel;
		this.kurzbeschreibung = kurzbeschreibung;
		this.vimeobild = vimeobild;
		this.vimeolink = vimeolink;
		this.langtext = langtext;
		this.firmen = firmen;
	}

}