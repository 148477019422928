export default class Uebung {
  id: number | null;
  titel: string;
  beschreibung: string;
  videoUrl: string;
  videoVorschauBildUrl: string;

  constructor(id: number | null, titel: string, beschreibung: string, videoUrl: string, videoVorschauBildUrl: string) {
    this.id = id;
    this.titel = titel;
    this.beschreibung = beschreibung;
    this.videoUrl = videoUrl;
    this.videoVorschauBildUrl = videoVorschauBildUrl;
  }

  static emptyInstance() {
    return new Uebung(null, "", "", "","");
  }
}
