








































































































import { add } from "date-fns";
import TerminApi from "@/services/api/Termin";
import DokumentApi from "@/services/api/Dokument";
import { mapMutations } from "vuex";
import PatientenListe from "./../PatientenListe.vue";
import format from "date-fns/format";
import deLocale from "date-fns/locale/de";
import axios from "axios";
import Api from "@/config/api";
import isSameDay from "date-fns/isSameDay";
import { Termintyp } from "@/services/api/Termintyp";
import Therapeut from '@/data/Therapeut';

export default {
  components: {
    PatientenListe,
  },
  props: {
    termin: Object,
    cart: Array,
  },
  data: function () {
    return {
      terminTypEinzel: Termintyp[Termintyp.EINZEL],
      terminTypGruppe: Termintyp[Termintyp.GRUPPE],
      terminTypTelefon: Termintyp[Termintyp.TELEFON],
      terminTypAnamnese: Termintyp[Termintyp.ANAMNESE],
      terminTypBefund: Termintyp[Termintyp.BEFUND],
      checkbox: false,
      showPatientenLinks: false,
    };
  },
  created() {
    const url: string = Api.getRequestConfig().baseURL + "/therapeut/therapeut/my";
    return axios.get(url, {
      headers: Api.getRequestConfig().headers,
    }).then((response) => {
      this.showPatientenLinks = response.data.firmaId == null;
    }).catch(() => {
      window.alert("Verbindungsfehler!")
    });
  },
  computed: {
    showStartButton() {
      return this.isDue && this.termin.termintyp != this.terminTypTelefon;
    },
    isDue() {
      const sichtbarAb = new Date(this.termin.datum).getTime() - 1000 * 60 * 10;
      const sichtbarBis =
        new Date(this.termin.datum).getTime() + this.termin.dauer * 1000 * 60;
      return Date.now() > sichtbarAb && Date.now() < sichtbarBis;
    },
    isNotCancelable() {
      return Date.now() - new Date(this.termin.datum).getTime() > 24 * 3600;
    },
    isToday() {
      return isSameDay(Date.now(), new Date(this.termin.datum));
    },
    ...mapMutations(["selectedTermine"]),
  },
  watch: {
    cart: function (newVal: any) {
      if (newVal.includes(this.termin)) {
        this.checkbox = true;
      } else {
        this.checkbox = false;
      }
    },
  },
  methods: {
    removeFromCart() {
      this.$emit("update:removeFromCart", this.termin);
      this.checkbox = false;
    },
    addToCart() {
      this.$emit("update:addToCart", this.termin);
      this.checkbox = true;
    },
    checkboxClickAction() {
      if (this.checkbox) {
        this.$emit("update:addToCart", this.termin);
      } else {
        this.$emit("update:removeFromCart", this.termin);
      }
    },
    stopPropagation: function (event: any) {
      //verhindert, dass das Panel ein- und ausklappt.
      event.stopPropagation();
    },
    startSession() {
      this.$router
        .push({
          name: "videosessionTherapeut",
          params: { conferenceId: this.termin.id },
        })
        .catch(() => {});
    },
    formateDate(date: string) {
      if (date == "") return "";
      return new Date(date).toLocaleDateString();
    },
    formateTime(date: string) {
      if (date == "") return "";
      return format(new Date(date), "p", { locale: deLocale });
    },
    getEndTime(termin: any) {
      return add(new Date(termin.datum), { minutes: termin.dauer });
    },
    istHeute(date: any) {
      const today = new Date().toLocaleDateString();
      const givenDate = new Date(date).toLocaleDateString();
      if (today === givenDate) {
        return true;
      } else {
        return false;
      }
    },
    addSelectedTermine() {
      this.$store.commit("selectedTermine", this.termin);
    },
    isSelected() {
      if (this.$store.state.selectedTermine.indexOf(this.termin) > -1) {
        return true;
      } else {
        return false;
      }
    },
    getColor() {
      if (this.$store.state.selectedTermine.indexOf(this.termin) > -1) {
        return "#f5f6f7";
      } else {
        return "white";
      }
    },
    getHeaderBorder() {
      if (this.$store.state.selectedTermine.indexOf(this.termin) > -1) {
        return "2px solid rgb(0,73,100)";
      } else {
        return "";
      }
    },
  },
};
