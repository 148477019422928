import WebsocketMessageOutgoing from "./WebsocketMessageOutgoing";

export default class MuteMessage implements WebsocketMessageOutgoing {
  command: string;
  bearer: string = "";
  mute: boolean;
  excludeMemberUuid: string;

  constructor(bearer: string, mute: boolean, excludeMemberUuid: string) {
    this.command = "mute";
    this.mute = mute;
    this.bearer = bearer;
    this.excludeMemberUuid = excludeMemberUuid;
  }
}
