


















































































































































import PatientApi from "@/services/api/Patient";
import UebungApi from "@/services/api/Uebung";
import TrainingsplanApi from "@/services/api/Trainingsplan";
import TrainingsplanListeComponent from "./TrainingsplanListe.vue";
import TrainingsplanAnzeigeComponent from "./TrainingsplanAnzeige.vue";
import TrainingsplanFormularComponent from "./TrainingsplanFormular.vue";

import { mapMutations, mapGetters } from "vuex";
import router from "@/router";
export default {
  components: {
    TrainingsplanAnzeigeComponent,
    TrainingsplanListeComponent,
    TrainingsplanFormularComponent,
  },
  data: () => ({
    patientId: null,
    patientObject: { vorname: "", nachname: "" },
    uebungen: [],
    trainingsplan: [],
    trainingsplanSets: [],
    selectedSet: null,
    freieUebungen: [],
    uebungenInTrainingsplan: [],
    selectedUebung: null,
    removeDialog: false,
    showFormular: false,
    selectedUebungsSatz: null,
    dataLoaded: 0,
    newSetDialog: false,
    changeDateDialog: false,
    pickerTime: null,
    pickerDate: null,

    selectedVorlage: undefined,
    planVorlagen: [],
  }),
  created() {
    if (this.$route.params.patient) {
      this.patientId = this.$route.params.patient;
      this.$store.commit("selectedPatientId", this.patientId);
    } else {
      this.patientId = this.$store.state.selectedPatientId;
    }
    this.getData();
  },
  computed: {
    ...mapMutations(["selectedPatientId"]),
    ...mapGetters(["hasPrivilege"]),
  },
  methods: {
    getData(selectedId: number | null | undefined) {
      PatientApi.getPatientByPatientId(this.patientId).then((response) => {
        this.patientObject = response?.data;
        TrainingsplanApi.getTrainingsplanSetsForPatient(this.patientId).then(
          (response) => {
            this.trainingsplanSets = response?.data;

            TrainingsplanApi.getTrainingsplanForPatient(this.patientId).then(
              (response) => {
                this.trainingsplan = response?.data;
                this.trainingsplanSets.forEach((planSet: any) => {
                  if (selectedId != null && selectedId != undefined) {
                    if (selectedId == planSet.id) {
                      this.selectedSet = planSet;
                      this.trainingsplan = planSet.planListe;
                      this.pickerDate = this.selectedSet.validFrom.split(
                        "T"
                      )[0];
                      const timeOnly: string = this.selectedSet.validFrom.split(
                        "T"
                      )[1];
                      this.pickerTime =
                        timeOnly.split(":")[0] + ":" + timeOnly.split(":")[1];
                    }
                  } 
                  else if (this.trainingsplan!== null && this.trainingsplan !== undefined && this.trainingsplan.length>0 && this.trainingsplan[0].setId == planSet.id) {
                    this.selectedSet = planSet;
                    this.pickerDate = this.selectedSet.validFrom.split("T")[0];
                    const timeOnly: string = this.selectedSet.validFrom.split(
                      "T"
                    )[1];
                    this.pickerTime =
                      timeOnly.split(":")[0] + ":" + timeOnly.split(":")[1];
                  }                  
                });

                if(this.trainingsplan !== undefined){
                    UebungApi.getAllUebungenSortiertNachTitel().then((response) => {
                    this.uebungen = response?.data;
                    
                      this.freieUebungen = this.uebungen.filter((uebung: any) => {
                        for (const uebungssatz of this.trainingsplan) {
                          if (uebung.id === uebungssatz.uebungId) {
                            return false;
                          }
                        }
                        return true;
                      });
                    
                    
                    this.uebungenInTrainingsplan = this.uebungen.filter(
                      (uebung: any) => {
                        for (const uebungssatz of this.trainingsplan) {
                          if (uebung.id === uebungssatz.uebungId) {
                            uebungssatz.titel = uebung.titel;
                            uebungssatz.videoUrl = uebung.videoUrl;
                            uebungssatz.videoVorschauBildUrl =
                              uebung.videoVorschauBildUrl;
                            return true;
                          }
                        }
                        return false;
                      }
                    );
                    this.dataLoaded++;
                  });
                }
              }
            )
            .catch(error => {
              console.debug(error)
            });
          }
        );
      });
    },
    hasSetSelected(){
      console.log(this.selectedSet)
      return !(this.selectedSet===null)
    },
    swapSelectedSet() {
      this.dataLoaded = 0;
      TrainingsplanApi.getTrainingsplanBySetId(this.selectedSet.id).then(
        (response) => {
          this.trainingsplan = response?.data;
          this.pickerDate = this.selectedSet.validFrom.split("T")[0];
          const timeOnly: string = this.selectedSet.validFrom.split("T")[1];
          this.pickerTime =
            timeOnly.split(":")[0] + ":" + timeOnly.split(":")[1];
          UebungApi.getAllUebungenSortiertNachTitel().then((response) => {
            this.uebungen = response?.data;
            this.freieUebungen = this.uebungen.filter((uebung: any) => {
              for (const uebungssatz of this.trainingsplan) {
                if (uebung.id === uebungssatz.uebungId) {
                  return false;
                }
              }
              return true;
            });
            this.uebungenInTrainingsplan = this.uebungen.filter(
              (uebung: any) => {
                for (const uebungssatz of this.trainingsplan) {
                  if (uebung.id === uebungssatz.uebungId) {
                    uebungssatz.titel = uebung.titel;
                    uebungssatz.videoUrl = uebung.videoUrl;
                    uebungssatz.videoVorschauBildUrl =
                      uebung.videoVorschauBildUrl;
                    return true;
                  }
                }
                return false;
              }
            );
            this.dataLoaded++;
          });
        }
      );
    },
    callRemoveDialog(uebung: any) {
      this.selectedUebung = uebung;
      this.removeDialog = true;
    },
    bearbeiteUebungSatz(uebungssatz: any) {
      this.selectedUebungsSatz = uebungssatz;
      let uebung = null
      this.uebungen.forEach((element: any) => {
        if(element.id == uebungssatz.uebungId) {
          uebung = element;
        }
      })
      this.selectedUebung = uebung
      this.showFormular = true;
    },
    removeUebung(uebungId: number) {
      TrainingsplanApi.deleteTrainingsplan(uebungId).then(() => {
        this.selectedUebung = null;
        this.removeDialog = false;
        this.getData();
      });
    },
    addUebungZumTrainingsplan(uebung: any) {
      this.selectedUebung = uebung;
      this.showFormular = true;
    },
    closeFormular() {
      this.selectedUebung = null;
      this.selectedUebungsSatz = null;
      this.showFormular = false;
    },
    saveUebung(uebungsset: any, uebungId: number) {
      if (uebungsset.position == null) {
        uebungsset.position = this.trainingsplan.length + 1;
      }
      if (uebungsset.setId == null) {
        uebungsset.setId = this.selectedSet.id;
      }
      TrainingsplanApi.storeTrainingsplan(uebungsset).then(() => {
        this.getData(this.selectedSet.id);
        this.closeFormular();
      });
    },
    postNewTime() {
      this.selectedSet.validFrom =
        this.pickerDate + "T" + this.pickerTime + ":00.000Z";
      TrainingsplanApi.changeDateForThisSet(this.selectedSet);
      this.changeDateDialog = false;
    },
    resetDateTimePickers() {
      this.pickerDate = this.selectedSet.validFrom.split("T")[0];
      const timeOnly: string = this.selectedSet.validFrom.split("T")[1];
      this.pickerTime = timeOnly.split(":")[0] + ":" + timeOnly.split(":")[1];
      this.changeDateDialog = false;
    },
    formatDate: (selection: any) => {
      if (
        !selection ||
        selection == undefined ||
        selection.validFrom == undefined
      ) {
        return "";
      }
      const dateOnly: string = selection.validFrom.split("T")[0];
      const timeOnly: string = selection.validFrom.split("T")[1];
      const [year, month, day] = dateOnly.split("-");
      const h: string = timeOnly.split(":")[0];
      const m: string = timeOnly.split(":")[1];
      return `${day}.${month}.${year} - ${h}:${m}`;
    },
    openNewPlanDialog() {
      this.newSetDialog = true;
      this.getVorlagen();
    },
    abortNewPlan() {
      this.newSetDialog = false;
      this.selectedVorlage = undefined;
    },
    getVorlagen() {
      TrainingsplanApi.getAllVorlagen().then((response) => {
        console.log(response);
        this.planVorlagen = response;
        this.planVorlagen.push({ befund: "Leerer Plan", id: null });
        this.selectedVorlage = this.planVorlagen[this.planVorlagen.length - 1];
      });
    },
    pickerText: (selection: any) => {
      if (
        !selection ||
        selection == undefined ||
        selection.befund == undefined
      ) {
        return "Kein Name";
      } else {
        return selection.befund;
      }
    },
    requestNewSet() {
      this.dataLoaded = 0;
      if (this.selectedVorlage.id == null) {
        TrainingsplanApi.getEmptySetForPatient(this.patientId).then(
          (response) => {
            this.trainingsplanSets.push(response?.data);
            this.newSetDialog = false;
            this.getData(this.selectedSet?.id??0);
            this.$router.go();
          }
        );
      } else {
        //Make axios thing
        TrainingsplanApi.getSetFromVorlageForPatient(
          this.patientId,
          this.selectedVorlage
        ).then((response) => {
          this.trainingsplanSets.push(response);
          this.newSetDialog = false;
          this.getData(this.selectedSet?.id??0);
          this.$router.go();
        });
      }
    },
  },
};
