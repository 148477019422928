







import AboApi from "@/services/api/Abo.ts";
export default {
  data() {
    return {
      notices: []
    };
  },
  props: {
  },
  created: function() {
      this.getAboHinweise()
  },
  methods: {
    getAboHinweise() {
      AboApi.getAboBezahlungHinweise().then(notices => {
        this.notices = notices;
      });
    },
  }
};

