import WebsocketMessageOutgoing from "./WebsocketMessageOutgoing";

export default class PublishSdpResponseMessage
  implements WebsocketMessageOutgoing {
  command: string;
  bearer: string = "";
  sdpDescription: string;
  rxRtpEndpointId: string;
  streamUuid: string;

  constructor(
    bearer: string,
    sdpDescription: string,
    rxRtpEndpointId: string,
    streamUuid: string
  ) {
    this.command = "publishSdpResponse";
    this.sdpDescription = sdpDescription;
    this.rxRtpEndpointId = rxRtpEndpointId;
    this.streamUuid = streamUuid;
    this.bearer = bearer
  }
}
