import StreamObject from "../../StreamObject";
import WebsocketMessageOutgoing from "./WebsocketMessageOutgoing";

export default class SubscribeSdpResponseMessage
  implements WebsocketMessageOutgoing {
  command: string;
  bearer: string = "";
  sdpDescription: string;
  txRtpEndpointId: string;
  stream: StreamObject;
  cameraStreamName: string;

  constructor(
    bearer: string,
    sdpDescription: string,
    txRtpEndpointId: string,
    stream: any,
    cameraStreamName: string,
    
  ) {
    this.command = "subscribeSdpResponse";
    this.sdpDescription = sdpDescription;
    this.txRtpEndpointId = txRtpEndpointId;
    this.stream = stream;
    this.cameraStreamName = cameraStreamName;
    this.bearer = bearer??"";
  }
}
