export default class FirmaForPatient {
	bezeichner: String;
	firmaId: Number;
	patientId: Number;

	constructor(
		bezeichner: String,
		firmaId: Number,
		patientId: Number
	) {
		this.bezeichner = bezeichner;
		this.firmaId = firmaId;
		this.patientId = patientId;
	}
}
