export default class AboPaketDto {
  trainingsplaene: number;
	befundtermine: number;
	einzelTermineProQuartal: number;
	gruppenTermineProQuartal: number;
	laufzeitMonate: number;
	buchungMonate: number;
	kuendigungsfristMonate: number;
	kostenProQuartalCent: number;
	title: string;
	aktiv: boolean;
	buchbar: boolean;

  constructor() {
    this.trainingsplaene = 1;
    this.befundtermine = 1;
    this.einzelTermineProQuartal = 0;
    this.gruppenTermineProQuartal = 0;
    this.laufzeitMonate = 12;
    this.buchungMonate = 3;
    this.kuendigungsfristMonate = 3;
    this.kostenProQuartalCent = 0;
    this.title = "";
    this.aktiv = false;
    this.buchbar = false;
  }

}
