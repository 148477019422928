

















































import Files from "../../services/api/Files";

export default {
  data: () => ({
    therapeuten_id: 0,
    profilePhoto: "",
    elementOverflown: false,
  }),
  props: {
    therapeut: Object,
  },
  mounted() {
    var element = this.$refs.aboutme;
    this.elementOverflown =
      element.offsetHeight < element.scrollHeight ||
      element.offsetWidth < element.scrollWidth;
  },
  methods: {
    showTermine() {
      this.$router
        .push({
          name: "Patient: Termine",
          params: {
            therapeut: this.therapeut.vorname + " " + this.therapeut.nachname,
          },
        })
        .catch(() => {});
    },
    showProfile() {
      if (this.therapeuten_id != null && this.therapeuten_id > 0) {
        this.$router
          .push({
            path: "/profil/" + this.therapeuten_id,
          })
          .catch(() => {});
      }
    },
  },
  created() {
    this.therapeuten_id = this.therapeut.profil;
    Files.getProfilePhoto(this.therapeut.profilbild).then((response) => {
      this.profilePhoto = response;
    });
  },
};
