

































































































































































































































































































import Stomp from "webstomp-client";
import VideoBoxOther from "./VideoBoxOther.vue";
import WebSocketApi from "../../services/api/WebSocket";
import TerminApi from "../../services/api/Termin";
import LockConferenceMessage from "../../data/videotherapie/messages/outgoing/LockConferenceMessage";
import UnlockConferenceMessage from "../../data/videotherapie/messages/outgoing/UnlockConferenceMessage";
import WebsocketMessageOutgoing from "../../data/videotherapie/messages/outgoing/WebsocketMessageOutgoing";
import PublishStreamMessage from "../../data/videotherapie/messages/outgoing/PublishStreamMessage";
import publishSdpResponseMessage from "../../data/videotherapie/messages/outgoing/PublishSdpResponseMessage";
import SubscribeStreamMessage from "../../data/videotherapie/messages/outgoing/SubscribeStreamMessage";
import SubscribeSdpResponseMessage from "../../data/videotherapie/messages/outgoing/SubscribeSdpResponseMessage";
import PublishSdpResponseMessage from "../../data/videotherapie/messages/outgoing/PublishSdpResponseMessage";
import StreamObject from "../../data/videotherapie/StreamObject";
import MemberObject from "../../data/videotherapie/MemberObject";
import Warteraum from "./Warteraum.vue";
import format from "date-fns/format";
import deLocale from "date-fns/locale/de";
import roleHelper, { Role } from "@/data/user/Role";

import Button from "../../data/videotherapie/buttons/Button";

export default {
  components: {
    VideoBoxOther,
    Warteraum,
  },
  props: {
    conferenceIdProp: {
      type: String,
      default: null,
    },
  },
  data: () => ({
    triedAutoRejoinOnce: false,
    flashReconButton: false,
    actionButtonColor: "accentred",
    everyoneView: false,
    reconDialog: false,
    video18Height: "31",
    currentDateString: "",
    currentTimeString: "",
    beginTimeString: "",
    endTimeString: "",
    waiting: true,
    joined: false,
    // means we are waiting to complete the JoinSessionRequest
    joining: false,
    //streams
    ownPosition: 7,
    showVideo: true,
    otherStreams: [null, null, null, null, null, null, null, null],
    patientUsers: [null, null, null, null, null, null, null, null],
    ownStream: null,
    ownStreamSet: false,
    audio: true,
    video: true,
    audioSubmission: true,
    meldung: false,
    conferenceDialog: false,
    connected: false,
    sessionId: "",
    isMuted: false,
    conferenceId: undefined,
    appMode: false,
    localStreams: {},
    tracks: [],
    streams: [],
    localPc: null,
    muted: false,
    afterDisconnectUrl: "/cockpit",
    afterRedirectUrl: "/cockpit",
    therapeut: "",
    rightBar: [
      new Button(
        require("../../assets/dihs/Videotherapie/DiHS_Video_Icon_Sr_Ton1.jpg"),
        require("../../assets/dihs/Videotherapie/DiHS_Video_Icon_Sr_Ton2.jpg"),
        require("../../assets/dihs/Videotherapie/DiHS_Video_Icon_Sr_Ton3.jpg"),
        require("../../assets/dihs/Videotherapie/DiHS_Video_Icon_Sr_Ton4.jpg"),
        "Ton",
        true
      ),

      new Button(
        require("../../assets/dihs/Videotherapie/DiHS_Video_Icon_Sr_Video1.jpg"),
        require("../../assets/dihs/Videotherapie/DiHS_Video_Icon_Sr_Video2.jpg"),
        require("../../assets/dihs/Videotherapie/DiHS_Video_Icon_Sr_Video3.jpg"),
        require("../../assets/dihs/Videotherapie/DiHS_Video_Icon_Sr_Video14.jpg"),
        "Video",
        true
      ),
      new Button(
        require("../../assets/dihs/Videotherapie/DiHS_Video_Icon_Sr_Tonuebertragung3.jpg"),
        require("../../assets/dihs/Videotherapie/DiHS_Video_Icon_Sr_Tonuebertragung3.jpg"),
        require("../../assets/dihs/Videotherapie/DiHS_Video_Icon_Sr_Tonuebertragung4.jpg"),
        require("../../assets/dihs/Videotherapie/DiHS_Video_Icon_Sr_Tonuebertragung4.jpg"),
        "Tonübertragung",
        false
      ),
      new Button(
        require("../../assets/dihs/Videotherapie/DiHS_Video_Icon_Sr_Meldung1.jpg"),
        require("../../assets/dihs/Videotherapie/DiHS_Video_Icon_Sr_Meldung3.jpg"),
        require("../../assets/dihs/Videotherapie/DiHS_Video_Icon_Sr_Meldung2.jpg"),
        require("../../assets/dihs/Videotherapie/DiHS_Video_Icon_Sr_Meldung4.jpg"),
        "Meldung",
        true
      ),
      new Button(
        require("../../assets/dihs/Videotherapie/DiHS_Video_Icon_Sr_Session1.jpg"),
        require("../../assets/dihs/Videotherapie/DiHS_Video_Icon_Sr_Session2.jpg"),
        require("../../assets/dihs/Videotherapie/DiHS_Video_Icon_Sr_Session1.jpg"),
        require("../../assets/dihs/Videotherapie/DiHS_Video_Icon_Sr_Session2.jpg"),
        "Session",
        true
      ),
    ],
  }),
  created() {
    if (this.$store.state.authentication.role != Role.PATIENT) {
      this.$store.state.error = {
        isVisible: true,
        headline: "Unberechtigter Zugriff!",
        text: "Sie Haben nicht die Rechte um dieser Sitzung beizutreten.",
        okAction: function () {},
      };
      console.error("Illegal Access to Videochat. Redirecting...");
      this.$router.push("/").catch((err: any) => {
        console.error(err);
      });
    } else {
      this.rightBar[0].setDeactivateAction(this.toggleAudioSubmission);
      this.rightBar[0].setActivateAction(this.toggleAudioSubmission);
      this.rightBar[1].setActivateAction(this.toggleVideo);
      this.rightBar[1].setDeactivateAction(this.toggleVideo);
      this.rightBar[2].setActivateAction(function () {});
      this.rightBar[2].setDeactivateAction(function () {});
      this.rightBar[3].setActivateAction(this.toggleMeldung);
      this.rightBar[3].setDeactivateAction(this.toggleMeldung);
      this.rightBar[4].setActivateAction(this.notAllowed);
      this.rightBar[4].setDeactivateAction(this.disconnect);
      if (this.conferenceIdProp === null) {
        this.conferenceId = this.$route.params.conferenceId;
      } else {
        this.conferenceId = this.conferenceIdProp;
        this.appMode = true;
      }

      // Bitte nicht entfernen, da nicht umfangreich getestet.
      console.log("ConferenceId: " + this.conferenceId);
      console.log("ConferenceIdProp: " + this.conferenceIdProp);
      console.log("RouterParams - ConfID: " + this.$route.params.conferenceId);
      // Ende von Behalteblock

      if (this.conferenceId !== undefined) {
        for (let i = 0; i < this.otherStreams.length; i++) {
          this.otherStreams[i] = {
            stream: null,
            name: "",
            video: null,
            muted: false,
            show: true,
          };
          TerminApi.getTherapeut(this.conferenceId).then((response) => {
            if (response !== null && response.data != null && response.data != undefined) {
              const name = response.data.vorname + " " + response.data.nachname;
              this.therapeut = name;
            }
          });
        }
        this.connect();
      }
      const now = new Date();
      this.currentDateString = format(now, "EEEE, d.M.yyyy", {
        locale: deLocale,
      });
      this.getNow();
      setInterval(this.getNow, 1000); //makes the clock display real time
    }
  },
  beforeDestroy() {
    this.disconnect();
  },
  methods: {
    reconnect() {
      this.reconDialog = false;
      WebSocketApi.reconnect(this);
    },
    getNow: function () {
      const today = new Date();
      this.currentTimeString = format(today, "pp", {
        locale: deLocale,
      });
    },
    disconnect() {
      WebSocketApi.disconnect(this);
    },
    getTerminTime(date: string) {
      const terminTime = new Date(date);
      return format(terminTime, "p", {
        locale: deLocale,
      });
    },
    setTimes(startTime: string, duration: number) {
      this.beginTimeString = this.getTerminTime(startTime) + " Uhr";
      const terminEndTime = new Date(startTime);
      terminEndTime.setMinutes(terminEndTime.getMinutes() + duration);
      this.endTimeString = this.getTerminTime(terminEndTime) + " Uhr";
    },
    calcRightBarWidth() {
      const buttons = this.rightBar.length;
      const buttonWidth = 7.5;
      const buttonSpace = 0.5;
      const vh = window.innerHeight / 100;
      const vw = window.innerWidth / 100;
      if (
        (buttons - 1) * buttonSpace * vw + buttons * buttonWidth * vw >
        80 * vh
      ) {
        return (
          ((88 * vh) / ((buttonWidth + buttonSpace) * buttons)) * buttonWidth
        );
      }
      return buttonWidth * vw;
    },
    toggleAudio: function () {
      this.audio = !this.audio;
    },
    toggleAudioSubmission: function () {
      this.audioSubmission = !this.audioSubmission;
      for (let track of this.tracks) {
        if (track.kind === "audio") {
          track.enabled = this.audioSubmission;
        }
      }
      WebSocketApi.sendUserMuted(this, !this.audioSubmission);
    },
    toggleVideo: function () {
      this.video = !this.video;
      for (let track of this.tracks) {
        if (track.kind === "video") {
          track.enabled = this.video;
        }
      }
    },
    toggleMeldung: function () {
      this.meldung = !this.meldung;
      WebSocketApi.transmitMeldung(this, this.meldung);
      //this.unimplemented();
    },
    connect() {
      WebSocketApi.connect(this);
    },
    notAllowed: function () {
      console.log("Not allowed");
      this.unimplemented();
    },
    unimplemented: function () {
      this.$store.state.error = {
        isVisible: true,
        headline: "Funktion nicht verfügbar",
        text: "Diese Funktion ist leider (noch) nicht verfügbar.",
        okAction: function () {},
      };
    },
    handleMessage(message: any) {
      console.log("COMMAND IS: " + message.command);
      if (
        this.conferenceId !== null &&
        this.conferenceId !== undefined &&
        this.joining
      ) {
        if (message.command !== "joinedSession" && !this.joined) {
          console.log("Got a command too early: " + message.command);
          console.log("might cause trouble...");
        } else {
          switch (message.command) {
            case "joinedSession":
              this.setTimes(message.startTime, message.duration);
              //WebSocketApi.publishStream(this);
              this.everyoneView = message.everyoneView;
              this.joined = true;
              //experimental: Publishing before session start to prevent hiccup
              WebSocketApi.publishStream(this);
              break;
            case "therapeutJoined":
              //experimental: Publishing before session start to prevent hiccup
              //if (this.waiting && this.joined) {
              this.waiting = false;
              //WebSocketApi.publishStream(this);
              //}
              break;
            case "conferenceClosed":
              // notify user, close websocket
              this.$store.state.error = {
                isVisible: true,
                headline: "Sitzung beendet",
                text: "Ihre Sitzung wurde beendet.",
                okAction: function () {
                  this.$router.push("/").catch(() => {});
                },
              };
              WebSocketApi.disconnect(this);
              break;
            case "kickedFromConference":
              this.$store.state.error = {
                isVisible: true,
                headline: "Sitzung gesperrt",
                text: "Sie sind nicht berechtigt, dieser Sitzung beizutreten.",
                okAction: function () {},
              };
              this.$router.push("/").catch(() => {});
              break;
            case "publishSdpRequest":
              if (this.joined) {
                WebSocketApi.handlePublishSdpRequest(
                  this,
                  message.sdp,
                  message.stream
                );
              }
              break;
            case "streamStatus":
              if (this.joined) {
                WebSocketApi.handleStreamStatusChange(
                  this,
                  message.stream,
                  message.active,
                  message.member
                );
              }
              break;
            case "subscribeSdpRequest":
              if (this.joined) {
                WebSocketApi.handleSubscribeSdpRequest(
                  this,
                  message.sdp,
                  message.stream,
                  message.txRtpEndpointId
                );
              }
              break;
            case "ahoyConnectFailed":
              if (!this.triedAutoRejoinOnce) {
                this.triedAutoRejoinOnce = true;
                WebSocketApi.reconnect(this);
              } else {
                console.log("Failed Automatic reconnect...");
                this.flashReconButton = true;
                this.flashReconnectButtonUntilPressedOrTenSeconds();
              }
              break;
            case "muteStatus":
              this.isMuted = message.muteStatus;
              this.rightBar[2].setActive(!message.muteStatus);
              break;
            case "userMuteStatus":
              this.rightBar[2].setActive(!message.muteStatus);
              break;
            case "userMeldungStatus":
              this.meldung = message.meldungStatus;
              this.rightBar[3].setActive(!message.meldungStatus);
              break;
            case "kicked":
              this.$store.state.error = {
                isVisible: true,
                headline: "Sitzung beendet",
                text: "Sie wurden aus der Sitzung entfernt.",
                okAction: function () {
                  this.disconnect();
                },
              };
              break;
            case "endYourSignal":
              this.meldung = false;
              this.rightBar[3].setActive(true);
              break;
            case "error":
              this.$store.state.error = {
                isVisible: true,
                headline: message.headline,
                text: message.description,
                okAction: function () {},
              };
              break;
            case "conferenceNotNow":
              console.log("Conference is not now...");
              WebSocketApi.disconnectSilent(this);
              this.$store.state.error = {
                isVisible: true,
                headline: "Sitzung derzeit nicht Verfügbar",
                text: "Diese Sitzung findet zu einem anderen Zeitpunkt statt.",
                okAction: function () {},
              };
              this.$router.push("/").catch(() => {});
              break;
            case "conferenceIdMissing":
              console.log("Conference Id not Recognized...");
              WebSocketApi.disconnectSilent(this);
              this.$store.state.error = {
                isVisible: true,
                headline: "Sitzung nicht Verfügbar",
                text: "Diese Sitzung scheint nicht zu Existieren.",
                okAction: function () {},
              };
              this.$router.push("/").catch(() => {});
              break;
            case "ahoyWebSocketClosed":
              console.log(
                "WebSocket for Ahoy is CLOSED state. Ending Session."
              );
              WebSocketApi.disconnectSilent(this);
              this.$store.state.error = {
                isVisible: true,
                headline: "Übertragungsfehler",
                text:
                  "Der Media-Websocket hat einen Kritischen fehler erreicht. Bitte wenden Sie sich an den Betreiber.",
                okAction: function () {},
              };
              this.$router.push("/").catch(() => {});
              break;
            case "conferenceNotBooked":
              console.error("Conference not Booked for this Client.");
              WebSocketApi.disconnectSilent(this);
              this.$store.state.error = {
                isVisible: true,
                headline: "Sitzung nicht Verfügbar",
                text:
                  "Wir konnten Ihre Buchung für diese Sitzung nicht finden. Falls Sie glauben, dass dies ein Fehler ist, wenden Sie sich bitte an unseren Supportkontakt.",
                okAction: function () {},
              };
              this.$router
                .push({
                  name: "Cockpit",
                })
                .catch(() => {});
              break;
            case "pingOK":
              // Rückantwort vom periodischen Ping, da müssen wir nicht reagieren
              break;
            default:
              // mache Fehlermeldung
              console.log("Servernachricht nicht erkannt!");
              console.log("Nachricht: " + message);
              break;
          }
        }
      }
    },
    openReconDialog() {
      this.reconDialog = true;
      this.flashReconButton = false;
    },
    flashReconnectButtonUntilPressedOrTenSeconds() {
      let i = 0;
      while (this.flashReconButton && i < 10) {
        if (this.actionButtonColor === "accentred") {
          this.actionButtonColor = "#ff8080";
        } else {
          this.actionButtonColor = "accentred";
        }
        setTimeout(() => {
          i++;
        }, 1000);
      }
      this.flashReconButton = false;
    },
    publishStream() {
      WebSocketApi.publishStream(this);
    },
    calcViewHeight(percentage: number) {
      return (((window.innerHeight * percentage) / 100) * 0.91).toString();
    },
    determineName(name: string) {
      if (
        name === "" ||
        name === undefined ||
        name === null ||
        name === "Kein Name"
      ) {
        return "Therapeut: " + this.therapeut;
      } else {
        return name;
      }
    },
  },
  beforeRouteLeave(to: any, from: any, next: any) {
    if (this.connected) {
      WebSocketApi.disconnect(this);
    }
    next();
  },
};
