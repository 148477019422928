

















import Patient from "@/services/api/Patient";
export default {
  data: () => ({
    code: null,
    erfolgsNachricht: null,
    fehlerNachricht: null,
  }),
  created: function() {
    this.code = this.$route.params.code;
    this.emailReset(this.code)
      .then(() => {
        this.erfolgsNachricht = "Das Zurücksetzen war erfolgreich!";
      })
      .catch(() => {
        this.fehlerNachricht = "Beim Zurückseten ist ein Fehler aufgetreten!";
      });
  },
  methods: {
    emailReset: function(resetCode: string) {
      this.erfolgsNachricht = null;
      this.fehlerNachricht = null;
      return Patient.emailReset(resetCode);
    },
  },
};
