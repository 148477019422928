import Api from "@/config/api";
import RegistrierungsBenutzer from "@/data/RegistrierungsBenutzer";
import axios from "axios";

export default {
	registrieren(benutzer: RegistrierungsBenutzer) {
		const url = "/patient/registrieren";
		const data = JSON.stringify(benutzer);

		return axios.post(url, data, Api.getRequestConfig())
		.catch(() => {
			window.alert("Verbindungsfehler!")
			return null
		});
	},
	emailBestaetigen(code: string) {
		const url: string = "/patient/email/bestaetigen/" + code;
		return axios.get(url, Api.getRequestConfig())
		.catch(() => {
			window.alert("Verbindungsfehler!")
			return null
		});
	},
	emailReset(code: string) {
		const url: string = "/patient/email/reset/" + code;
		return axios.get(url, Api.getRequestConfig())
		.catch(() => {
			window.alert("Verbindungsfehler!")
			return null
		});
	},
	getPatientByPatientId(id: number) {
		const url: string =
			Api.getRequestConfig().baseURL + "/patient/getByPatientId/" + id;
		return axios.get(url, {
			headers: Api.getRequestConfig().headers,
		})
		.catch(() => {
			window.alert("Verbindungsfehler!")
			return null
		});
	},
	getPatientByUuId(conferenceId: string, uuid: string) {
		const url: string =
			Api.getRequestConfig().baseURL + "/patient/getByUuid/" + conferenceId + "/" + uuid;
		return axios.get(url, {
			headers: Api.getRequestConfig().headers,
		})
		.catch(() => {
			window.alert("Verbindungsfehler!")
			return null
		});
	},
	getPatientByUserId(id: number) {
		const url: string =
			Api.getRequestConfig().baseURL + "/patient/getByUserId/" + id;
		return axios.get(url, {
			headers: Api.getRequestConfig().headers,
		})
		.catch(() => {
			window.alert("Verbindungsfehler!")
			return null
		});
	}
};
