import Stream from "@/data/videotherapie/Stream"

export default class WebRtc{
    private static instance:WebRtc|undefined = undefined
    private localCameraStream:MediaStream|undefined = undefined
    private localPc:RTCPeerConnection|undefined = undefined
    private static foreignStreamsCount:number=1
    private foreignStreams:Stream[]
    private peerConnections:RTCPeerConnection[]

    public static getInstance(){
        if(WebRtc.instance===undefined){
            WebRtc.instance=new WebRtc()
        }
        return WebRtc.instance
    }

    private constructor(){
        this.foreignStreams = []
        for(let i=0; i< WebRtc.foreignStreamsCount; i++){
            this.foreignStreams.push(new Stream(undefined, "", undefined, false))
        }
        this.peerConnections=[]
    }

    public static setForeignStreamsCount(count:number){
        if(WebRtc.instance!==undefined){
            console.warn("Cannot set foreignStreamsCount after initialization!")
        }
        this.foreignStreamsCount=count;
    }

    public getForeignStreams(){
        return this.foreignStreams;
    }

    public setLocalCameraStream(stream:MediaStream){
        this.localCameraStream=stream
    }
    public getLocalCameraStream(){
        return this.localCameraStream
    }

    public getLocalPc(){
        if(this.localPc===undefined){
            this.localPc = new RTCPeerConnection()
        }
        return this.localPc
    }

    addPeerConnection(pc:RTCPeerConnection){
        this.peerConnections.push(pc)
    }

    public disconnect(refresher:() => void){  
        this.localCameraStream?.getTracks().forEach((track)=> {
            track.stop()
        })      
        this.localCameraStream=undefined
        this.localPc?.close()
        console.debug(this.localPc?.iceConnectionState)
        console.debug(this.localPc?.signalingState)
        //this.localPc?.restartIce()
        this.localPc=undefined
        this.foreignStreams.forEach(stream => {
            stream.video?.getTracks().forEach((track)=> {
                track.stop()
            })
            stream.video=undefined
        })
        this.peerConnections.forEach((pc) =>{
            pc.close()
            console.debug(pc.iceConnectionState)
            console.debug(pc.signalingState)
            //this.localPc?.restartIce()
        })        
        this.peerConnections=[]                
        WebRtc.instance=undefined
        refresher();
    }


}