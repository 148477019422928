




































































































import FragebogenApi from "@/services/api/Fragebogen";
export default {
  props: ["selectedElement"],
  data() {
    return {
      types: [
        { key: 0, value: "FREITEXT" },
        { key: 1, value: "WAHRHEITSWERT" },
        { key: 2, value: "SKALA" },
      ],
      enumTypes: ["FREITEXT", "WAHRHEITSWERT", "SKALA"],
      showFrageForm: false,
      newFrage: {},
      showFragebogen: true,
    };
  },
  computed: {
    getBooleanFragen() {
      // Bugticket Redmine: Bug #4239
      const booleanFragen = [];
      for (const frage of this.selectedElement.fragen) {
        if (frage.typ === "WAHRHEITSWERT") {
          booleanFragen.push(frage);
        }
      }
      return booleanFragen;
    },
    getSelectedElement() {
      return this.selectedElement;
    },
  },
  methods: {
    getTypes: function () {
      return this.types;
    },
    inactivateFrage: function (frage: any) {
      FragebogenApi.inactivateFrage(frage).then((response) => {
        this.$emit("update:selection", response);
      });
    },
    toggleFrageForm: function () {
      if (!this.showFrageForm) {
        this.newFrage = {
          frage: "",
          typ: 0,
          abhaengigVonFrageId: null,
          position: null,
          fragebogenId: this.selectedElement.id,
          abhaengigVonBedingung: null,
        };
      }
      this.showFrageForm = !this.showFrageForm;
    },
    saveFrage: function () {
      this.newFrage.position = this.selectedElement.fragen.length;

      FragebogenApi.addFrage(this.newFrage).then((response) => {
        this.newFrage.id = response;
        delete this.newFrage.fragebogenId;
        for (const object of this.types) {
          if (object.key === this.newFrage.typ) {
            this.newFrage.typ = object.value;
          }
        }

        this.selectedElement.fragen.push(this.newFrage);
        this.toggleFrageForm();
        this.reloadFragebogenView();
      });
    },
    publish: function () {
      this.save();
      FragebogenApi.publishFragebogen(this.selectedElement).then((response) => {
        this.$emit("update:draftStatus", response);
        this.$emit("update:selection", response);
      });
    },
    save: function () {
      const fragen = this.selectedElement.fragen;
      for (let i = 0; i < fragen.length; i++) {
        const frage = fragen[i];
        if (frage.position !== null && frage.position > -1) {
          frage.position = i;
        }
      }
      FragebogenApi.saveFragebogen(this.selectedElement);
    },
    moveUp: function (i: number) {
      const fragen = this.selectedElement.fragen;
      const cache = fragen[i - 1];
      fragen[i - 1] = fragen[i];
      fragen[i] = cache;
      this.reloadFragebogenView();
    },
    moveDown: function (i: number) {
      const fragen = this.selectedElement.fragen;
      const cache = fragen[i + 1];
      fragen[i + 1] = fragen[i];
      fragen[i] = cache;
      this.selectedElement.fragen = fragen;
      this.reloadFragebogenView();
    },
    reloadFragebogenView: function () {
      this.showFragebogen = false;
      this.showFragebogen = true;
    },
  },
};
