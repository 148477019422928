











import Vue from "vue";

export default Vue.extend({
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    emitClick() {
      if (!this.disabled) {
        this.$emit("click");
      }
    },
    amIDisabled: function() {
      return {
        disabledStyle: this.disabled,
        theButton: true,
      };
    },
  },
});
