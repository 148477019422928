


































export default {
  props: {
    uebungen: Array,
  },
  data: function() {
    return {
      filter: "",
      selectedItem: -1,
      mandatory: true,
    };
  },
  computed: {
    filteredRows() {
      if(this.filter == null) return this.uebungen;
      return this.uebungen.filter(
        (row: { titel: { toString: () => string } }) => {
          const title = row.titel.toString().toLowerCase();
          return (
            "" == this.filter || title.indexOf(this.filter.toLowerCase()) > -1
          );
        }
      );
    },
  },
  methods: {
    bearbeiteUebung(uebung: any) {
      this.$emit("selectionChanged", uebung);
    },
    changed() {},
    deselectAll() {
      this.mandatory = false;
      this.selectedItem = -1;
    },
    clearFilter(){
      this.filter = "";
    }
  },
};
