export default class RegistrierungsBenutzer {
	vorname: string;
	nachname: string;
	email: string;
	passwort: string;
	straße: string;
	hausnummer: string;
	plz: string;
	stadt: string;
	land: string;
	geburtsdatum: string;

	constructor(
		vorname: string,
		nachname: string,
		email: string,
		passwort: string,
		straße: string,
		hausnummer: string,
		plz: string,
		stadt: string,
		land: string,
		geburtsdatum: string
	) {
		this.vorname = vorname;
		this.nachname = nachname;
		this.email = email.toLowerCase();
		this.passwort = passwort;
		this.straße = straße;
		this.hausnummer = hausnummer;
		this.plz = plz;
		this.stadt = stadt;
		this.land = land;
		this.geburtsdatum = geburtsdatum;
	}
}
