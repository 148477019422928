/* Muss mit dem Role-Enum im Backend übereinstimmen */
export enum Role {
  BETREIBER = 0,
  THERAPEUT = 1,
  PATIENT = 2,
}

export default {
  numbersToStrings(roles: Role[]): string[] {
    const strings: string[] = [];
    for (const role of roles) {
      strings.push(this.numberToString(role));
    }
    return strings;
  },
  stringsToNumbers(strings: string[]): Role[] {
    const roles: Role[] = [];
    for (const str of strings) {
      roles.push(this.stringToNumber(str));
    }
    return roles;
  },
  numberToString(role: Role): string {
    return Role[role];
  },
  stringToNumber(str: string): Role {
    const str2 = str.toUpperCase() as keyof typeof Role;
    return Role[str2];
  },
  allValues(): string[] {
    const enumValues: Array<string> = [];
    for (const value in Role) {
      if (typeof Role[value] === "number") {
        enumValues.push(value);
      }
    }
    return enumValues;
  },
};
