































































































































































































































































































































































































































































































import Patient from "@/services/api/Patient";
import RegistrierungsBenutzer from "@/data/RegistrierungsBenutzer";
import rules from "@/config/rules";
import SeiteComponent from "@/components/inhalt/Seite.vue";
import formatISO from "date-fns/formatISO";
import router from "@/router";
export default {
  components: {
    SeiteComponent,
  },
  data: () => ({
    dialog: false,
    seite: "",
    rules: rules,
    valide: false,
    nutzungsbestimmungenCheckbox: false,
    vorname: "",
    nachname: "",
    email: "",
    straße: "",
    hausnummer: "",
    plz: "",
    stadt: "",
    land: "",
    passwort: "",
    passwortUeberpruefen: "",
    passwortAnzeigen: false,
    registrierungErfolgreich: null,
    registrierungFehler: null,
    geburtsdatum: null,
    geburtsdatumFormatted: null,
    menu: false,
    successClosed: false,
  }),
  watch: {
    // Für die Geburtstagseingabe
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    menu(val: any) {
      // eslint-disable-next-line no-unused-expressions
      val && setTimeout(() => (this.$refs.picker.activePicker = "YEAR"));
    },
    geburtsdatum() {
      this.geburtsdatumFormatted = this.formatDate(this.geburtsdatum);
    },
    // Ende Geburtstagseingabe
    successClosed(val: boolean | null ) {
      if(val == false) {
        
        router.push({path: "/"});

      }
      
    },
  },
  computed: {
    passwortUeberpruefenRegel() {
      return (
        (this.passwortAnzeigen && this.passwortUeberpruefen === "") ||
        this.passwort === this.passwortUeberpruefen ||
        "Die Passwörter stimmen nicht überein."
      );
    },
  },
  methods: {
    // Für die Geburtstagseingabe
    saveBirthday(geburtsdatum: string) {
      this.$refs.menu.save(geburtsdatum);
    },
    parseDate(date: string) {
      if (!date) return null;
      const [day, month, year] = date.split(".");
      return `${year}-${("0" + month).slice(-2)}-${("0" + day).slice(-2)}`;
    },
    formatDate(date: string) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return `${day}.${month}.${year}`;
    },
    isoFormatToday() {
      return formatISO(new Date(), { representation: "date" });
    },
    // Ende Geburtstagseingabe
    showRechtliches(seite: string) {
      this.seite = seite;
      this.dialog = true;
    },
    submit() {
      this.registrierungErfolgreich = null;
      this.registrierungFehler = null;
      const benutzer: RegistrierungsBenutzer = new RegistrierungsBenutzer(
        this.vorname,
        this.nachname,
        this.email.toLowerCase(),
        this.passwort,
        this.straße,
        this.hausnummer,
        this.plz,
        this.stadt,
        this.land,
        this.geburtsdatum
      );
      Patient.registrieren(benutzer)
        .then(() => {
          this.registrierungErfolgreich = true;
          this.successClosed = true;
        })
        .catch((error) => {
          // Der Patient kann mit dieser Nachricht nichts anfangen
          // Hier sollte nur bei Netzwerkfehlern oder Validierungsfehlern etwas passieren.
          // Um diese zu vermeiden müssen die config/rules mit den Constrains im Backend
          // immer übereinstimmen.
          this.registrierungFehler = error.response.data.message;
          this.registrierungErfolgreich = false;
        });
    },
    reset() {
      this.$refs.registrierungsFormular.reset();
    },
    showHintSmallScreens(hint: string) {
      return this.$vuetify.breakpoint.smAndDown ? hint : "";
    },
  },
};
