


































import UebungApi from "@/services/api/Uebung";
import UebungenListeComponent from "./UebungenListe.vue";
import UebungenFormularComponent from "./UebungenFormular.vue";
export default {
  components: {
    UebungenListeComponent,
    UebungenFormularComponent,
  },
  data: () => ({
    uebungen: [],
    selectedUebungId: null,
    uebungBearbeiten: false
  }),
  created() {
    this.getData();
  },
  methods: {
    getData() {
      UebungApi.getAllUebungenSortiertNachTitel().then(response => {
        this.uebungen = response?.data;
      });
    },
    erstelleNeueUebung() {
      this.selectedUebungId = 0;
      this.uebungBearbeiten = true;
      //this.$refs.list.deselectAll();
    },
    selectionChanged(uebung: any) {              
      const selectedItem = uebung;
      this.selectedUebungId = selectedItem.id;
      return;            
    },
    dialogBack(){
       this.selectedUebungId = -1;
    },
    formSubmitted() {
      UebungApi.getAllUebungenSortiertNachTitel().then(response => {
        this.uebungen = response?.data;
      });
      this.dialogBack();
    },
    uebungDeleted() {
      UebungApi.getAllUebungenSortiertNachTitel().then(response => {
        this.uebungen = response?.data;
      });
      this.dialogBack();
    },
  }
}
