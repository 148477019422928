































































import privilege, { Privilege } from "@/data/user/Privilege";
import { mapMutations, mapGetters } from "vuex";
import api from "../../config/api";
import Axios from "axios";
import { PrivilegesDto } from "@/dtos/PrivilegesDto";

interface Data {
  edit: boolean;
  dialogEdit: boolean;
  dialogError: boolean;
  privileges: string[];
  active: boolean;
  allRolePrivileges: string[];
}
export default {
  props: {
    user: {
      type: Object,
      default: null,
    },
  },
  data(): Data {
    return {
      edit: false,
      dialogEdit: false,
      dialogError: false,
      privileges: privilege.numbersToStrings(this.user.privileges),
      active: this.user.active,
      allRolePrivileges: [],
    };
  },
  created() {
    this.getRolePrivileges();
  },
  computed: {
    ...mapGetters(["isMe"]),
    ...mapMutations(["setNetworkError"]),
  },
  methods: {
    changeEdit() {
      if (!this.isMe(this.user.id)) {
        if (!this.edit) {
          this.edit = true;
        } else {
          this.dialogEdit = true;
        }
      } else {
        this.dialogError = true;
      }
    },
    getRolePrivileges() {
      const url: string = "/authority/" + this.user.role;
      Axios.get<Privilege[]>(url, api.getRequestConfig()).then(response => {
        this.allRolePrivileges = privilege.numbersToStrings(response.data);
      })
      .catch(() => {
        window.alert("Verbindungsfehler!")
      });
    },
    editSave() {
      this.user.privileges = privilege.stringsToNumbers(this.privileges);
      this.user.active = this.active;
      this.dialogEdit = false;
      this.edit = false;

      const privilegesDto: PrivilegesDto = {
        privileges: this.user.privileges,
        active: this.user.active,
      };

      const requestUrl = "/user/other/" + this.user.id + "/privileges";
      Axios.post(requestUrl, privilegesDto, api.getRequestConfig())
      .catch((error: Error) => {
        window.alert("Verbindungsfehler!")
        this.$store.commit("setNetworkError");
      })
    },
    editDiscard() {
      this.privileges = privilege.numbersToStrings(this.user.privileges);
      this.active = this.user.active;
      this.dialogEdit = false;
      this.edit = false;
    },
    editBack() {
      this.dialogEdit = false;
    },
  }
}
