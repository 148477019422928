import Vue from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import router from "./router";
import { store } from "./store";
import VueHead from 'vue-head'

Vue.config.productionTip = false;
Vue.use(VueHead, {
  separator: '-',
  complement:  process.env.VUE_APP_PAGE_TITLE_PREFIX
})

new Vue({
  vuetify,
  router,
  store: store,
  render: h => h(App),
}).$mount("#app")
