import NavigationsmenueDto from '@/data/inhaltsseite/NavigationsmenueDto';
import NavigationseintragFullDto from '@/data/inhaltsseite/NavigationseintragFullDto';
import NavigationselementDto from '@/data/inhaltsseite/NavigationselementDto';

export default {

  isEintrag(element: NavigationselementDto | NavigationseintragFullDto | NavigationsmenueDto): element is NavigationseintragFullDto {
    return (element as NavigationsmenueDto).type === 'eintrag';
  },
  isMenue(element: NavigationselementDto | NavigationseintragFullDto | NavigationsmenueDto): element is NavigationsmenueDto {
    return (element as NavigationsmenueDto).type === 'menue';
  }

}