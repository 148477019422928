



























































































import axios from "axios";
import Api from "@/config/api";
import Vue from "vue";
import { UserDto } from "@/dtos/UserDto";
import { UserListRequestDto } from "@/dtos/UserListRequestDto";
import roleHelper, { Role } from "@/data/user/Role";
import privilegeHelper, { Privilege } from "@/data/user/Privilege";
import Axios from "axios";
import api from "@/config/api";
interface Data {
  userList: UserDto[] | undefined;
  currentSelection: Role;
  dialog: boolean;
  newEmail: string;
  waiting: boolean;
  emailError: boolean;
  error: boolean;
  showLocked: boolean;
  lockedUser: string[];
  selectedLockedUser: string[];
}
export default Vue.extend({
  name: "UserListComponent",
  data(): Data {
    return {
      userList: undefined,
      currentSelection: Role.BETREIBER,
      dialog: false,
      newEmail: "",
      waiting: false,
      emailError: false,
      error: false,
      showLocked: false,
      lockedUser: [],
      selectedLockedUser: [],
    };
  },
  created() {
    this.loadContent(Role.BETREIBER);
  },
  computed: {
    currentSelectionString(): string {
      return roleHelper.numberToString(this.currentSelection);
    },
  },
  methods: {
    createNewUser() {
      this.waiting = true;
      this.error = false;
      this.emailError = false;
      const requestUrl = "/user/other/" + this.currentSelection + "/new";
      Axios.post(requestUrl, this.newEmail, api.getRequestConfig())
        .then(response => {
          if (response.data === "") {
            this.waiting = false;
            this.emailError = true;
          } else {
            this.$router.push("/profil/" + response.data).catch(() => {});
          }
        })
        .catch(() => {
          window.alert("Verbindungsfehler!")
          this.waiting = false;
          this.error = true;
        });
    },
    abortNewUser() {
      this.newEmail = "";
      this.newPassword = "";
      this.dialog = false;
      this.waiting = false;
      this.error = false;
      this.emailError = false;
    },
    changeEditMode(value: boolean) {
      this.editMode = value;
    },
    privilegeNumberToString(privilege: Privilege) {
      return privilegeHelper.numberToString(privilege);
    },
    loadBetreiber() {
      this.loadContent(Role.BETREIBER);
    },
    loadTherapeuten() {
      this.currentSelection = "Therapeut";
      this.loadContent(Role.THERAPEUT);
    },
    loadPatienten() {
      this.currentSelection = "Patient";
      this.loadContent(Role.PATIENT);
    },
    loadLocked() {
      this.getLockedUser();
      this.showLocked = true;
    },
    loadContent(role: Role) {
      this.showLocked = false;
      this.currentSelection = role;
      this.userList = undefined;
      this.getUserList(role);
    },
    getUserList(role: Role) {
      const url = "/benutzerliste";
      const request: UserListRequestDto = { role: role };
      axios
        .post<UserDto[]>(url, request, Api.getRequestConfig())
        .then(response => {
          this.userList = response.data;
        })
        .catch(e => {
          window.alert("Verbindungsfehler!")
          console.log(e);
        });
    },
    getLockedUser() {
      const url = "/user/locked/list";
      axios
        .get<string[]>(url, Api.getRequestConfig())
        .then(response => {
          this.lockedUser = response.data;
        })
        .catch(e => {
          window.alert("Verbindungsfehler!")
          console.log(e);
        });
    },
    checkboxClickAction(email: string) {
      if(!this.selectedLockedUser.includes(email)) {
        this.selectedLockedUser.push(email);
      } else {
        let i = 0;
        while(i < this.selectedLockedUser.length) {
          if(this.selectedLockedUser[i] == email) {
            this.selectedLockedUser.splice(i, 1);
          }
          i++;
        }
      }
    },
    unlockAccounts() {
      const url = "/user/locked/unlock";
      const data = this.selectedLockedUser;
      axios
        .post(url, data, Api.getRequestConfig())
        .then(response => {
          this.selectedLockedUser = []
          this.lockedUser = response.data;
        })
        .catch(error => {
          window.alert("Verbindungsfehler!")
          console.log(error);
        });
    }
  },
});
