import WebsocketMessageOutgoing from "./WebsocketMessageOutgoing";

export default class TestResultMessage implements WebsocketMessageOutgoing {
  command: string;
  bearer: string = "";
  result: boolean;
  constructor(bearer: string, result: boolean) {
    this.command = "testResult";
    this.result = result;
    this.bearer = bearer;

  }
}
