























































export default {
  props: {
    videoEinzel: {
      type: Number,
      default: 0,
    },
    videoGruppe: {
      type: Number,
      default: 0,
    },
    befund: {
      type: Number,
      default: 0,
    },
    trainingsplan: {
      type: Number,
      default: 0,
    },
  },
  data: function() {
    return {};
  },
};
