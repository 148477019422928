export default class TrainingsplanVorlage {
	uebungId: number | null;
	setId: number | null;

	dauer: number | null;
	anzahl: number | null;
	wiederholungen: number | null;
	pause: number | null;
	information: string;

	constructor(
		uebungId: number | null,
		setId: number | null,
		dauer: number | null,
		anzahl: number | null,
		wiederholungen: number | null,
		pause: number | null,
		information: string,
	) {
		this.uebungId = uebungId;
		this.setId = setId;
		this.dauer = dauer;
		this.anzahl = anzahl;
		this.wiederholungen = wiederholungen;
		this.pause = pause;
		this.information = information;
	}

	static emptyInstance() {
		return new TrainingsplanVorlage(
			null,
			null,
			null,
			null,
			null,
			null,
			""
		);
	}
}
