



























































































import RezeptApi from "@/services/api/Rezept";
import RezeptEditDialog from "@/components/rezepte/RezeptEditDialog.vue";

export default {
  components: {
    RezeptEditDialog,
  },
  data() {
    return {
      dateiListe: [],
      gotData: false,
      editDialog: false,
      selectedFile: null,
    };
  },
  created() {
    if (this.patientId === undefined) {
      this.getMeineRezepte();
    } else {
      this.getRezepteForPatient();
    }
  },
  props: {
    patientId: {
      type: String,
      default: undefined,
    },
    showDetails: {
      type: Boolean,
      default: false,
    },
    vorname: {
      type: String,
      default: "",
    },
    nachname: {
      type: String,
      default: "",
    },
  },
  methods: {
    openDialog(selection: any) {
      this.selectedFile = selection;
      this.editDialog = true;
    },
    closeDialog() {
      this.editDialog = true;
      this.selectedFile = null;
    },
    getMeineRezepte() {
      RezeptApi.getMyRezepte().then((data) => {
        this.dateiListe = data;
        this.gotData = true;
      });
    },
    downloadOwnFile(file: any) {
      let fileName = "";
      for (let i = 0; i < file.originalFilename.split(".").length - 1; i++) {
        fileName += file.originalFilename.split(".")[i];
      }
      RezeptApi.downloadRezeptFileAsPatient(
        file.id,
        fileName,
        file.originalFilename.split(".")[
          file.originalFilename.split(".").length - 1
        ]
      );
    },
    getRezepteForPatient() {
      RezeptApi.getRezepteForPatient(this.patientId).then((data) => {
        this.dateiListe = data;
        this.gotData = true;
      });
    },
    formatEingereicht: (item: any) => {
      if (!item || item == undefined || item.eingereicht == undefined) {
        return "Nein";
      }
      const dateOnly: string = item.eingereicht.split("T")[0];
      const timeOnly: string = item.eingereicht.split("T")[1];
      const [year, month, day] = dateOnly.split("-");
      const h: string = timeOnly.split(":")[0];
      const m: string = timeOnly.split(":")[1];
      return `${day}.${month}.${year} um ${h}:${m}Uhr`;
    },
  },
};
