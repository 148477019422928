





















import Patient from "@/services/api/Patient";
export default {
  data: () => ({
    code: null,
    erfolgsNachricht: null,
    fehlerNachricht: null,
  }),
  created: function() {
    this.code = this.$route.params.code;
    this.emailBestaetigen(this.code)
      .then(() => {
        this.erfolgsNachricht = "Die Bestätigung war erfolgreich!";
      })
      .catch(() => {
        this.fehlerNachricht = "Beim Bestätigen ist ein Fehler aufgetreten!";
        // auf Startseite weiterleiten
        this.$router.push("/").catch(() => {});
      });
  },
  methods: {
    emailBestaetigen: function(validationCode: string) {
      this.erfolgsNachricht = null;
      this.fehlerNachricht = null;
      return Patient.emailBestaetigen(validationCode);
    },
  },
};
