



































export default {
  props: {
    uebungssatz: Object,
  },
  data: () => ({}),
  methods: {
    bearbeiteUebung(uebung: any) {
      this.$emit("bearbeiteUebung", uebung);
    },
    entferneUebung(uebung: any) {
      this.$emit("callRemoveDialog", uebung);
    },
  },
};
