import StreamObject from "../../StreamObject";
import WebsocketMessageOutgoing from "./WebsocketMessageOutgoing";

export default class SubscribeStreamMessage
  implements WebsocketMessageOutgoing {
  command: string;
  bearer: string = "";
  cameraStreamName: string;
  stream: StreamObject;
  audio: boolean;
  video: boolean;

  constructor(
    bearer: string,
    cameraStreamName: string,
    stream: any,
    audio: boolean,
    video: boolean,
    
  ) {
    this.command = "subscribeStream";
    this.cameraStreamName = cameraStreamName;
    this.stream = stream;
    this.audio = audio;
    this.video = video;
    this.bearer = bearer??"";
  }
}
