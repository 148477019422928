import WebsocketMessageOutgoing from "./WebsocketMessageOutgoing";

export default class PublishStreamMessage implements WebsocketMessageOutgoing {
  command: string;
  bearer: string = "";
  cameraStreamName: string;
  audio: boolean;
  video: boolean;
  constructor(bearer: string, cameraStreamName: string, audio: boolean, video: boolean) {
    this.command = "publishStream";
    this.cameraStreamName = cameraStreamName;
    this.audio = audio;
    this.video = video;
    this.bearer = bearer??"";
  }
}
