



























































































































































import urlHelper from "@/helper/urlDto";
import { format, isToday, isTomorrow } from "date-fns";
import deLocale from "date-fns/locale/de";
import { UrlType } from "@/dtos/UrlType";
import { mapGetters } from "vuex";

export default {
  components: {},
  props: {
    element: Object,
  },
  data: () => ({
    paddingPercent: 1,
    columns: 5,
    columnWidth: 20,
    timestamp: "",
    today: "",
  }),
  computed: {
    ...mapGetters(["verbindungsTest"]),
    cardIcon: function () {
      if (this.element.link.urlType === UrlType.VERBINDUNGSTEST_PATIENT) {
        if (this.$store.state.verbindungsTest.isDone) {
          if (this.$store.state.verbindungsTest.isFailed) {
            return this.element.icon.replace("1.svg", "3.svg");
          } else {
            return this.element.icon.replace("1.svg", "2.svg");
          }
        }
      }
      return this.element.icon;
    },
  },
  methods: {
    getNextDay() {
      const terminTime = new Date(this.element.descriptiveObject.date);
      if (isToday(terminTime)) {
        return "heute";
      }
      if (isTomorrow(terminTime)) {
        return "morgen";
      }
      return format(terminTime, "d.M.yyyy", { locale: deLocale });
    },
    getNextTime(date: string) {
      const terminTime = new Date(date);
      return format(terminTime, "p", { locale: deLocale });
    },
    getTerminTime(date: string) {
      const terminTime = new Date(date);
      return format(terminTime, "d.M.yyyy'<br>'p' Uhr<br>'", {
        locale: deLocale,
      });
    },
    getNow: function () {
      const today = new Date();
      this.timestamp = format(today, "HH:mm:ss", { locale: deLocale });
    },
    getUrl() {
      return urlHelper.getUrl(this.element.link);
    },
    calcColumnWidth() {
      const spaceBetween = 13 / 190;
      const columnWidth = 100 / this.columns / (spaceBetween + 1);
      this.columnWidth =
        columnWidth * this.element.size + this.element.size * spaceBetween;
    },
    calcPaddingPercent() {
      const spaceBetween = 13 / 190;
      const paddingPercent =
        ((100 / this.columns / (spaceBetween + 1)) * spaceBetween) / 2;
      this.paddingPercent = paddingPercent;
    },
    calculateColumns() {
      const width = window.innerWidth;
      //xs
      this.columns = 1;
      if (width > 600) {
        //sm
        this.columns = 2;
        if (width > 960) {
          //md
          this.columns = 3;
          if (width > 1248) {
            //lg
            this.columns = 4;
            if (width > 1888) {
              //xl
              this.columns = 5;
            }
          }
        }
      }
      this.calcPaddingPercent();
      this.calcColumnWidth();
    },
    style() {
      return "font-size: " + (this.size >= 2 ? "34px" : "22px");
    },
    hrefTarget(ziel: any) {
      // In targetBlank ist kein Boolean sondern ein String "true" oder "false"
      // element: Object könnte hier durch eine Klasse element: KachelElement ersetzt werden.
      if (
        ziel.params.targetBlank === true ||
        ziel.params.targetBlank === "true"
      ) {
        return "_blank";
      }
      return "_self";
    },
  },
  created() {
    window.addEventListener("resize", () => {
      this.calculateColumns();
    });
    this.calculateColumns();
    if (this.element.text === "timeBox") {
      const now = new Date();
      const days = [
        "Sonntag",
        "Montag",
        "Dienstag",
        "Mittwoch",
        "Donnerstag",
        "Freitag",
        "Samstag",
      ];
      this.today = format(now, "EEEE, d.M.yyyy", { locale: deLocale });
      this.getNow();
      setInterval(this.getNow, 1000); //makes the clock display real time
    }
  },
};
