
























































































import Trainingsplan from "@/data/Trainingsplan";
import axios from "axios";
export default {
  props: {
    uebungsSatz: Object,
    uebung: Object,
    showDialogProp: Boolean,
  },
  data: function () {
    return {
      valide: false,
      showDialog: false,
      uebungsSet: {},
      uebungsTitel: "",
      videoFrame: ""
    };
  },
  watch: {
    showDialogProp: function (newVal: boolean) {
      if (newVal === true) {
        if (this.uebungsSatz && this.uebungsSatz.id > 0) {
          // Eintrag bearbeiten
          this.uebungsSet = this.uebungsSatz;
          this.uebungsTitel = this.uebungsSatz.titel;
        } else {
          // neuer Eintrag
          this.uebungsTitel = this.uebung.titel;
          this.uebungsSet = new Trainingsplan(
            null,
            this.$store.state.selectedPatientId,
            this.uebung.id,
            null,
            null,
            null,
            "",
            null,
            null,
            null
          );
        }
      }

      this.videoFrame = ""
      if(this.uebung != null && this.uebung.videoUrl != null) {
        axios.get("https://vimeo.com/api/oembed.json?url=" + this.uebung.videoUrl).then((response) => {
          this.videoFrame = response.data.html
        })
        .catch(() => {
          window.alert("Verbindungsfehler!")
        })
      }
      this.showDialog = newVal;
    },
  },
  methods: {
    closeFormular() {
      this.$emit("closeFormular");
    },
    submitFormular() {
      this.$emit(
        "submit",
        this.uebungsSet,
        this.uebung ? this.uebung.id : this.uebungsSet.uebungId
      );
      this.$emit("closeFormular");
    },
  },
};
