import "@mdi/font/css/materialdesignicons.css";
import Vue from "vue";
import Vuetify from "vuetify/lib";

Vue.use(Vuetify);

export default new Vuetify({
	icons: {
		iconfont: "mdi"
	},
	theme: {
		themes: {
			light: {
				primary: "#042f4c", //DarkBlue
				secondary: "#299fe1",	//LightBlue
				bgwhite: "#fafafa", //almost white
				accentred: "#b22835", //DarkRed
				accentgreen: "#51b16c", //lightgreen
				accentdarkgreen: "#228c87", //darkgreen
				palelight: "#dadada", //20-grey
				steelgrey: "#d1dae4", //darker grey
				darkgrey: "#8f8f8f",
				bluegrey: "#b4c2d1", //blue-ish grey
				error: "#B32330", //strong red
				warning: "#ffbb00", //strong yellow
				info: "#cddc39", //lime
				success: "#4caf50", //strong green
				anchor: "#8c9eff", //pale blue
			},
			dark: {
				primary: "#042f4c", //DarkBlue
				secondary: "#299fe1",	//LightBlue
				accentred: "#b22835", //DarkRed
				accentgreen: "#51b16c", //lightgreen
				accentdarkgreen: "#228c87", //darkgreen
				palelight: "#dadada", //20-grey
				steelgrey: "#d1dae4", //darker grey
				darkgrey: "#8f8f8f",
				bluegrey: "#b4c2d1", //blue-ish grey
				error: "#B32330", //strong red
				warning: "#ffbb00", //strong yellow
				info: "#cddc39", //lime
				success: "#4caf50", //strong green
				anchor: "#8c9eff", //pale blue
			},
		},
	},
});
