


































































































































































































































































































import Websocket from "../../services/api/WebsocketSingleton";
import VideoBoxOther from "./VideoBoxOther.vue";
import TestResultMessage from "../../data/videotherapie/messages/outgoing/TestResultMessage";
import SubscribeStreamMessage from "../../data/videotherapie/messages/outgoing/SubscribeStreamMessage";
import StreamObject from "../../data/videotherapie/StreamObject";
import MemberObject from "../../data/videotherapie/MemberObject";
import { mapMutations } from "vuex";
import WebRtc from "@/services/api/WebRtc";

export default {
  components: {
    VideoBoxOther,
  },
  name: "Websocketdemo",
  data() {
    return {
      sessionId: "",
      conferenceId: "loopback",      
      testStep: "",
      displayTestStep: "",
      testFailed: false,
    };
  },
  created() {
    Websocket.getInstance().setRefreshView(() => {
      console.debug("refreshing view")
      this.ownStream=this.getOwnStream()
      this.foreignStreams=this.getForeignStreams()
      this.testStep=Websocket.getInstance().getTestStep()
      this.$forceUpdate()
      //setTimeout(() => { this.$forceUpdate() }, 20);
    })    
    for (let i = 0; i < this.otherStreams.length; i++) {
      this.otherStreams[i] = {
        stream: null,
        name: "",
        video: null,
        muted: false,
      };
    }       
  },
  computed: {
    ...mapMutations(["setVerbindungsTestFailed"]),    
  },
  mounted() {
    //window.addEventListener('beforeunload', this.disconnect()) 
  },
  watch: {
    //TODO: das ganze sollte auf Enums umgebaut werden, weil die weniger anfällig sind. //pr
    testStep: function (newVal: any) {
      /*const stringArray = newVal.split("<br>");
      stringArray.shift();
      stringArray.forEach((string: string, i: number) => {
        const newString =
          "<br><i class='material-icons' style='color:green; position: relative; top: 6px;'>check_box</i>" +
          string;
        stringArray[i] = newString;
      });
      if (
        !stringArray[stringArray.length - 1].includes(
          "Technischer Test erfolgreich"
        )
      ) {
        const lastStringArray = stringArray[stringArray.length - 1].split(
          "</i>"
        );
        lastStringArray[0] =
          "<br><i class='material-icons' style='color:red; position: relative; top: 6px;'>disabled_by_default</i>";
        stringArray[
          stringArray.length - 1
        ] = lastStringArray.toString().replaceAll(",", "");
      }
      this.displayTestStep = stringArray.toString().replaceAll(",", "");*/
    },
  },
  beforeDestroy() {
    this.disconnect();
  },
  methods: {
    isTechnicalSuccess() {
        console.debug(Websocket.getInstance().isConnected() )
        console.debug(Websocket.getInstance().isSubscribed() )
        console.debug(Websocket.getInstance().getSessionId !== undefined )
        console.debug(Websocket.getInstance().getConferenceId() != undefined )
        console.debug(this.getOwnStream() != undefined )
        console.debug(this.getForeignStreams()[0] != undefined)
      if (
        Websocket.getInstance().isConnected() &&
        Websocket.getInstance().isSubscribed() &&
        Websocket.getInstance().getSessionId !== undefined &&
        Websocket.getInstance().getConferenceId() != undefined &&
        this.getOwnStream() != undefined &&
        this.getForeignStreams()[0] != undefined
      ) {
        return true;
      }
      return false;
    },
    isConnected(){
      return Websocket.getInstance().isConnected()
    },
    getOwnStream(){
      return WebRtc.getInstance().getLocalCameraStream()
    },
    getForeignStreams(){
      return WebRtc.getInstance().getForeignStreams()
    },
    connect() {
      this.testStep = "";
      this.displayTestStep = "";
      Websocket.getInstance().connect(this.conferenceId, this.$store.state.authentication.userId, this.$store.state.authentication.token, this.handleMessage, 1, () => this.testStarted=true)
    },
    finishTest() {
      this.testFinished = true;
      this.disconnect();
    },
    success() {
      this.testFailed = false;
      this.$store.commit("setVerbindungsTestFailed", false);
      this.sendMessage(new TestResultMessage("", true));
      this.disconnect();
    },
    failed() {
      this.testFailed = true;
      this.$store.commit("setVerbindungsTestFailed", true);
      this.sendMessage(new TestResultMessage("", false));
      this.disconnect();
    },
    disconnect() {
      this.testStep = "";
      this.displayTestStep = "";
      Websocket.getInstance().disconnect();
    },

    sendMessage(msg: any) {
      msg.bearer = this.$store.state.authentication.token;
      this.stompClient.send("/app/sendOnly", JSON.stringify(msg), {});
    },
    handleMessage(message: any) {      
      console.debug(message)
      switch (message.command) {
        case "joinedSession":
          this.testStep += "<br>Sitzung erfolgreich gestartet";
          Websocket.getInstance().publishStream();
          break;
        case "publishSdpRequest":
          Websocket.getInstance().handlePublishSdpRequest(
            message.sdp,
            message.stream
          );
          break;
        case "streamStatus":
          this.testStep += "<br>Statusmeldung über eigenen Stream erhalten";
          Websocket.getInstance().handleStreamStatusChange(
            message.stream,
            message.active,
            message.member
          );
          break;
        case "subscribeSdpRequest":
          Websocket.getInstance().handleSubscribeSdpRequest(
            message.sdp,
            message.stream,
            message.txRtpEndpointId
          );
          break;
        default:
          // mache Fehlermeldung
          console.log("I have received the command: " + message.command);
          console.log("This command was not recognized...");
          break;
      }
    },
    handleStreamStatusChange(
      stream: StreamObject,
      active: boolean,
      member: MemberObject
    ) {
      if (active) {
        this.testStep += "<br>Stelle Anfrage, eigenen Stream zu erhalten";
        this.sendMessage(
          new SubscribeStreamMessage("", stream.name, stream, true, true)
        ); // we could remove stream.name as it is part of stream (redundant)
      }
    },
    navigateToSturzPraevention() {
      this.$router
        .push({
          name: "Sturz",
        })
        .catch(() => {});
    },
    navigateToKontakt() {
      this.$router
        .push({
          name: "Kontakt",
        })
        .catch(() => {});
    },
    navigateToCockpit() {
      this.$router
        .push({
          name: "Cockpit",
        })
        .catch(() => {});
    },
  },

  beforeRouteLeave(to: any, from: any, next: any) {
    Websocket.getInstance().disconnect();
    next();
  },
};
