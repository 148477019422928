











































































































































import { add } from "date-fns";
import TerminApi from "@/services/api/Termin";
import DokumentApi from "@/services/api/Dokument";
import { mapMutations } from "vuex";
import format from "date-fns/format";
import deLocale from "date-fns/locale/de";
import BetreiberTermineFormular from "./BetreiberTermineFormular.vue";
import PatientenListe from "./../PatientenListe.vue";
import { Termintyp } from "@/services/api/Termintyp";
import NutzerProfil from "@/components/profil/NutzerProfil.vue";
export default {
  components: {
    BetreiberTermineFormular,
    PatientenListe,
    NutzerProfil,
  },
  props: {
    termin: Object,
    cart: Array,
  },
  data: function () {
    return {
      checkbox: false,
      terminBearbeiten: false,
      dialogTherapeut: false,
      selectedTherapeutId: null,
      terminTypEinzel: Termintyp[Termintyp.EINZEL],
      terminTypGruppe: Termintyp[Termintyp.GRUPPE],
      terminTypTelefon: Termintyp[Termintyp.TELEFON],
      terminTypAnamnese: Termintyp[Termintyp.ANAMNESE],
      terminTypBefund: Termintyp[Termintyp.BEFUND],
    };
  },
  computed: {
    isDue() {
      return Date.now() - new Date(this.termin.datum).getTime() < 10 * 60;
    },
    isNotCancelable() {
      return Date.now() - new Date(this.termin.datum).getTime() > 24 * 3600;
    },
    ...mapMutations(["selectedTermine"]),
  },
  watch: {
    cart: function (newVal: any) {
      if (newVal.includes(this.termin)) {
        this.checkbox = true;
      } else {
        this.checkbox = false;
      }
    },
  },
  methods: {
    removeFromCart() {
      this.$emit("update:removeFromCart", this.termin);
      this.checkbox = false;
    },
    addToCart() {
      this.$emit("update:addToCart", this.termin);
      this.checkbox = true;
    },
    checkboxClickAction() {
      if (this.checkbox) {
        this.$emit("update:addToCart", this.termin);
      } else {
        this.$emit("update:removeFromCart", this.termin);
      }
    },
    toggleBearbeiten() {
      this.terminBearbeiten = false;
    },
    submitted() {
      this.$emit("submitted");
    },
    dataChanged() {
      this.$emit("dataChanged");
    },
    stopPropagation: function (event: any) {
      //verhindert, dass das Panel ein- und ausklappt.
      event.stopPropagation();
    },
    formateDate(date: string) {
      if (date == "") return "";
      return new Date(date).toLocaleDateString();
    },
    formateTime(date: string) {
      if (date == "") return "";
      return format(new Date(date), "p", { locale: deLocale });
    },
    getEndTime(termin: any) {
      return add(new Date(termin.datum), { minutes: termin.dauer });
    },
    istHeute(date: any) {
      const today = new Date().toLocaleDateString();
      const givenDate = new Date(date).toLocaleDateString();
      if (today === givenDate) {
        return true;
      } else {
        return false;
      }
    },
    addSelectedTermine() {
      this.$store.commit("selectedTermine", this.termin);
    },
    bearbeiteTermin() {
      this.terminBearbeiten = true;
    },
    isSelected() {
      if (this.$store.state.selectedTermine.indexOf(this.termin) > -1) {
        return true;
      } else {
        return false;
      }
    },
    getColor() {
      if (this.$store.state.selectedTermine.indexOf(this.termin) > -1) {
        return "#f5f6f7";
      } else {
        return "white";
      }
    },
    getHeaderBorder() {
      if (this.$store.state.selectedTermine.indexOf(this.termin) > -1) {
        return "2px solid rgb(0,73,100)";
      } else {
        return "";
      }
    },
    showProfile(userId: number) {
      this.selectedTherapeutId = userId;
      this.dialogTherapeut = true;
      /*
      this.$router
        .push({
          name: "NutzerProfil",
          params: { userId: userId },
        })
        .catch(() => {});
        */
    },
  },
};
