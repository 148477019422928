




































import { mapMutations } from "vuex";
import api from "../../config/api";
import Axios from "axios";
import rules from "@/config/rules";
export default {
  props: {
    info: {
      type: Object,
      default: null,
    },
  },
  data: () => ({
    rules: rules,
    editMail: false,
    dialogEditMail: false,
    emailModel: null,
  }),
  created() {
    this.emailModel = this.info.email;
  },
  computed: {
    ...mapMutations(["setNetworkError"])
  },
  methods: {
    editMailSave() {
      this.info.email = this.emailModel;
      this.editMail = false;
      this.dialogEditMail = false;
      const requestUrl = "/patient/email/aendern";
      Axios.post(
        requestUrl,
        { email: this.info.email },
        api.getRequestConfig()
      ).catch((error: Error) => {
        window.alert("Verbindungsfehler!")
        this.$store.commit("setNetworkError");
      })
    },
    editMailDiscard() {
      this.emailModel = this.info.email;
      this.editMail = false;
      this.dialogEditMail = false;
    },
    editMailBack() {
      this.dialogEditMail = false;
    },
    changeMail() {
      if (!this.editMail) {
        this.editMail = true;
      } else {
        this.dialogEditMail = true;
      }
    },
  }
}
