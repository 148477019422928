
























































































































































































































































































































































import NutzerProfil from "@/components/profil/NutzerProfil.vue";
import Stomp from "webstomp-client";
import VideoBoxOther from "./VideoBoxOther.vue";
import VideoBoxHighlight from "./VideoBoxHighlight.vue";
import api from "../../config/api";
import WebSocketApi from "../../services/api/WebSocket";
import LockConferenceMessage from "../../data/videotherapie/messages/outgoing/LockConferenceMessage";
import UnlockConferenceMessage from "../../data/videotherapie/messages/outgoing/UnlockConferenceMessage";
import WebsocketMessageOutgoing from "../../data/videotherapie/messages/outgoing/WebsocketMessageOutgoing";
import PublishStreamMessage from "../../data/videotherapie/messages/outgoing/PublishStreamMessage";
import publishSdpResponseMessage from "../../data/videotherapie/messages/outgoing/PublishSdpResponseMessage";
import SubscribeStreamMessage from "../../data/videotherapie/messages/outgoing/SubscribeStreamMessage";
import SubscribeSdpResponseMessage from "../../data/videotherapie/messages/outgoing/SubscribeSdpResponseMessage";
import PublishSdpResponseMessage from "../../data/videotherapie/messages/outgoing/PublishSdpResponseMessage";
import EndConferenceMessage from "../../data/videotherapie/messages/outgoing/EndConferenceMessage";
import KickMemberMessage from "../../data/videotherapie/messages/outgoing/KickMemberMessage";
import StreamObject from "../../data/videotherapie/StreamObject";
import MemberObject from "../../data/videotherapie/MemberObject";
import PatientApi from "../../services/api/Patient";
import Button from "../../data/videotherapie/buttons/Button";
import format from "date-fns/format";
import deLocale from "date-fns/locale/de";
import roleHelper, { Role } from "@/data/user/Role";

export default {
  components: {
    VideoBoxOther,
    VideoBoxHighlight,
    NutzerProfil,
  },
  props: {},
  data: () => ({
    flashReconButton: false,
    actionButtonColor: "accentred",
    reconDialog: false,
    triedAutoRejoinOnce: false,
    //userRole: "therapeut",
    video18Height: "31",
    currentDateString: "",
    currentTimeString: "",
    beginTimeString: "",
    endTimeString: "",
    terminArt: "",
    //streams
    ownPosition: 7,
    showVideo: true,
    otherStreams: [null, null, null, null, null, null, null, null],
    patientUsers: [null, null, null, null, null, null, null, null],
    patientIds: [null, null, null, null, null, null, null, null],
    ownStream: null,
    ownStreamCache: null,
    ownStreamSet: false,
    viewMode: 18,

    hightlightStream: null,
    highlightStreamDialog: false,
    hightlightStreamId: null,
    hightlightStreamName: "Hochlichtnutzer",
    hightlightStreamUUID: null,
    //conferenceDialog: false,
    isConferenceLocked: false,
    connected: false,
    sessionId: "",
    conferenceId: undefined,
    localStreams: {},
    tracks: [],
    streams: [],
    localPc: null,
    showProfileId: 0,
    showProfile: false,
    afterDisconnectUrl: "/therapeut/termine",
    rightBar: [
      new Button(
        require("../../assets/dihs/Videotherapie/DiHS_Video_Icon_Sr_Eingang1.jpg"),
        require("../../assets/dihs/Videotherapie/DiHS_Video_Icon_Sr_Eingang2.jpg"),
        require("../../assets/dihs/Videotherapie/DiHS_Video_Icon_Sr_Eingang3.jpg"),
        require("../../assets/dihs/Videotherapie/DiHS_Video_Icon_Sr_Eingang4.jpg"),
        "Eingang",
        true
      ),

      new Button(
        require("../../assets/dihs/Videotherapie/DiHS_Video_Icon_Sr_Ton1.jpg"),
        require("../../assets/dihs/Videotherapie/DiHS_Video_Icon_Sr_Ton2.jpg"),
        require("../../assets/dihs/Videotherapie/DiHS_Video_Icon_Sr_Ton3.jpg"),
        require("../../assets/dihs/Videotherapie/DiHS_Video_Icon_Sr_Ton4.jpg"),
        "Ton",
        true
      ),

      new Button(
        require("../../assets/dihs/Videotherapie/DiHS_Video_Icon_Sr_Video1.jpg"),
        require("../../assets/dihs/Videotherapie/DiHS_Video_Icon_Sr_Video2.jpg"),
        require("../../assets/dihs/Videotherapie/DiHS_Video_Icon_Sr_Video3.jpg"),
        require("../../assets/dihs/Videotherapie/DiHS_Video_Icon_Sr_Video14.jpg"),
        "Video",
        true
      ),

      new Button(
        require("../../assets/dihs/Videotherapie/DiHS_Video_Icon_Sr_Multiview3.jpg"),
        require("../../assets/dihs/Videotherapie/DiHS_Video_Icon_Sr_Multiview3.jpg"),
        require("../../assets/dihs/Videotherapie/DiHS_Video_Icon_Sr_Multiview1.jpg"),
        require("../../assets/dihs/Videotherapie/DiHS_Video_Icon_Sr_Multiview2.jpg"),
        "Ansicht",
        true
      ),

      new Button(
        require("../../assets/dihs/Videotherapie/DiHS_Video_Icon_Sr_Session1.jpg"),
        require("../../assets/dihs/Videotherapie/DiHS_Video_Icon_Sr_Session2.jpg"),
        require("../../assets/dihs/Videotherapie/DiHS_Video_Icon_Sr_Session1.jpg"),
        require("../../assets/dihs/Videotherapie/DiHS_Video_Icon_Sr_Session2.jpg"),
        "Session",
        true
      ),
    ],
  }),
  created() {
    if (this.$store.state.authentication.role != Role.THERAPEUT) {
      console.error("Illegal Access to Videochat. Redirecting...");
      this.$store.state.error = {
        isVisible: true,
        headline: "Unberechtigter Zugriff!",
        text: "Sie Haben nicht die Rechte um dieser Sitzung beizutreten.",
        okAction: function () {},
      };
      this.$router.push("/").catch((err: any) => {
        console.error(err);
      });
    } else {
      this.rightBar[0].setDeactivateAction(this.sendLockConference);
      this.rightBar[0].setActivateAction(this.sendUnlockConference);
      this.rightBar[1].setActivateAction(this.toggleMuteMe);
      this.rightBar[1].setDeactivateAction(this.toggleMuteMe);
      this.rightBar[2].setActivateAction(this.toggleVideo);
      this.rightBar[2].setDeactivateAction(this.toggleVideo);
      this.rightBar[3].setActivateAction(this.switchToMultiview);
      this.rightBar[3].setDeactivateAction(this.notAllowed);
      this.rightBar[4].setActive(false);
      this.rightBar[4].setActivateAction(this.sendEndSession);
      this.rightBar[4].setDeactivateAction(this.notAllowed);

      this.conferenceId = this.$route.params.conferenceId;
      if (this.conferenceId !== undefined) {
        for (let i = 0; i < this.otherStreams.length; i++) {
          this.otherStreams[i] = {
            stream: null,
            name: "",
            video: null,
            muted: false,
            show: true,
          };
        }
        this.connect();
      }
      const now = new Date();
      this.currentDateString = format(now, "EEEE, d.M.yyyy", {
        locale: deLocale,
      });
      this.getNow();
      setInterval(this.getNow, 1000); //makes the clock display real time
    }
  },
  beforeDestroy() {
    this.disconnect();
  },
  methods: {
    reconnect() {
      this.reconDialog = false;
      WebSocketApi.reconnect(this);
    },
    getNow: function () {
      const today = new Date();
      this.currentTimeString = format(today, "pp", {
        locale: deLocale,
      });
    },
    getTerminTime(date: string) {
      const terminTime = new Date(date);
      return format(terminTime, "p", {
        locale: deLocale,
      });
    },
    setTimes(startTime: string, duration: number) {
      this.beginTimeString = this.getTerminTime(startTime) + " Uhr";
      let terminEndTime = new Date(startTime);
      terminEndTime.setMinutes(terminEndTime.getMinutes() + duration);
      this.endTimeString = this.getTerminTime(terminEndTime) + " Uhr";
    },
    getRightBarButtonCount() {
      return this.rightBar.length;
    },
    calcRightBarWidth() {
      const buttons = this.rightBar.length;
      const buttonWidth = 7.5;
      const buttonSpace = 0.5;
      const vh = window.innerHeight / 100;
      const vw = window.innerWidth / 100;
      if (
        (buttons - 1) * buttonSpace * vw + buttons * buttonWidth * vw >
        80 * vh
      ) {
        return (
          ((88 * vh) / ((buttonWidth + buttonSpace) * buttons)) * buttonWidth
        );
      }
      return buttonWidth * vw;
    },
    calcViewHeight(percentage: number) {
      return (((window.innerHeight * percentage) / 100) * 0.91).toString();
    },
    muteHighlighted: function () {
      this.otherStreams[this.hightlightStreamId].muted = true;
    },
    unmuteHighlighted: function () {
      this.otherStreams[this.hightlightStreamId].muted = false;
    },
    oneOnOne: function () {
      this.viewMode = 11;
    },
    showAkte: function (event: string) {
      console.log("Akte Zeigen für Patient: " + event);
      //WATCH IT! This Patient ID is NOT the patientId Property of Patient Objects
      // PatientId here refers to the MemberUuid Used to connect Patients to the Ahoy Session.
      PatientApi.getPatientByUuId(this.conferenceId, event).then((response :any) => {
        this.showProfileId = response.data;
        console.log(JSON.stringify(response.data));
        this.showProfile = true;
      });
    },
    kick: function (patientId?:string) {
      console.debug("PatientId is "+patientId)
      console.debug("Highlighted user is "+ this.patientIds[this.hightlightStreamId])
      if(patientId !== undefined){
        this.sendKickPatientId(patientId);
      }
      else{
        this.sendKickHighlightedMember();
      }
      
    },
    doMeldung: function (uuid: string) {
      //console.log("CALLED! " + this.otherStreams.length);
      for (let stream of this.otherStreams) {
        console.log("Stream: " + JSON.stringify(stream) + " UUID: " + uuid);
        if (stream.name === uuid) {
          stream.meldung = false;
        }
      }
      console.log("ending meldung for uuid: " + uuid);
      WebSocketApi.endMeldungForPatient(this, uuid);
    },
    unimplemented: function () {
      this.$store.state.error = {
        isVisible: true,
        headline: "Funktion nicht verfügbar",
        text: "Diese Funktion ist leider (noch) nicht verfügbar.",
        okAction: function () {},
      };
    },
    switchToMultiview: function () {
      this.viewMode = 18;
      this.unmuteAll();
    },
    notAllowed: function () {
      console.log("Not allowed");
      this.unimplemented();
    },
    togglePresentation: function () {
      console.log("Presentation not implemented.");
      this.unimplemented();
    },
    calculateHeightPercentage(percentage: number) {
      const height = screen.height;
      return ((height / 100) * percentage).toString();
    },
    toggleMuteMe: function () {
      let muteStatus: boolean;
      muteStatus = false;
      this.tracks.forEach((track: any) => {
        if (track.kind === "audio") {
          track.enabled = !track.enabled;
          muteStatus = !track.enabled;
        }
      });
      WebSocketApi.sendUserMuted(this, muteStatus);
    },
    toggleVideo: function () {
      this.tracks.forEach((track: any) => {
        if (track.kind === "video") {
          track.enabled = !track.enabled;
        } 
      });
      if(this.ownStream===null){
          this.ownStream=this.ownStreamCache;
        }else{
          this.ownStreamCache=this.ownStream
          this.ownStream = null
        }
    },
    muteAllExcept(excludeMemberUuid: string) {
      WebSocketApi.sendMute(this, true, excludeMemberUuid);
      for (let otherStream of this.otherStreams) {
        if (otherStream.name == excludeMemberUuid) {
          otherStream.muted = false;
        } else {
          otherStream.muted = true;
        }
      }
    },
    unmuteAll() {
      WebSocketApi.sendMute(this, false, "");
      for (let otherStream of this.otherStreams) {
        otherStream.muted = false;
      }
    },
    highlightThisStream(streamId: number) {
      this.viewMode = 117;
      this.rightBar[3].setActive(false);
      this.highlightStreamDialog = true;
      this.hightlightStreamId = streamId;
      this.hightlightStream = this.otherStreams[streamId];
      this.hightlightStreamName = this.patientUsers[streamId];
      this.hightlightStreamUUID = this.otherStreams[streamId].name;
      this.muteAllExcept(this.otherStreams[streamId].name);
    },
    closeHighlightStream() {
      this.highlightStreamDialog = false;
      this.hightlightStreamId = null;
      this.hightlightStream = null;
      this.hightlightStreamName = null;
      this.hightlightStreamUUID = null;
      this.unmuteAll();
    },
    sendKickHighlightedMember() {
      console.debug("Sending kickMemberMessage by highlighted user for "+ this.patientIds[this.hightlightStreamId])
      WebSocketApi.sendMessage(
        this,
        new KickMemberMessage("", this.patientIds[this.hightlightStreamId])
      );
    },
    sendKickPatientId(patientId:string) {
      console.debug("Sending kickMemberMessage for "+ patientId)
      WebSocketApi.sendMessage(
        this,
        new KickMemberMessage("", patientId)
      );
    },
    sendLockConference: function () {
      WebSocketApi.sendMessage(this, new LockConferenceMessage(""));
    },
    sendUnlockConference() {
      WebSocketApi.sendMessage(this, new UnlockConferenceMessage(""));
    },
    sendEndSession() {
      WebSocketApi.sendMessage(this, new EndConferenceMessage(""));
    },
    connect() {
      WebSocketApi.connect(this);
    },
    disconnect() {
      WebSocketApi.disconnect(this);
    },
    startSession() {
      WebSocketApi.startSession(this);
    },
    handleMessage(message: any) {
      switch (message.command) {
        case "ahoyConnectFailed":
          if (!this.triedAutoRejoinOnce) {
            this.triedAutoRejoinOnce = true;
            WebSocketApi.reconnect(this);
          } else {
            console.log("Failed Automatic reconnect...");
            this.flashReconButton = true;
            this.flashReconnectButtonUntilPressedOrTenSeconds();
          }
          break;
        case "joinedSession":
          this.setTimes(message.startTime, message.duration);
          WebSocketApi.publishStream(this);
          // spezielle Behandlung von Einzelterminen
          this.terminArt = message.terminTyp ? message.terminTyp : "";
          if ("einzel" == this.terminArt.toLowerCase()) {
            this.highlightThisStream(0);
            this.rightBar = this.rightBar
              .slice(0, 3)
              .concat(this.rightBar.slice(-1)); // Multiviewaktion entfernen
            this.patientUsers = this.patientUsers.slice(0, 1);
            this.patientIds = this.patientIds.slice(0, 1);
            this.otherStreams = this.otherStreams.slice(0, 1);
          }
          break;
        case "conferenceClosed":
          // notify user, close websocket
          this.$store.state.error = {
            isVisible: true,
            headline: "Sitzung beendet",
            text: "Ihre Sitzung wurde beendet.",
            okAction: function () {},
          };
          WebSocketApi.disconnect(this);
          break;
        case "publishSdpRequest":
          WebSocketApi.handlePublishSdpRequest(
            this,
            message.sdp,
            message.stream
          );
          break;
        case "streamStatus":
          WebSocketApi.handleStreamStatusChange(
            this,
            message.stream,
            message.active,
            message.member
          );
          break;
        case "subscribeSdpRequest":
          WebSocketApi.handleSubscribeSdpRequest(
            this,
            message.sdp,
            message.stream,
            message.txRtpEndpointId
          );
          break;
        case "conferenceLocked":
          this.isConferenceLocked = true;
          break;
        case "conferenceUnlocked":
          this.isConferenceLocked = false;
          break;
        case "userMuteStatus":
          if (message.user.patientId == "") {
            // das muss wohl der Therapeut selbst sein
            this.ownStream.muted = message.muteStatus;
            break;
          }
          for (let stream of this.otherStreams) {
            if (stream.name === message.user.memberUuid) {
              stream.muted = message.muteStatus;

              console.log(stream);
            }
          }
          break;
        case "userMeldungStatus":
          for (let stream of this.otherStreams) {
            if (stream.name === message.user.memberUuid) {
              stream.meldung = message.meldungStatus;
              console.log(
                "Meldung gesetzt für: " +
                  message.user.memberUuid +
                  " Meldungstyp: " +
                  message.meldungStatus
              );
            }
          }
          break;
        case "error":
          this.$store.state.error = {
            isVisible: true,
            headline: message.headline,
            text: message.description,
            okAction: function () {},
          };
          break;
        case "conferenceNotYours":
          console.error("This Conference is not Yours. Redirecting...");
          this.$store.state.error = {
            isVisible: true,
            headline: "Sitzung nicht Verfügbar",
            text: "Diese Sitzung wird nicht von Ihnen durchgeführt.",
            okAction: function () {},
          };
          WebSocketApi.disconnectSilent(this);
          this.$router.push({
            name: "therapeutTermine",
          });
          break;
        case "pingOK":
          // Rückantwort vom periodischen Ping, da müssen wir nicht reagieren
          break;
        default:
          console.warn(
            "WEBSOCKET RECEIVED UNRECOGNIZED COMMAND: " + message.command
          );
          break;
      }
    },
    openReconDialog() {
      this.reconDialog = true;
      this.flashReconButton = false;
    },
    flashReconnectButtonUntilPressedOrTenSeconds() {
      let i = 0;
      while (this.flashReconButton && i < 10) {
        if (this.actionButtonColor === "accentred") {
          this.actionButtonColor = "#ff8080";
        } else {
          this.actionButtonColor = "accentred";
        }
        setTimeout(() => {
          i++;
        }, 1000);
      }
      this.flashReconButton = false;
    },
  },
  beforeRouteLeave(to: any, from: any, next: any) {
    WebSocketApi.disconnect(this);
    next();
  },
};
