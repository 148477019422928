export default {
	// Achtung!
	// Änderungen an diesen Regeln müssen auch im Backend übernommen werden,
	// dort gibt es ebenfalls Validierungsconstraints im Verzeichnis:
	// de.vatergruppe.sportreha.dto.PatientRegistrierungsDto
	// Stimmen diese nicht überein werden unspezifische Fehler angezeigt.
	vornameRegeln: [
		(v: string) => !!v || "Vorname darf nicht leer sein.",
		(v: string) => /^.{1,30}$/.test(v) || "Maximale Länge beachten",
		(v: string) =>
			/^[a-zA-ZäöüÄÖÜß\- ]+$/.test(v) ||
			"Vorname enthält unzulässige Zeichen.",
	],
	nachnameRegeln: [
		(v: string) => !!v || "Nachname darf nicht leer sein.",
		(v: string) => /^.{1,40}$/.test(v) || "Maximale Länge beachten",
		(v: string) =>
			/^[a-zA-ZäöüÄÖÜß\- ]+$/.test(v) ||
			"Nachname enthält unzulässige Zeichen.",
	],
	emailRegeln: [
		(v: string) => !!v || "E-Mail darf nicht leer sein.",
		(v: string) => /.+@.+/.test(v) || "E-Mail ist nicht valide.",
		(v: string) => /^.{1,100}$/.test(v) || "Maximale Länge beachten",
	],
	emailOptionalRegeln: [
		(v: string) => /.+@.+/.test(v) || !v || "E-Mail ist nicht valide.",
		(v: string) => /^.{0,100}$/.test(v) || "Maximale Länge beachten",
	],
	straßeRegeln: [
		(v: string) => /^.{0,50}$/.test(v) || "Maximale Länge beachten",
		(v: string) =>
			/^[a-zA-ZäöüÄÖÜß\-. ]*$/.test(v) ||
			"Straße enthält unzulässige Zeichen.",
	],
	hausnummerRegeln: [
		(v: string) => /^.{0,6}$/.test(v) || "Maximale Länge beachten",
		(v: string) =>
			/^[0-9a-zA-Z-]*$/.test(v) || "Hausnummer enthält unzulässige Zeichen.",
	],
	plzRegeln: [
		(v: string) => /^.{0,6}$/.test(v) || "Maximale Länge beachten",
		(v: string) => /^[0-9]*$/.test(v) || "PLZ enthält unzulässige Zeichen.",
	],
	stadtRegeln: [
		(v: string) => /^.{0,40}$/.test(v) || "Maximale Länge beachten",
		(v: string) =>
			/^[a-zA-ZäöüÄÖÜß0-9\- ]*$/.test(v) ||
			"Stadt enthält unzulässige Zeichen.",
	],
	landRegeln: [
		(v: string) => /^.{0,60}$/.test(v) || "Maximale Länge beachten",
		(v: string) =>
			/^[a-zA-ZäöüÄÖÜß0-9\- ]*$/.test(v) ||
			"Land enthält unzulässige Zeichen.",
	],
	passwortRegeln: [
		(v: string) => !!v || "Passwort darf nicht leer sein.",
		(v: string) => /^.{8,40}$/.test(v) || "Das Passwort muss zwischen 8 und 40 Zeichen haben.",
		(v: string) => /^(?=.*?[A-Z]).{8,}$/.test(v) || "Das Passwort muss einen Großbuchstaben beinhalten.",
		(v: string) => /^(?=.*?[a-z]).{8,}$/.test(v) || "Das Passwort muss einen Kleinbuchstaben beinhalten.",
		(v: string) => /^(?=.*?[0-9]).{8,}$/.test(v) || "Das Passwort muss eine Zahl beinhalten.",
		(v: string) => /^(?=.*?[#?!@$ %^&*-]).{8,}$/.test(v) || "Das Passwort muss ein Sonderzeichen beinhalten.",
	],
	secondFactorRegeln: [
		(v: string) => /^(.{0}|.{6})$/.test(v) || "Der Code muss 6 Zeichen lang sein.",
	],
	feedbackRegeln: [
		(v: string) => !!v || "Feedback darf nicht leer sein.",
	],
}
