
















































































import Api from "@/config/api";
import axios from "axios";
import UploadFilesVuetify from "@/components/general/Upload/UploadFilesVuetify.vue";
import RezeptListePatient from "@/components/rezepte/RezeptListePatient.vue";

export default {
  components: {
    UploadFilesVuetify,
    RezeptListePatient,
  },
  data() {
    return {
      destinationUrl: "/upload/rezept",
      vorname: "",
      nachname: "",
      gotName: false,
      buttonTrigger: false,
      anmerkung: "",
    };
  },
  created() {
    this.getUsername();
  },
  methods: {
    getUsername() {
      axios
        .get("profil/nameonly", Api.getRequestConfig())
        .then((response) => {
          if (response != null && response.status === 200) {
            this.nachname = response.data.nachname;
            this.vorname = response.data.vorname;
            this.gotName = true;
          }
        })
        .catch((err) => {
          window.alert("Verbindungsfehler!")
          console.error("Error fetching name: " + err);
        });
    },
    linkToKontakt() {
      this.$router
        .push({
          name: "Kontakt",
        })
        .catch(() => {});
    },
    uploadFile() {
      this.buttonTrigger = true;
    },
    resetButton() {
      this.buttonTrigger = false;
    },
  },
};
