import { UrlDto } from "@/dtos/UrlDto";
import { UrlType } from "@/dtos/UrlType";

export default {

	getUrl(url: UrlDto) {
		let params = "";
		for (const key in url.params) {
			params += "&" + key;
			if (url.params[key] != "") {
				params += "=" + url.params[key];
			}
		}
		params = params.substr(1);
		if (url.urlType === UrlType.SEITE) {
			return url.statisch ? "/" + url.ziel : "/inhalt/" + url.ziel;
		} else if (url.urlType === UrlType.PROFIL) {
			return !url.ziel ? "/profil" : "/profil/" + url.ziel;
		} else if (url.urlType === UrlType.TERMIN_THERAPEUT) {
			return !url.ziel ? "/therapeut/termine" : "/therapeut/termine/" + url.ziel;
		} else if (url.urlType === UrlType.TERMIN_PATIENT) {
			return !url.ziel ? "/patient/termine?" + params : "/patient/termine?id=" + url.ziel + "&" + params;
		} else if (url.urlType === UrlType.NAVIGATION_EDITIEREN) {
			return "/navigation";
		} else if (url.urlType === UrlType.TRAININGSPLAN_VORLAGEN_VERWALTEN) {
			return "/therapeut/trainingsplanvorlagen";
		} else if (url.urlType === UrlType.FRAGEBOGEN_EDITIEREN) {
			return "/betreiber/fragebogen";
		} else if (url.urlType === UrlType.DATEIEN_HOCHLADEN) {
			return "/betreiber/upload";
		} else if (url.urlType === UrlType.TERMIN_BETREIBER) {
			return "/betreiber/termine";
		} else if (url.urlType === UrlType.BENUTZER_VERWALTEN) {
			return "/betreiber/benutzer/liste";
		} else if (url.urlType === UrlType.VERBINDUNGSTEST_PATIENT) {
			return "/verbindungstest";
		} else if (url.urlType === UrlType.FRAGEBOGEN_PATIENT) {
			return "/anamnese";
		} else if (url.urlType === UrlType.UEBUNGEN_VERWALTEN) {
			return "/betreiber/uebungen";
		} else if (url.urlType === UrlType.FIRMEN_VERWALTEN) {
			return "/betreiber/firmenverwaltung";
		} else if (url.urlType === UrlType.VIDEOS) {
			return "/videos";
		} else if (url.urlType === UrlType.ANWENDUNG_VERWALTEN) {
			return "/betreiber/verwaltung";
		} /* Deaktiviert da nicht umgesetzt.
			else if (url.urlType === UrlType.ZAHLUNGEN_VERWALTEN) {
			return "/betreiber/zahlungen";
		} */
		else if (url.urlType === UrlType.UEBUNGSPLAN_PATIENT) {
			return "/patient/trainingsplan";
		} else if (url.urlType === UrlType.ABO_BUCHEN) {
			return "/patient/abo";
		} else if (url.urlType === UrlType.WARTERAUM_PATIENT) {
			return "/video/therapie/patient/" + url.ziel;
		} else if (url.urlType === UrlType.GUTHABEN_BUCHEN) {
			return "/patient/guthaben";
		} else if (url.urlType === UrlType.ABOPAKETE_VERWALTEN) {
			return "/betreiber/abo/pakete";
		} else if (url.urlType === UrlType.REZEPTE_PATIENT) {
			return "/patient/rezepte";
		} else if (url.urlType === UrlType.REZEPTE_VERWALTEN) {
			return "/betreiber/rezepte";
		} else if (url.urlType === UrlType.EXTERN) {
			return url.ziel == null || url.ziel == undefined || url.ziel == '' ? '#' : url.ziel;
		} else {
			return "";
		}
	}
}
