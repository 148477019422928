var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (this.abo != null && this.abo != undefined)?_c('v-container',[_c('AboNoticesComponent'),_c('v-row',{staticClass:"margintop20"},[_c('v-col',{attrs:{"xs":"12","sm":"12","md":"4","lg":"3"}},[_c('v-img',{staticClass:"abonnement",attrs:{"width":"50%","src":require('../../assets/dihs/Dashboard/DiHS_Dashboard_Icon_Abonnement_ro.svg')}})],1),_c('v-col',{attrs:{"xs":"12","sm":"12","md":"8","lg":"9"}},[_c('v-row',[_c('v-col',{attrs:{"lg":"4","md":"6","sm":"12","cols":"12"}},[_c('b',[_vm._v("Abonnement")])]),_c('v-col',{attrs:{"lg":"8","md":"6","sm":"12","cols":"12"}},[_vm._v(_vm._s(this.abo.aboPaket.title))])],1),_c('v-row',[_c('v-col',{attrs:{"lg":"4","md":"6","sm":"12","cols":"12"}},[_vm._v("Status:")]),_c('v-col',{attrs:{"lg":"8","md":"6","sm":"12","cols":"12"}},[_vm._v(_vm._s(_vm.getAboDetailedStatus(this.abo.detailedStatus)))])],1),_c('v-row',[_c('v-col',{attrs:{"lg":"4","md":"6","sm":"12","cols":"12"}},[_vm._v("Abgeschlossen am:")]),_c('v-col',{attrs:{"lg":"8","md":"6","sm":"12","cols":"12"}},[_vm._v(_vm._s(_vm.getAboBuchungsdatum(this.abo)))])],1),_c('v-row',[_c('v-col',{attrs:{"lg":"4","md":"6","sm":"12","cols":"12"}},[_vm._v("Laufzeit / autom. Verlängerung:")]),_c('v-col',{attrs:{"lg":"8","md":"6","sm":"12","cols":"12"}},[_vm._v(_vm._s(this.abo.aboPaket.laufzeitMonate)+" Monate")])],1),(!_vm.istFirmenAbo(this.abo))?_c('v-row',[_c('v-col',{attrs:{"lg":"4","md":"6","sm":"12","cols":"12"}},[_vm._v("Kündigungsfrist:")]),_c('v-col',{attrs:{"lg":"8","md":"6","sm":"12","cols":"12"}},[_vm._v(_vm._s(this.abo.aboPaket.kuendigungsfristMonate)+" Monate zum Laufzeitende")])],1):_vm._e(),(
          _vm.isAboUngekuendigtUndUnwiderrufen(this.abo) &&
          !_vm.istFirmenAbo(this.abo)
        )?_c('v-row',[_c('v-col',{attrs:{"lg":"4","md":"6","sm":"12","cols":"12"}},[_vm._v("Spätest mögliche Kündigung:")]),_c('v-col',{attrs:{"lg":"8","md":"6","sm":"12","cols":"12"}},[_vm._v(_vm._s(_vm.getSpaetestesKuendigungsdatum(this.abo)))])],1):_vm._e(),(!_vm.isAboUngekuendigtUndUnwiderrufen(this.abo))?_c('v-row',[_c('v-col',{attrs:{"lg":"4","md":"6","sm":"12","cols":"12"}},[_vm._v("Abo endet am:")]),_c('v-col',{attrs:{"lg":"8","md":"6","sm":"12","cols":"12"}},[_vm._v(_vm._s(_vm.getAboVertragsendeDatum(this.abo))+" ("+_vm._s(_vm.getAboEndeGrund(this.abo))+")")])],1):_vm._e(),(!_vm.istFirmenAbo(this.abo))?_c('v-row',[_c('v-col',{attrs:{"lg":"4","md":"6","sm":"12","cols":"12"}},[_vm._v("Kosten:")]),_c('v-col',{attrs:{"lg":"8","md":"6","sm":"12","cols":"12"}},[_vm._v(_vm._s(_vm.getAboKosten(this.abo)))])],1):_vm._e(),(
          !_vm.istFirmenAbo(this.abo) &&
          !this.abo.kuendigungBetreiberVorgemerkt &&
          this.abo.status == 'AKTIV'
        )?_c('v-row',[_c('v-col',{attrs:{"lg":"4","md":"6","sm":"12","cols":"12"}},[_vm._v("Kündigen zum Ende der Laufzeit")]),_c('v-col',{attrs:{"lg":"8","md":"6","sm":"12","cols":"12"}},[(this.abo.vertragsendeDatum == null)?_c('v-btn',{staticClass:"white--text center",attrs:{"color":"#12BAEF","tile":""},on:{"click":function($event){return _vm.aboBeenden()}}},[_vm._v("Abo kündigen")]):_vm._e(),(this.abo.kuendigungVorgemerkt)?_c('v-btn',{staticClass:"white--text center",attrs:{"color":"#12BAEF","tile":""},on:{"click":function($event){return _vm.kuendigungWiderrufen()}}},[_vm._v("Kündigung zurücknehmen")]):_vm._e()],1)],1):_vm._e(),(
          !_vm.istFirmenAbo(this.abo) &&
          this.abo.status == 'INBEZAHLUNG' &&
          this.bezahlungslink != null &&
          this.bezahlungslink != ''
        )?_c('v-row',[_c('v-col',{attrs:{"lg":"4","md":"6","sm":"12","cols":"12"}},[_vm._v("Zahlung abschließen")]),_c('v-col',{attrs:{"lg":"8","md":"6","sm":"12","cols":"12"}},[_c('v-btn',{staticClass:"white--text center",attrs:{"color":"#12BAEF","tile":""},on:{"click":function($event){return _vm.bezahlungAbschliessen()}}},[_vm._v("Zahlung abschließen")])],1)],1):_vm._e(),(!_vm.istFirmenAbo(this.abo) && this.abo.status == 'AKTIV')?_c('v-row',[_c('v-col',{attrs:{"lg":"4","md":"6","sm":"12","cols":"12"}}),_c('v-col',{attrs:{"lg":"8","md":"6","sm":"12","cols":"12"}},[(
              this.abo.kuendigungVorgemerkt ||
              this.abo.kuendigungBetreiberVorgemerkt
            )?_c('v-btn',{staticClass:"white--text center",attrs:{"color":"#12BAEF","tile":""},on:{"click":function($event){return _vm.aboKuendigungsBestatigungLaden()}}},[_vm._v("Kündigungsbestätigung (PDF)")]):_vm._e(),_vm._v(" "),_c('v-btn',{staticClass:"white--text center",attrs:{"color":"#12BAEF","tile":""},on:{"click":function($event){return _vm.aboBestatigungLaden()}}},[_vm._v("Abo-Bestätigung (PDF)")])],1)],1):_vm._e()],1)],1),_c('v-dialog',{attrs:{"width":"70% "},model:{value:(_vm.cancelDialog),callback:function ($$v) {_vm.cancelDialog=$$v},expression:"cancelDialog"}},[_c('v-card',{staticClass:"angebot-dialog"},[_c('v-card-title',[_c('h2',[_vm._v("Kündigung bestätigen")])]),_c('v-card-text',[_vm._v(" Möchten Sie das Abo wirklich kündigen? ")]),_c('v-card-actions',[_c('v-btn',{attrs:{"color":"green"},on:{"click":function($event){return _vm.aboBeendenConfirmed()}}},[_vm._v("Kündigen")]),_c('v-btn',{attrs:{"color":"red"},on:{"click":function($event){_vm.cancelDialog = false}}},[_vm._v("Abbrechen")])],1)],1)],1),_c('v-dialog',{attrs:{"width":"70% "},model:{value:(_vm.reactivateDialog),callback:function ($$v) {_vm.reactivateDialog=$$v},expression:"reactivateDialog"}},[_c('v-card',{staticClass:"angebot-dialog"},[_c('v-card-title',[_c('h2',[_vm._v("Kündigung widerrufen")])]),_c('v-card-text',[_vm._v(" Möchten Sie die Kündigung wirklich wiederufen? ")]),_c('v-card-actions',[_c('v-btn',{attrs:{"color":"green"},on:{"click":function($event){return _vm.reactivateAboConfirmed()}}},[_vm._v("Widerrufen")]),_c('v-btn',{attrs:{"color":"red"},on:{"click":function($event){_vm.reactivateDialog = false}}},[_vm._v("Abbrechen")])],1)],1)],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }