export default class FirmaForTherapeut {
	bezeichner: string;
	firmaId: number;
	therapeutId: number;

	constructor(
		bezeichner: string,
		firmaId: number,
		therapeutId: number
	) {
		this.bezeichner = bezeichner;
		this.firmaId = firmaId;
		this.therapeutId = therapeutId;
	}
}
