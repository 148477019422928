




































































































import Vue from "vue";
import Api from "@/config/api";
import axios from "axios";
import GuthabenAngebotDto from "../../dtos/GuthabenAngebotDto";
import GuthabenAngebot from "../../data/GuthabenAngebot";
import GuthabenAngebotCard from "@/components/shop/GuthabenAngebotCard.vue";
//for displaying guthaben of Patient:
import PatientTerminGuthabenBalken from "@/components/Termin/Patient/PatientTerminGuthabenBalken.vue";
import GuthabenDto from "@/dtos/GuthabenDto";
import DokumentApi from "@/services/api/Dokument";

export default {
  components: {
    PatientTerminGuthabenBalken,
  },
  data() {
    return {
      buchungId: {
        value: 0,
        type: Number,
      },
      status: {
        value: "",
        type: String,
      },
      transactionId: {
        value: "",
        type: String,
      },
      buchungsdatum: Date,
      stornodatum: Date,
      guthabenAngebot: {
        value: "",
        type: String,
      },
      bezahltEinzelTermin: 0,
      bezahltGruppenTermin: 0,
      bezahltBefundTermin: 0,
      bezahltTelefonTermin: 0,
      bezahltAnamneseTermin: 0,
    };
  },
  head: {
    title: function () {
      return {
        inner: "Guthabenbuchung - " + this.guthabenAngebot
      }
    },
  },
  methods: {
    getBuchungInfo() {
      const url = "/guthaben/patient/check/" + this.buchungId;
      axios.get(url, Api.getRequestConfig()).then(response => {
        this.status = response.data.status;
        this.transactionId = response.data.transactionId;
        this.buchungsdatum = response.data.buchungsdatum;
        this.stornodatum = response.data.stornodatum;
        this.guthabenAngebot = response.data.guthabenAngebot;
        switch (this.status) {
          case "PENDING":
            this.status = "Zahlung Ausstehend...";
            break;
          case "PAID":
            this.status = "Zahlung erfolgreich!";
            break;
          case "CANCELLED":
            this.status = "Zahlung Abgebrochen...";
            break;
          case "REFUNDED":
            this.status = "Rückerstattung angefordert.";
            break;
          case "STORNIERT":
            this.status = "Zahlung Storniert.";
            break;
          default:
            this.status =
              "Unbekannter Zahlungszustand... Bitte wenden Sie sich an den Kundenservice.";
            break;
        }
        this.$emit('updateHead');
      })
      .catch(() => {
        window.alert("Verbindungsfehler!")
      });
    },
    getGuthaben: function() {
      const url = "/guthaben/patient/get";
      return axios
        .get<GuthabenDto>(url, Api.getRequestConfig())
        .then(response => {
          this.bezahltAnamneseTermin = response.data.bezahltAnamneseTermin;
          this.bezahltBefundTermin = response.data.bezahltBefundTermin;
          this.bezahltEinzelTermin = response.data.bezahltEinzelTermin;
          this.bezahltGruppenTermin = response.data.bezahltGruppenTermin;
          this.bezahltTelefonTermin = response.data.bezahltTelefonTermin;
        })
        .catch(error => {
          window.alert("Verbindungsfehler!")
          console.log(error);
        });
    },
    backToShop() {
      this.$router
        .push({
          name: "GuthabenBuchung",
        })
        .catch(() => {});
    },
    goToTermine() {
      this.$router
        .push({
          name: "Patient: Termine",
        })
        .catch(() => {});
    },
    printBuchungsbestätigung() {
      DokumentApi.getGuthabenBestaetigung(this.buchungId);
    },
  },
  created() {
    this.buchungId = this.$route.params.id;
    this.getBuchungInfo();
    this.getGuthaben();
  },
};
