


























import AdminPrivilegesListItem from "./AdminPrivilegesListItem.vue";
import AdminMailPasswordListItem from "./AdminMailPasswordListItem.vue";

export default {
  components: {
    AdminPrivilegesListItem,
    AdminMailPasswordListItem,
  },
  props: {
    user: {
      type: Object,
      default: null,
    },
  },
  data: () => ({}),
  methods: {},
};
