export default class ProfilBenutzerDto {
  vorname: string;
  nachname: string;
  email: string;
  passwort: string;
  straße: string;
  hausnummer: string;
  plz: string;
  ort: string;
  land: string;
  profilbild: string;
  ausbildung: string;
  schwerpunkte: string;
  beschreibung: string;
  geburtsdatum: string;
  telefon: string;
  listenanzeige: boolean;

  constructor(
    vorname: string,
    nachname: string,
    email: string,
    passwort: string,
    hausnummer: string,
    straße: string,
    plz: string,
    ort: string,
    land: string,
    profilbild: string,
    ausbildung: string,
    schwerpunkte: string,
    beschreibung: string,
    geburtsdatum: string,
    telefon: string,
    listenanzeige: boolean
  ) {
    this.vorname = vorname;
    this.nachname = nachname;
    this.email = email;
    this.passwort = passwort;
    this.straße = straße;
    this.hausnummer = hausnummer;
    this.plz = plz;
    this.ort = ort;
    this.land = land;
    this.profilbild = profilbild;
    this.ausbildung = ausbildung;
    this.schwerpunkte = schwerpunkte;
    this.beschreibung = beschreibung;
    this.geburtsdatum = geburtsdatum;
    this.telefon = telefon;
    this.listenanzeige = listenanzeige;
  }
}
