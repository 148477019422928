




































































import Button from "../../data/videotherapie/buttons/Button";

export default {
  components: {},
  props: {
    video: {
      type: MediaStream,
      default: null,
    },
    patientId: {
      type: String,
      default: "",
    },
    active: {
      type: Boolean,
      default: false,
    },
    focus: {
      type: Boolean,
      default: undefined,
    },
    focusClickable: {
      type: Boolean,
      default: false,
    },
    muted: {
      type: Boolean,
      default: false,
    },
    selfView: {
      type: Boolean,
      default: false,
    },
    meldung: {
      type: Boolean,
      default: false,
    },
    height: {
      type: String,
      default: "100%",
    },
    name: {
      type: String,
      default: "Kein Name",
    },
    uuid: {
      type: String,
      default: "",
    },
    isViewedByPatient: {
      type: Boolean,
      default: false,
    },
    isTiny:{
      type:Boolean,
      default:false,
    }
  },
  data: () => ({
    disableTherapeutFunctionality: false,
    bottomBar: [
      new Button(
        require("../../assets/dihs/Videotherapie/DiHS_Video_Icon_Lu_Ton4.png"),
        require("../../assets/dihs/Videotherapie/DiHS_Video_Icon_Lu_Ton4.png"),
        require("../../assets/dihs/Videotherapie/DiHS_Video_Icon_Lu_Ton2.png"),
        require("../../assets/dihs/Videotherapie/DiHS_Video_Icon_Lu_Ton2.png"),
        "Audio",
        false
      ),

      new Button(
        require("../../assets/dihs/Videotherapie/DiHS_Video_Icon_Lu_Akte2.png"),
        require("../../assets/dihs/Videotherapie/DiHS_Video_Icon_Lu_Akte1.png"),
        require("../../assets/dihs/Videotherapie/DiHS_Video_Icon_Lu_Akte2.png"),
        require("../../assets/dihs/Videotherapie/DiHS_Video_Icon_Lu_Akte1.png"),
        "Akte",
        true
      ),

      new Button(
        require("../../assets/dihs/Videotherapie/DiHS_Video_Icon_Lu_Kick1.png"),
        require("../../assets/dihs/Videotherapie/DiHS_Video_Icon_Lu_Kick2.png"),
        require("../../assets/dihs/Videotherapie/DiHS_Video_Icon_Lu_Kick1.png"),
        require("../../assets/dihs/Videotherapie/DiHS_Video_Icon_Lu_Kick2.png"),
        "kick",
        true
      ),

      new Button(
        require("../../assets/dihs/Videotherapie/DiHS_Video_Icon_Lu_Meldung1.png"),
        require("../../assets/dihs/Videotherapie/DiHS_Video_Icon_Lu_Meldung2.png"),
        require("../../assets/dihs/Videotherapie/DiHS_Video_Icon_Lu_Meldung2.png"),
        require("../../assets/dihs/Videotherapie/DiHS_Video_Icon_Lu_Meldung3.png"),
        "melden",
        true
      ),
    ],
    dimensions: "30px",
    dimensions_small: "12px",
    localname: "",
  }),
  created() {
    this.disableTherapeutFunctionality = this.isViewedByPatient;
    if (this.disableTherapeutFunctionality) {
      this.bottomBar = null;
    } else {
      //this.bottomBar[0].setActivateAction(this.mute);
      //this.bottomBar[0].setDeactivateAction(this.unmute);
      this.bottomBar[0].setActive(!this.muted);
      //this.bottomBar[1].setActivateAction(this.oneOnOne);
      //this.bottomBar[1].setDeactivateAction(this.oneOnOne);
      this.bottomBar[1].setActivateAction(this.showAkte);
      this.bottomBar[1].setDeactivateAction(this.showAkte);
      this.bottomBar[2].setActivateAction(this.kick);
      this.bottomBar[2].setDeactivateAction(this.kick);
      this.bottomBar[3].setActivateAction(this.doMeldung);
      this.bottomBar[3].setDeactivateAction(() => {
        this.bottomBar[3].setActive(false);
      });
    }
    if (this.name === "" || this.name === undefined || this.name === null) {
      this.localname = "Kein Name";
    } else {
      this.localname = this.name;
    }
  },
  methods: {
    emitFocus: function () {
      if (this.focusClickable && !this.disableTherapeutFunctionality) {
        console.log("Emitting: <focusClicked>");
        this.$emit("focusClicked");
      } else {
        //console.log("Cannot focus this video...");
      }
    },
    unmute: function () {
      if (!this.disableTherapeutFunctionality) {
        this.$emit("update:unmute");
      }
    },
    mute: function () {
      if (!this.disableTherapeutFunctionality) {
        this.$emit("update:mute");
      }
    },
    oneOnOne: function () {
      this.$emit("update:oneOnOne");
    },
    unimplemented: function () {
      this.$emit("update:unimplemented");
    },
    showAkte: function () {
      console.debug("showAkte button pressed for patientId "+ this.patientId)
      if (!this.disableTherapeutFunctionality) {
        console.debug("showAkte triggered")
        this.$emit("update:showAkte", this.patientId);
      }
    },
    kick: function () {
      console.debug("Kick button pressed for patientId "+ this.patientId)
      if (!this.disableTherapeutFunctionality) {
        this.$emit("update:kick", this.patientId);
      }
    },
    doMeldung: function () {
      //console.log("---- Doing Meldung ----");
      if (!this.disableTherapeutFunctionality) {
        this.$emit("update:doMeldung", this.uuid);
      }
    },
  },
  computed: {
    conditionalOuterBox() {
      return {
        outerPadding: this.focus === false,
      };
    },
    conditionalVideoBoxWrapper() {
      return {
        videoBoxWrapper: true,
        videoBoxBorder: true,
      };
    },
    conditionalNameSpacing() {
      return {
        nameSpacingFocussed: this.focus === true,
        spacer: true,
        isAboveVideo: this.isTiny===true,
        
      };
    },
  },
  watch: {
    muted: {
      immediate: true,
      handler(newVal: boolean, oldVal: boolean) {
        if (this.bottomBar != undefined && this.bottomBar != null) {
          this.bottomBar[0].setActive(!newVal);
        }
      },
    },
    meldung: {
      immediate: true,
      handler(newVal: boolean, oldVal: boolean) {
        if (this.bottomBar != undefined && this.bottomBar != null) {
          this.bottomBar[3].setActive(!newVal);
        }
      },
    },
  },
};
