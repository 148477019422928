import Api from "@/config/api";
import axios from "axios";
import { store } from "@/store";
import { saveAs } from "file-saver";

export default {
	getTerminliste(terminListe: any) {
		const url: string =
			Api.getRequestConfig().baseURL + "/pdf/terminliste";
		return axios
			.post(url, terminListe, { headers: Api.getRequestConfig().headers, responseType: 'blob' })
			.then(response => {
				this.dateiLaden(response.data, "Terminliste.pdf")
			})
			.catch(error => {
				window.alert("Verbindungsfehler!")
				console.log(error);
				store.state.error = {
					isVisible: true,
					headline: "Fehler",
					text: error.message,
					okAction: function () { },
				};
				return null
			});
	},
	getTeilnahmebestaetigung(id: number) {
		const url: string =
			Api.getRequestConfig().baseURL + "/pdf/teilnahmebestaetigung/" + id;
		this.getPdf(url, "Teilnahmebestätigung.pdf");
	},
	getAnamneseFragebogen(id: number | null) {
		let url: string =
			Api.getRequestConfig().baseURL + "/pdf/anamnesefragebogen/";
		if (id != null) {
			url += id;
		}
		this.getPdf(url, "Anamnesefragebogen.pdf");
	},
	getAboKuendigungsBestaetigung() {
		const url: string =
			Api.getRequestConfig().baseURL + "/pdf/abokuendigungsbestaetigung";
		this.getPdf(url, "Kündigungsbestätigung.pdf");
	},
	getAboBestaetigung() {
		const url: string =
			Api.getRequestConfig().baseURL + "/pdf/abobestaetigung";
		this.getPdf(url, "Abobestätigung.pdf");
	},
	getGuthabenBestaetigung(guthabenid: string) {
		const url: string =
			Api.getRequestConfig().baseURL + "/pdf/guthabenbestaetigung/" + guthabenid;
		this.getPdf(url, "Buchungsbestätigung.pdf");

	},
	getTerminbuchungsBestaetigung(cartId: string) {
		const url: string =
			Api.getRequestConfig().baseURL + "/pdf/terminbuchungsbestaetigung/" + cartId;
		this.getPdf(url, "Buchungsbestätigung.pdf");

	},
	getBefundfragebogen(id: number) {
		const url: string =
			Api.getRequestConfig().baseURL + "/pdf/befund/" + id;
		this.getPdf(url, "Befund.pdf");
	},	
	getPdf(url: string, dateiname: string) {
		return axios
			.get(url, { headers: Api.getRequestConfig().headers, responseType: 'blob' })
			.then(response => {
				this.dateiLaden(response.data, dateiname)
			})
			.catch(error => {
				window.alert("Verbindungsfehler!")
				console.log(error);
				store.state.error = {
					isVisible: true,
					headline: "Fehler",
					text: error.message,
					okAction: function () { },
				};
				return null

			});
	},
	dateiLaden(blobContent: string, fileName: string) {
		const blob = new Blob([blobContent], { type: 'application/pdf' });
		saveAs(blob, fileName);
	},
	getPatientenDokumentation(id: number) {
		const url: string =
			Api.getRequestConfig().baseURL + "/pdf/patientendokumentation/" + id;
		this.getPdf(url, "Patientendokumentation.pdf");
	},
};
