









































import Vue from 'vue'    
import UploadFiles from "@/components/general/Upload/UploadFiles.vue";
import FileApi from "@/services/api/Files";

export default {
    components: {
        UploadFiles
    },
    data: function() {
        return {
            files: [], 
            uploadDialog: false,    
            storeNewFile: false,       
        };
    },
    created(){
        this.getFileList();
    },
    methods:{
        getFileList: function(){
            FileApi.getFileList().then((response) =>{
                this.files = response;
            })
        },
        uploadFileAndClose() {
            this.uploadDialog = false;
            this.storeNewFile = true;
        }
    }
}
