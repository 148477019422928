var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{style:('width: ' +
    _vm.columnWidth.toString() +
    '%; padding: ' +
    _vm.paddingPercent.toString() +
    '%;padding-bottom:0px;')},[(_vm.element.text === 'leftText')?_c('div',{staticClass:"ratio2-1"},[_c('div',{class:'ratio2-1'},[_c('h1',{staticClass:"cockpitText",style:('padding: calc(6 * ' + _vm.paddingPercent.toString() + '%);')},[_vm._v(" COCKPIT ")])])]):_vm._e(),(_vm.element.text === 'greeting')?_c('div',{staticClass:"ratio2-1"},[_c('div',{class:'ratio2-1',attrs:{"width":"100%","tile":""}},[(_vm.element.icon !== '')?_c('img',{attrs:{"src":require('../../assets/dihs/Dashboard/' + _vm.element.icon),"width":"100%"}}):_vm._e(),_c('h1',{staticClass:"greeting greetingName"},[_vm._v(" Hallo "+_vm._s(_vm.element.descriptiveObject)+" ")]),_c('h3',{staticClass:"greeting greetingDefault"},[_vm._v("Herzlich willkommen!")])])]):(_vm.element.text === 'termin')?_c('div',{staticClass:"ratio1-1"},[_c('router-link',{attrs:{"to":_vm.getUrl()}},[_c('button',{class:'ratio1-1',attrs:{"width":"100%","tile":""}},[(_vm.element.icon !== '')?_c('img',{attrs:{"src":require('../../assets/dihs/Dashboard/' + _vm.element.icon),"width":"100%"}}):_vm._e(),_c('div',{staticClass:"terminText"},[_c('div',{domProps:{"innerHTML":_vm._s(_vm.getTerminTime(_vm.element.descriptiveObject.date))}}),_vm._v(" "+_vm._s(_vm.element.descriptiveObject.title)+" ")])])])],1):(_vm.element.text === 'firma')?_c('div',{staticClass:"ratio1-1"},[(
        _vm.element.link.ziel != null &&
        _vm.element.link.ziel != undefined &&
        _vm.element.link.ziel != ''
      )?_c('a',{attrs:{"href":_vm.getUrl(),"target":_vm.hrefTarget(_vm.element.link),"rel":"noreferrer noopener"}},[_c('button',{class:'ratio1-1',attrs:{"width":"100%","tile":""}},[(_vm.element.icon !== '')?_c('img',{attrs:{"src":_vm.element.icon,"width":"100%"}}):_vm._e()])]):_c('div',{class:'ratio1-1',attrs:{"width":"100%","tile":""}},[(_vm.element.icon !== '')?_c('img',{attrs:{"src":_vm.element.icon,"width":"100%"}}):_vm._e()])]):(_vm.element.text === 'nextTermin')?_c('div',{staticClass:"ratio2-1"},[_c('router-link',{attrs:{"to":_vm.getUrl()}},[_c('button',{class:'ratio2-1',attrs:{"width":"100%","tile":""}},[(_vm.element.icon !== '')?_c('img',{attrs:{"src":require('../../assets/dihs/Dashboard/' + _vm.element.icon),"width":"100%"}}):_vm._e(),_c('div',{staticClass:"nextTerminText"},[_c('h3',[_vm._v("Ihr nächster Termin")]),_c('h4',[_vm._v("Beginn")]),_c('h1',[_vm._v(" "+_vm._s(_vm.getNextDay())+" "+_vm._s(_vm.getNextTime(_vm.element.descriptiveObject.date))+" Uhr ")])])])])],1):(_vm.element.text === 'termineUndLeistungen')?_c('div',{staticClass:"ratio2-1"},[_c('router-link',{attrs:{"to":_vm.getUrl()}},[_c('button',{class:'ratio2-1',attrs:{"width":"100%","tile":""}},[(_vm.element.icon !== '')?_c('img',{attrs:{"src":require('../../assets/dihs/Dashboard/' + _vm.element.icon),"width":"100%"}}):_vm._e(),_c('div',{staticClass:"termineUndLeistungenText"},[_vm._v(" Weitere Termine"),_c('br'),_vm._v(" und Leistungen finden"),_c('br'),_vm._v(" Sie hier! ")])])])],1):(
      _vm.element.text === 'personalTile' || _vm.element.text === 'staticTile'
    )?_c('div',{staticClass:"ratio1-1"},[_c('router-link',{attrs:{"to":_vm.getUrl()}},[_c('button',{class:'ratio1-1',attrs:{"width":"100%","tile":""}},[(_vm.element.icon !== '')?_c('img',{attrs:{"src":require('../../assets/dihs/Dashboard/' + this.cardIcon),"width":"100%"}}):_vm._e()])])],1):(_vm.element.text === 'timeBox')?_c('div',{staticClass:"ratio2-1"},[_c('router-link',{attrs:{"to":_vm.getUrl()}},[_c('div',{class:'ratio2-1',attrs:{"width":"100%","tile":""}},[(_vm.element.icon !== '')?_c('img',{attrs:{"src":require('../../assets/dihs/Dashboard/' + _vm.element.icon),"width":"100%"}}):_vm._e(),_c('div',{staticClass:"timeBoxText"},[_c('h3',[_vm._v(_vm._s(_vm.today))]),_c('h1',[_vm._v(_vm._s(_vm.timestamp))])])])])],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }