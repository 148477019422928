
























































import Files from "../../services/api/Files";

export default {
  data: () => ({}),
  props: {
    uebungsset: Object,
    uebung: Object,
  },
  methods: {},
  computed: {
    url() {
      return (
        "https://player.vimeo.com/video/" + this.uebung.videoUrl.split("/")[3]
      );
    },
  },
};
