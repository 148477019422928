



















































































































































































































import CreateTerminDto from "@/data/CreateTerminDto";
import TerminApi from "@/services/api/Termin";
import TherapeutApi from "@/services/api/Therapeut";
import Therapeut from "../../../data/Therapeut";
import { Termintyp } from "@/services/api/Termintyp";
import SimpleFirmaDto from "@/dtos/SimpleFirmaDto";
import Api from "@/config/api";
import axios from "axios";
export default {
  props: {
    termin: Object,
    terminBearbeiten: Boolean,
  },
  data: function () {
    return {
      terminTypEinzel: Termintyp[Termintyp.EINZEL],
      terminTypGruppe: Termintyp[Termintyp.GRUPPE],
      terminTypTelefon: Termintyp[Termintyp.TELEFON],
      terminTypAnamnese: Termintyp[Termintyp.ANAMNESE],
      terminTypBefund: Termintyp[Termintyp.BEFUND],

      datumMenu: false,
      uhrzeitMenu: false,
      valide: false,
      selectedTermin: null,
      showDialog: false,
      terminInVergangenheit: false,
      termintypen: [],
      isMaxTeilnehmerDisabled: false,
      therapeuten: [],
      firmen: [],
      detailsRegeln: [
        (v: string) => !!v || "Die Details dürfen nicht leer sein.",
        (v: string) => /^.{0,999}$/s.test(v) || "Maximale Länge beachten",
      ],
      befundRegeln: [
        (v: string) => !!v || "Der Befund darf nicht leer sein.",
        (v: string) => /^.{0,30}$/.test(v) || "Maximale Länge beachten",
      ],
      terminartRegeln: [
        (v: string) => !!v || "Die Terminart darf nicht leer sein.",
      ],
      therapeutRegeln: [
        (v: string) => !!v || "Es muss ein Therapeut gewählt werden.",
      ],
      datumRegeln: [(v: string) => !!v || "Das Datum darf nicht leer sein."],
      uhrzeitRegeln: [
        (v: string) => !!v || "Die Uhrzeit darf nicht leer sein.",
      ],
      dauerRegeln: [(v: string) => !!v || "Die Dauer darf nicht leer sein."],
      maxTeilnehmerRegeln: [
        (v: string) => !!v || "Die max. Teilnehmerzahl darf nicht leer sein.",
      ],
    };
  },
  watch: {
    termin: function (newVal: Record<string, any>) {
      const createTerminDto = new CreateTerminDto(
        newVal.id,
        newVal.details,
        newVal.befund,
        newVal.termintyp,
        newVal.datum,
        newVal.maxTeilnehmer,
        newVal.dauer,
        newVal.therapeut.id,
        newVal.status,
        newVal.firmaId,
        newVal.everyoneView
      );

      this.selectedTermin = createTerminDto;

      if (createTerminDto.id != null) {
        const terminDate = new Date(createTerminDto.datum);
        const newTerminDate = new Date();
        newTerminDate.setUTCFullYear(terminDate.getFullYear());
        newTerminDate.setUTCMonth(terminDate.getMonth());
        newTerminDate.setUTCDate(terminDate.getDate());
        newTerminDate.setUTCMinutes(terminDate.getMinutes());
        newTerminDate.setUTCHours(terminDate.getHours());
        newTerminDate.setUTCSeconds(0);
        newTerminDate.setUTCMilliseconds(0);
        this.selectedTermin.datum = newTerminDate;
      }

      if (this.$refs.form != undefined) {
        this.$refs.form.resetValidation();
      }
    },
    terminBearbeiten: function (newVal: boolean) {
      this.showDialog = newVal;
    },
    showDialog: function (newVal: boolean) {
      if (newVal === false) {
        this.$emit("toggleBearbeiten");
      }
    },
  },
  created() {
    if (this.termin) {
      const createTerminDto = new CreateTerminDto(
        this.termin.id,
        this.termin.details,
        this.termin.befund,
        this.termin.termintyp,
        this.termin.datum,
        this.termin.maxTeilnehmer,
        this.termin.dauer,
        this.termin.therapeut.id,
        this.termin.status,
        this.termin.firmaId,
        this.termin.everyoneView
      );
      this.selectedTermin = createTerminDto;
    } else {
      this.selectedTermin = CreateTerminDto.emptyInstance();
    }
    this.termintypen = [
      this.terminTypEinzel,
      this.terminTypGruppe,
      this.terminTypTelefon,
      this.terminTypAnamnese,
      this.terminTypBefund,
    ];
    TherapeutApi.getAllTherapeuten()
      .then((response) => {
        this.therapeuten = response?.data;
        this.sortTherapeuten();
        this.$emit("dataChanged");
      })
      .catch((error) => {
        console.log(error);
      });
    this.getFirmen();
  },
  methods: {
    submit() {
      const terminDate = new Date(this.selectedTermin.datum);
      this.selectedTermin.datum = terminDate;
      TerminApi.storeTermin(this.selectedTermin)
        .then(() => {
          this.$emit("submitted");
          this.showDialog = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    back() {
      this.showDialog = false;
    },
    empty() {
      this.$refs.form.reset();
    },
    reloadTherapeuten() {
      TherapeutApi.getAllTherapeuten()
        .then((response) => {
          this.therapeuten = response?.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    vollerTherapeutenName: (item: Therapeut) => {
      if(item.firmenName != null) {
        return item.vorname + " " + item.nachname +" (" +item.firmenName + ")";
      }
      return item.vorname + " " + item.nachname;
    },
    firmenName: (item: SimpleFirmaDto) => item.bezeichner,
    formateDate(date: string) {
      if (date == "") return "";
      return new Date(date).toLocaleDateString();
    },
    changeTerminart() {
      if (this.terminTypEinzel == this.selectedTermin.termintyp) {
        this.selectedTermin.maxTeilnehmer = "1";
        this.isMaxTeilnehmerDisabled = true;
      } else {
        this.isMaxTeilnehmerDisabled = false;
      }
      if (this.selectedTermin != this.terminTypGruppe) {
        this.selectedTermin.everyoneView = false;
      }
    },
    storeNewDatum(obj: any) {
      const terminDate = new Date(this.selectedTermin.datum);
      const datumParts = obj.split("-");
      terminDate.setUTCFullYear(datumParts[0]);
      terminDate.setUTCMonth(datumParts[1] - 1);
      terminDate.setUTCDate(datumParts[2]);
      this.selectedTermin.datum = terminDate;
      this.$refs.datumMenu.save(obj);
    },
    storeNewUhrzeit(obj: any) {
      const terminDate = new Date(this.selectedTermin.datum);
      const uhrzeitParts = obj.split(":");
      terminDate.setUTCMinutes(uhrzeitParts[1]);
      terminDate.setUTCHours(uhrzeitParts[0]);
      terminDate.setUTCSeconds(0);
      terminDate.setUTCMilliseconds(0);
      this.selectedTermin.datum = terminDate;
      this.$refs.uhrzeitMenu.save(obj);
    },
    terminAktivieren() {
      TerminApi.terminAktivieren(this.selectedTermin.id)
        .then((response) => {
          if (response?.status == 200) {
            this.selectedTermin.status = response.data.status;
            this.$emit("dataChanged");
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    terminAbsagen() {
      if (new Date() > new Date(this.selectedTermin.datum)) {
        this.terminInVergangenheit = true;
      } else {
        TerminApi.terminAbsagen(this.selectedTermin.id)
          .then((response) => {
            if (response?.status == 200) {
              this.selectedTermin.status = response.data.status;
              this.$emit("dataChanged");
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
    getFirmen() {
      const url = "/firma/alle/terminEditieren";
      return axios
        .get(url, Api.getRequestConfig())
        .then((response) => {
          this.firmen = response.data;
          this.firmen.unshift({ id: null, bezeichner: "Keine Firma" });
          this.selectedTermin.firmaId = null;
        })
        .catch((error) => {
          window.alert("Verbindungsfehler!")
          console.log(error);
        });
    },
    sortTherapeuten() {
      if(this.selectedTermin != null && this.selectedTermin != undefined) {
        if(this.selectedTermin.firmaId == null) {

          this.therapeuten.sort(function(a: any, b: any) {
            if(a.firmaId == null && b.firmaId != null) {
              return -1;
            } else if(a.firmaId != null && b.firmaId == null) {
              return 1;
            } else {
              return 0;
            }
          });
        } else {
          const termin = this.selectedTermin
          this.therapeuten.sort(function(a: any, b: any) {
            if(a.firmaId == null && b.firmaId != null) {
              if(b.firmaId == termin.firmaId) {
                return 1;
              }
              return -1;
            } else if(a.firmaId != null && b.firmaId == null) {
              if(a.firmaId == termin.firmaId) {
                return -1;
              }
              return 1;
            } else if(a.firmaId != null && b.firmaId != null) {
              if(a.firmaId == termin.firmaId) {
                return -1;
              } else if(b.firmaId == termin.firmaId) {
                return 1;

              }
              return 0;
            } else {
              return 0;
            }
          });
        }
      }
    }
  },
};
