
















import Termin from "@/data/Termin";

export default {
  props: {
    termin: {
      type: Object,
      default: null,
    }
  },
  data: () => {
    return {
      selectedTermin: null,
      showDialog: false,
    };
  },
  created() {
  },
  watch: {
    termin: function(newVal: Termin) {      
      if(newVal != null) {
        this.selectedTermin = newVal
        this.showDialog = true
      } else {
        this.showDialog = false
      }
    },
  },
  methods: {
    abort() {
      this.$emit("aborted");
      this.showDialog = false;
    },
    stornieren() {
      this.$emit("submitted");
      this.showDialog = false;
    },
  },
};
