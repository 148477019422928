















































































































































































import TherapeutTerminExpansionPanels from "./TherapeutTerminExpansionPanels.vue";
import TerminApi from "@/services/api/Termin";
import { mapMutations } from "vuex";
import { addYears, formatISO } from "date-fns";
import { Termintyp } from "@/services/api/Termintyp";
export default {
  components: {
    TherapeutTerminExpansionPanels,
  },
  data: () => ({
    terminTypEinzel: Termintyp[Termintyp.EINZEL],
    terminTypGruppe: Termintyp[Termintyp.GRUPPE],
    terminTypTelefon: Termintyp[Termintyp.TELEFON],
    terminTypAnamnese: Termintyp[Termintyp.ANAMNESE],
    terminTypBefund: Termintyp[Termintyp.BEFUND],
    termine: [],
    gefilterteTermine: [],
    selectedTermin: null,
    einzeltermine: true,
    gruppentermine: true,
    anamnesetermine: true,
    telefontermine: true,
    befundtermine: true,
    zeitraumMenu: false,
    zeitraum: [],
    tempZeitraum: [],
    today: new Date(Date.now()),
    panels: [],
    cart: [],
    checkbox: false,
    todayString: "",
    todayInOneYearString: "",
  }),
  created() {
    this.getData();
  },
  watch: {
    termine() {
      this.updateFilter();
    },
    telefontermine() {
      this.updateFilter();
    },
    einzeltermine() {
      this.updateFilter();
    },
    gruppentermine() {
      this.updateFilter();
    },
    anamnesetermine() {
      this.updateFilter();
    },
    befundtermine() {
      this.updateFilter();
    },
    zeitraum() {
      this.updateFilter();
    },
  },
  computed: {
    displayedZeitraum: {
      get() {
        if (!this.zeitraum) {
          return "";
        } else {
          const dateArray: string[] = [];
          for (const date of this.zeitraum) {
            dateArray.push(this.formatDate(date));
          }
          if (dateArray.length === 1) return dateArray[0];
          else return dateArray[0] + " - " + dateArray[1];
        }
      },
      set(newZeitraum: any) {
        this.zeitraum = newZeitraum;
      },
    },
    ...mapMutations(["filterSelectedTermine"]),
  },
  methods: {
    getData() {
      if (this.zeitraum.length === 0) {
        this.todayString = formatISO(this.today, { representation: "date" });
        this.todayInOneYearString = formatISO(addYears(this.today, 1), {
          representation: "date",
        });
        this.displayedZeitraum = [this.todayString, this.todayInOneYearString];
      }
      TerminApi.getTherapeutTerminListByStatus("AKTIV").then((response) => {
        this.termine = response?.data;
        this.gefilterteTermine = this.termine;
        this.updateFilter();
      });
    },
    addToCart(termin: any) {
      if (!this.cart.includes(termin)) {
        this.cart.push(termin);
      }
    },
    addAllToCart() {
      for (const termin of this.gefilterteTermine) {
        if (!this.cart.includes(termin)) {
          this.cart.push(termin);
        }
      }
    },
    removeFromCart(termin: any) {
      if (this.cart.includes(termin)) {
        this.cart.pop(termin);
      }
    },
    removeAllFromCart() {
      this.cart = [];
    },
    updateFilter() {
      this.cart = [];
      this.gefilterteTermine = this.termine;
      if (!this.einzeltermine) {
        this.gefilterteTermine = this.gefilterteTermine.filter(
          (termin: any) => {
            return (
              termin.termintyp === this.terminTypGruppe ||
              termin.termintyp === this.terminTypTelefon ||
              termin.termintyp === this.terminTypAnamnese ||
              termin.termintyp === this.terminTypBefund
            );
          }
        );
      }
      if (!this.gruppentermine) {
        this.gefilterteTermine = this.gefilterteTermine.filter(
          (termin: any) => {
            return (
              termin.termintyp === this.terminTypEinzel ||
              termin.termintyp === this.terminTypTelefon ||
              termin.termintyp === this.terminTypAnamnese ||
              termin.termintyp === this.terminTypBefund
            );
          }
        );
      }
      if (!this.telefontermine) {
        this.gefilterteTermine = this.gefilterteTermine.filter(
          (termin: any) => {
            return (
              termin.termintyp === this.terminTypEinzel ||
              termin.termintyp === this.terminTypGruppe ||
              termin.termintyp === this.terminTypAnamnese ||
              termin.termintyp === this.terminTypBefund
            );
          }
        );
      }
      if (!this.befundtermine) {
        this.gefilterteTermine = this.gefilterteTermine.filter(
          (termin: any) => {
            return (
              termin.termintyp === this.terminTypEinzel ||
              termin.termintyp === this.terminTypGruppe ||
              termin.termintyp === this.terminTypAnamnese ||
              termin.termintyp === this.terminTypTelefon
            );
          }
        );
      }
      if (!this.anamnesetermine) {
        this.gefilterteTermine = this.gefilterteTermine.filter(
          (termin: any) => {
            return (
              termin.termintyp === this.terminTypEinzel ||
              termin.termintyp === this.terminTypGruppe ||
              termin.termintyp === this.terminTypTelefon ||
              termin.termintyp === this.terminTypBefund
            );
          }
        );
      }
      if (this.zeitraum) {
        this.gefilterteTermine = this.gefilterteTermine.filter(
          (termin: any) => {
            const terminDateString = termin.datum.substring(0, 10);
            if (this.zeitraum.length === 1) {
              return terminDateString === this.zeitraum[0];
            } else {
              return (
                new Date(terminDateString) >= new Date(this.zeitraum[0]) &&
                new Date(terminDateString) <= new Date(this.zeitraum[1])
              );
            }
          }
        );
      }
      this.$store.commit("filterSelectedTermine", this.gefilterteTermine);
      this.panels = this.getPanelIndizes();
    },
    selectionChanged(selectedItemIndex: number) {
      if (this.termine.length > selectedItemIndex && selectedItemIndex >= 0) {
        this.selectedTermin = this.termine[selectedItemIndex];
      }
    },
    checkboxClickAction() {
      if (this.checkbox) {
        this.addAllToCart();
      } else {
        this.removeAllFromCart();
      }
    },
    clearFilter() {
      this.displayedZeitraum = [this.todayString, this.todayInOneYearString];
      this.gruppentermine = true;
      this.einzeltermine = true;
      this.telefontermine = true;
      this.anamnesetermine = true;
      this.befundtermine = true;
    },
    setZeitraum() {
      this.tempZeitraum.sort((a: string, b: string) => {
        return new Date(a) > new Date(b);
      });
      this.zeitraum = this.tempZeitraum;
      this.zeitraumMenu = false;
    },
    zeitraumAbbrechen() {
      this.zeitraumMenu = false;
      this.tempZeitraum = this.zeitraum;
    },
    formatDate(date: string) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return `${day}.${month}.${year}`;
    },
    getPanelIndizes() {
      const indexArray: any[] = [];
      for (const termin of this.gefilterteTermine) {
        if (this.$store.state.selectedTermine.indexOf(termin) > -1) {
          indexArray.push(this.gefilterteTermine.indexOf(termin));
        }
      }
      return indexArray;
    },
  },
};
