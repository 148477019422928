






import VideoPatientView from "@/components/VideoTherapie/VideoPatientView.vue";

export default {
  components: {
    VideoPatientView,
  },
  props: {},
  data: () => ({
    conferenceId: undefined,
  }),
  created() {
    this.conferenceId = this.$route.params.conferenceId;
    this.$store.commit("login", this.$route.params.bearer);
  },
  methods: {},
};
