








































































































































































































































































import TrainingsplanApi from "@/services/api/Trainingsplan";
import PatientApi from "@/services/api/Patient";
import UebungApi from "@/services/api/Uebung";
import TrainingsplanVorlage from "@/data/TrainingsplanVorlage";
import { Privilege } from "@/data/user/Privilege";

export default {
  components: {},
  data() {
    return {
      vorlagen: [],
      gotData: false,
      selectedVorlage: undefined,
      vorlagenPlan: [],
      uebungen: [],
      freieUebungen: [],
      uebungenInTrainingsplan: [],
      disableNewButton: false,

      selectedUebung: null,
      selectedIndex: null,

      removeDialog: false,
      addDialog: false,
      neueUebungObjekt: undefined,
      neueUebungTitel: undefined,

      showFormular: false,
      renameDialog: false,
      areYouSure: false,
      newName: "",
    };
  },
  created() {
    if (
      !this.$store.getters.hasPrivilege(
        Privilege.TRAININGSPLAN_VORLAGEN_VERWALTEN
      )
    ) {
      console.log("Insufficient Permissions");
      this.$router.push("/").catch(() => {});
    } else {
      this.getVorlagen();
    }
  },
  methods: {
    editUebung(uebung: any, pos: number) {
      this.selectedIndex = pos;
      this.selectedUebung = uebung;
      this.showFormular = true;
    },
    closeEdit() {
      TrainingsplanApi.updateUebungVorlage(this.selectedUebung);
      this.selectedIndex = null;
      this.selectedUebung = null;
      this.showFormular = false;
    },
    deleteUebung() {
      TrainingsplanApi.deleteUebungVorlage(this.selectedUebung);
      if (this.selectedIndex > -1) {
        this.selectedUebung = null;
        this.vorlagenPlan.splice(this.selectedIndex, 1);
      }
      this.selectedIndex = null;
      this.showFormular = false;
      this.filterUebungen();
    },
    addUebungDialog(selectedUebung: any) {
      this.neueUebungObjekt = TrainingsplanVorlage.emptyInstance();
      this.neueUebungObjekt.uebungId = selectedUebung.id;
      this.neueUebungObjekt.setId = this.selectedVorlage.id;
      this.neueUebungTitel = selectedUebung.titel;
      this.addDialog = true;
      this.showFormular = true;
    },
    cancelAddUebung() {
      this.neueUebungObjekt = undefined;
      this.addDialog = false;
      this.showFormular = false;
    },
    confirmAddUebung() {
      TrainingsplanApi.addUebungToVorlage(this.neueUebungObjekt).then(
        (response) => {
          //console.log(response);
          this.vorlagenPlan.push(response);
          this.filterUebungen();
          this.neueUebungObjekt = undefined;
          this.addDialog = false;
          this.showFormular = false;
        }
      );
    },
    getVorlagen() {
      TrainingsplanApi.getAllVorlagen().then((response) => {
        //console.log(response);
        this.vorlagen = response;
        if (this.vorlagen.length > 0) {
          this.selectedVorlage = this.vorlagen[0];
          this.swapSelectedVorlage();
        } else {
          this.gotData = true;
        }
      });
    },
    getUebungen() {
      UebungApi.getAllUebungenSortiertNachTitel().then((response) => {
        this.uebungen = response?.data;
        this.filterUebungen();
      });
    },
    filterUebungen() {
      this.freieUebungen = this.uebungen.filter((uebung: any) => {
        for (const uebungssatz of this.vorlagenPlan) {
          if (uebung.id === uebungssatz.uebungId) {
            return false;
          }
        }
        return true;
      });
      this.uebungenInTrainingsplan = this.uebungen.filter((uebung: any) => {
        for (const uebungssatz of this.vorlagenPlan) {
          if (uebung.id === uebungssatz.uebungId) {
            uebungssatz.titel = uebung.titel;
            uebungssatz.videoUrl = uebung.videoUrl;
            uebungssatz.videoVorschauBildUrl = uebung.videoVorschauBildUrl;
            return true;
          }
        }
        return false;
      });
      if (
        this.uebungenInTrainingsplan != undefined &&
        this.uebungenInTrainingsplan.length < 1
      ) {
        this.disableNewButton = true;
      } else {
        this.disableNewButton = false;
      }
    },
    swapSelectedVorlage() {
      TrainingsplanApi.getUebungenForVorlage(this.selectedVorlage.id).then(
        (response) => {
          this.vorlagenPlan = response;
          this.gotData = true;
          this.getUebungen();
        }
      );
    },
    getNeueVorlage() {
      TrainingsplanApi.getAllPlusAnotherVorlagen().then((response) => {
        //console.log(response);
        this.vorlagen = response;
        if (this.vorlagen.length > 0) {
          this.selectedVorlage = this.vorlagen[this.vorlagen.length - 1];
          this.swapSelectedVorlage();
        } else {
          this.gotData = true;
        }
      });
    },
    pickerText: (selection: any) => {
      if (
        !selection ||
        selection == undefined ||
        selection.befund == undefined
      ) {
        return "Kein Name";
      } else {
        return selection.befund;
      }
    },
    openRenameDialog() {
      this.renameDialog = true;
      this.areYouSure = false;
      this.newName += this.selectedVorlage.befund;
    },
    closeRenameDialog() {
      this.newName = "";
      this.renameDialog = false;
      this.areYouSure = false;
    },
    deleteEntireVorlagenPlan() {
      TrainingsplanApi.deleteEntirePlan(this.selectedVorlage).then(
        (response) => {
          this.vorlagen;
          const index = this.vorlagen.indexOf(this.selectedVorlage);
          this.vorlagen.splice(index, 1);
          if (this.vorlagen.length > 0) {
            this.selectedVorlage = this.vorlagen[this.vorlagen.length - 1];
            this.swapSelectedVorlage();
          } else {
            this.selectedVorlage = undefined;
            this.disableNewButton = false;
          }
          this.newName = "";
          this.areYouSure = false;
          this.renameDialog = false;
        }
      );
    },
    saveRenameDialog() {
      this.selectedVorlage.befund = this.newName;
      TrainingsplanApi.renameVorlage(
        this.selectedVorlage.befund,
        this.selectedVorlage.id
      );
      this.newName = "";
      this.renameDialog = false;
    },
  },
};
