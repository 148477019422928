












































export default {
  props: {
    uebungen: Array,
  },
  data: () => {
    return {
      filter: "",
      page: 1,
      entriesPerPage: 50,
      pageCount: 0,
    };
  },
  watch: {
    filteredRows(newvalue: any) {
      let listLength: number = this.uebungen.length;
      if (this.filter) {
        listLength = newvalue.length + 1;
      }
      this.pageCount = Math.ceil(listLength / this.entriesPerPage);
      if (this.page > this.pageCount) {
        this.page = this.pageCount;
      }
    },
  },
  computed: {
    filteredRows() {
      const filteredList = this.uebungen.filter(
        (row: { titel: { toString: () => string } }) => {
          const title = row.titel.toString().toLowerCase();
          return (
            !this.filter ||
            "" == this.filter ||
            title.indexOf(this.filter.toLowerCase()) > -1
          );
        }
      );

      return filteredList.slice(
        (this.page - 1) * this.entriesPerPage,
        this.page * this.entriesPerPage
      );
    },
  },
  methods: {
    callAddDialog(item: any) {
      this.$emit("addUebungZumTrainingsplan", item);
    },
    clearFilter() {
      this.filter = "";
    },
  },
};
