



































































































import AboApi from "@/services/api/Abo";
import CreateAboPaketDto from "@/dtos/CreateAboPaketDto";
import AboNeuesPaketDialogBetreiber from "@/components/abo/betreiber/AboNeuesPaketDialogBetreiber.vue";
import AboPaketDto from "@/dtos/AboPaketDto";
import AboPaket from "@/data/AboPaket";

export default {
  components: {
    AboNeuesPaketDialogBetreiber,
  },
  data: () => ({
    pakete: [] as Array<AboPaket>,
    openedPakete: [] as Array<AboPaket>,
    changedPakete: [] as Array<AboPaket>,
    selectedPaket: null,
    titleRegeln: [
      (v: string) => !!v || "Der Titel darf nicht leer sein.",
      (v: string) => /^.{0,30}$/.test(v) || "Maximale Länge beachten",
    ],
  }),
  created() {
    this.getData();
  },
  watch: {},
  computed: {},
  methods: {
    getData() {
      AboApi.getBetreiberAllePakete().then(response => {
        this.pakete = response;
      });
    },
    pushIfNotYetPushed(aboPaket: any) {
      if (!this.changedPakete.includes(aboPaket)) {
        this.changedPakete.push(aboPaket);
      }
    },
    hasChanged(clickedPaket: any) {
      for (const paket of this.changedPakete) {
        if (paket.aboPaketId == clickedPaket.aboPaketId) {
          return false;
        }
      }
      return true;
    },
    formatePreis(preis: number) {
      return preis / 100 + " €";
    },
    showPaketDetails(aboPaketId: number) {
      if (this.openedPakete.indexOf(aboPaketId) > -1) {
        return true;
      } else {
        return false;
      }
    },
    addSelectedAboPaket(aboPaketId: number) {
      if (this.showPaketDetails(aboPaketId)) {
        const index = this.openedPakete.indexOf(aboPaketId);
        this.openedPakete.splice(index, 1);
      } else {
        this.openedPakete.push(aboPaketId);
      }
    },
    saveThisItem(aboPaket: any) {
      console.log("Saving AboPaket: " + aboPaket);
      AboApi.changeAboPaket(aboPaket);
      this.changedPakete = this.changedPakete.filter(
        (obj: any) => obj !== aboPaket
      );
    },
    erstelleNeuesPaket() {
      const neuesAboPaket = new CreateAboPaketDto();
      this.selectedPaket = neuesAboPaket;
    },
    submitted() {
      this.getData();
    },
  },
};
