












































































































import CreateAboPaketDto from "@/dtos/CreateAboPaketDto";
import AboApi from "@/services/api/Abo";
export default {
  props: {
    paket: {
      type: CreateAboPaketDto,
      default: null,
    }
  },
  data: function() {
    return {
      showDialog: false,    
      selectedPaket: null,
      valide: false,
      kostenProQuartalInEuro: 0,
      titleRegeln: [
        (v: string) => !!v || "Der Titel darf nicht leer sein.",
        (v: string) => /^.{0,30}$/.test(v) || "Maximale Länge beachten",
      ],
      einzeltermineRegeln: [(v: number) => v >=0 || "Die Anzahl darf nicht leer sein."],
      gruppentermineRegeln: [(v: number) => v >=0 || "Die Anzahl darf nicht leer sein."],
      trainingsplaeneRegeln: [(v: number) => v >=0 || "Die Anzahl darf nicht leer sein."],
      befundtermineRegeln: [(v: number) => v >=0 || "Die Anzahl darf nicht leer sein."],
      kostenRegeln: [(v: number) => v >=0 || "Die Anzahl darf nicht leer sein."],
      laufzeitRegeln: [
        (v: number) => v >=0  || "Die Anzahl darf nicht leer sein.",
        (laufzeit: number) => {
          const buchungsInterval = this.$data["selectedPaket"].buchungMonate;
          const rest = laufzeit % buchungsInterval;
          return rest == 0 || "Das Buchungsinterval muss durch die Laufzeit teilbar sein sein"
        }
      ],
      buchungenRegeln: [
        (v: number) => v >=0  || "Die Anzahl darf nicht leer sein.",
        (buchungsInterval: number) => {
          const laufzeit = this.$data["selectedPaket"].laufzeitMonate;
          const rest = laufzeit % buchungsInterval;
          return rest == 0 || "Das Buchungsinterval muss durch die Laufzeit teilbar sein sein"
        }
      ],
      kuendigungsfristRegeln: [(v: number) => v >=0  || "Die Anzahl darf nicht leer sein."],
    };
  },
  watch: {
    paket: function(newVal: CreateAboPaketDto) {
      if(newVal != null) {
        this.selectedPaket = newVal
        this.showDialog = true
      }
    },
  },
  created() {
    
  },
  methods: {
    submit() {
      this.selectedPaket.kostenProQuartalCent = Math.floor( this.kostenProQuartalInEuro * 100);
      AboApi.storeAboPaket(this.selectedPaket).then(() => {
          this.$emit("submitted");
          this.showDialog = false;
      })
    },
    back() {
      this.showDialog = false;
    },
    empty() {
      this.selectedPaket = new CreateAboPaketDto();
      this.$refs.form.resetValidation();
    },
  },
};
