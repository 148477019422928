import WebsocketMessageOutgoing from "./WebsocketMessageOutgoing";

export default class MeldungMessage implements WebsocketMessageOutgoing {
	command: string;
	bearer: string = "";
	meldung: boolean;

	constructor(bearer: string, meldung: boolean) {
		this.command = "meldung";
		this.meldung = meldung;
		this.bearer = bearer;
	}
}
