export default class CreateTerminDto {
	id: number | null;
	details: string;
	befund: string;
	termintyp: number;
	datum: string;
	maxTeilnehmer: number;
	dauer: number;
	datumObj: string;
	uhrzeitObj: string;
	status: string;
	therapeutId: number | null | undefined;
	firmaId: number | null | undefined;
	everyoneView: boolean | null | undefined;

	constructor(
		id: number | null,
		details: string,
		befund: string,
		termintyp: number,
		datum: string,
		maxTeilnehmer: number,
		dauer: number,
		therapeutId: number | null | undefined,
		status: string,
		firmaId: number | null | undefined,
		everyoneView: boolean | null | undefined
	) {
		this.id = id;
		this.details = details;
		this.befund = befund;
		this.termintyp = termintyp;
		this.datum = datum;
		this.maxTeilnehmer = maxTeilnehmer;
		this.dauer = dauer;
		this.datumObj = datum.substr(0, 10);
		this.uhrzeitObj = datum.substr(11, 5);
		this.therapeutId = therapeutId;
		this.status = status;
		this.firmaId = firmaId;
		this.everyoneView = everyoneView;
	}

	static emptyInstance() {
		return new CreateTerminDto(null, "", "", 5, "", 0, 0, null, "", null, false);
	}
}
