export default class Trainingsplan {
	id: number | null;
	patientId: number | null;
	uebungId: number | null;
	dauer: number | null;
	anzahl: number | null;
	wiederholungen: number | null;
	information: string;
	position: number | null;
	pause: number | null;
	setId: number | null;

	constructor(
		id: number | null,
		patientId: number | null,
		uebungId: number | null,
		dauer: number | null,
		anzahl: number | null,
		wiederholungen: number | null,
		information: string,
		position: number | null,
		pause: number | null,
		setId: number | null
	) {
		this.id = id;
		this.patientId = patientId;
		this.uebungId = uebungId;
		this.dauer = dauer;
		this.anzahl = anzahl;
		this.wiederholungen = wiederholungen;
		this.information = information;
		this.position = position;
		this.pause = pause;
		this.setId = setId;
	}

	static emptyInstance() {
		return new Trainingsplan(
			null,
			null,
			null,
			null,
			null,
			null,
			"",
			null,
			null,
			null,
		);
	}
}
