





















import CookieLaw from "vue-cookie-law";
export default {
  data: () => ({}),
  components: {
    CookieLaw,
  },
  methods: {},
};
