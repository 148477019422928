























































































import Vue from "vue";
import Api from "@/config/api";
import axios from "axios";
import GuthabenAngebotDto from "../../dtos/GuthabenAngebotDto";
import GuthabenAngebot from "../../data/GuthabenAngebot";
import GuthabenAngebotCard from "@/components/shop/GuthabenAngebotCard.vue";

export default {
  components: {
    GuthabenAngebotCard,
  },
  data() {
    return {
      buyDialog: false,
      chosenOffer: null,
      chosenTitle: "",
      checkbox: true,
      angebote: [] as Array<GuthabenAngebot>,
    };
  },
  methods: {
    guthabenCardEventHandler(guthabenAngebotId: number | undefined) {
      //start a dialog with a BUY button
      if (guthabenAngebotId != undefined) {
        for (const angebot of this.angebote) {
          if (angebot.getId() == guthabenAngebotId) {
            this.chosenOffer = angebot;
          }
        }
        this.chosenTitle = this.chosenOffer.titel;
        this.buyDialog = true;
      } else {
        console.log("Something went wrong here...");
      }
    },
    confirmAndPay(angebot: GuthabenAngebot) {
      const url = "/guthaben/patient/placeOrder/" + angebot.getId();
      axios.get(url, Api.getRequestConfig()).then((response) => {
        //This takes you to the mollie payment page, which in turn will redirect you
        //back to guthaben/<ID> to view your payment status
        window.location.replace(response.data);
      })
      .catch(() =>{
        window.alert("Verbindungsfehler!")
      });

      this.buyDialog = false;
    },
    getOffers() {
      const url = "/guthaben/patient/getOffers";
      axios
        .get<Array<GuthabenAngebotDto>>(url, Api.getRequestConfig())
        .then((response) => {
          response.data.forEach((angebot) => {
            this.angebote.push(
              new GuthabenAngebot(
                angebot.guthabenAngebotId,
                angebot.titel,
                angebot.centPreis,
                angebot.buchbar,
                angebot.einzelTermine,
                angebot.gruppenTermine,
                angebot.telefonTermine,
                angebot.anamnese,
                angebot.befunde
              )
            );
          });
        })
        .catch((e) => {
          window.alert("Verbindungsfehler!")
          console.log("Failed with: " + e)
        });
    },
  },
  created() {
    this.getOffers();
  },
};
