

















































































































































import Authentication from "@/services/api/Authentication";
import { mapGetters } from "vuex";
import api from "../../config/api";
import Axios from "axios";
import { Role } from "../../data/user/Role";
import rules from "@/config/rules";
export default {
  data: () => {
    return {
      email: "",
      password: "",
      secondFactor: "",
      dialog: false,
      passwordReset: false,
      resetRequestSend: false,
      resetSuccessful: undefined,
      switchPage: false,
      rules: rules,
      logoutTimeout: undefined,
      timedOut: false,
      showSecondFactor: false,
    };
  },
  created() {
    this.checkTokenExpiration();
    if(this.$route.query.login !== undefined) {
      this.dialog = true
    }
  },
  computed: {
    ...mapGetters(["loggedIn", "isRole"]),
    isPatient() {
      return this.isRole(Role.PATIENT);
    },
    isTherapeut() {
      return this.isRole(Role.THERAPEUT);
    },
  },watch: {
    $route() {
      if(this.$route.query.login !== undefined) {
        this.dialog = true
      }
    },
    dialog(visible: boolean) {
      this.email = "";
      this.password = "";
      this.secondFactor = "";
      this.passwordReset = false;
      this.resetRequestSend = false;
      this.resetSuccessful = undefined;
      this.showSecondFactor = false;
    },
    "$store.state.authentication.token": function () {
      this.checkTokenExpiration();
      if (this.switchPage) {
        this.switchPage = false;
        if (this.isPatient) {
          this.$router
            .push({
              name: "Cockpit",
            })
            .catch(() => {});
        } else if (this.isTherapeut) {
          this.$router
            .push({
              name: "therapeutTermine",
            })
            .catch(() => {});
        } else {
          this.$router
            .push({
              name: "Startseite",
            })
            .catch(() => {});
        }
      }
    },
  },
  methods: {
    jumpToPassField() {
      this.$refs.passwordfield.focus();
    },
    enterPressed() {
      if (this.passwordReset) {
        this.reset();
      } else {
        this.checkSecondFactor();
      }
    },
    abort() {
      this.dialog = false;
      if (this.$refs.form != null && this.$refs.form != undefined) {
        this.$refs.form.resetValidation();
      }
    },
    retry() {
      this.resetRequestSend = false;
      this.resetSuccessful = undefined;
    },
    reset() {
      this.resetRequestSend = true;
      Axios.post("/patient/passwort/reset", this.email, api.getRequestConfig())
        .then(() => {
          this.resetSuccessful = true;
        })
        .catch(() => {
				  window.alert("Verbindungsfehler!")
          this.resetSuccessful = false;
        });
    },
    stopReset() {
      this.passwordReset = false;
    },
    startReset() {
      this.password = "";
      this.passwordReset = true;
    },
    checkSecondFactor() {
      if (this.showSecondFactor == true) {
        this.login();
      } else {
        //const thisAlias = this;
        Authentication.checkSecondFactor(this, this.email, this.password)
          .then((response) => {
            if (response?.data == true) {
              this.showSecondFactor = true;
            } else {
              this.login();
            }
          })
          .catch(function (error) {
            this.$store.state.error = {
              isVisible: true,
              headline: "Fehler bei der Anmeldung",
              text: error.response.data.message,
            };
            this.abort();
            if (!this.timedOut) {
              this.switchPage = true;
            }
            this.timedOut = false;
          });
      }
    },
    login() {
      let secondFactorKey = "";
      if (this.showSecondFactor) {
        secondFactorKey = this.secondFactor;
      }
      Authentication.retrieveSessionToken(
        this,
        this.email,
        this.password,
        secondFactorKey
      );
      this.abort();
      if (!this.timedOut) {
        this.switchPage = true;
      }
      this.timedOut = false;
    },
    logout() {
      Authentication.logout(this);
      window.clearTimeout(this.logoutTimeout);
      this.$router
        .push({
          name: "Startseite",
        })
        .catch(() => {});
      if (this.$refs.form != null && this.$refs.form != undefined) {
        this.$refs.form.resetValidation();
      }
    },
    checkTokenExpiration() {
      if (this.loggedIn) {
        const expirationTime: number = this.$store.state.authentication
          .expiration;
        const millisecondsValid: number =
          (expirationTime - Date.now() / 1000) * 1000;
        if (millisecondsValid < 0) {
          this.logout();
        } else {
          this.logoutTimeout = window.setTimeout(() => {
            this.timedOut = true;
            Authentication.logout(this);
            this.$store.state.error = {
              isVisible: true,
              headline: "Login abgelaufen",
              text:
                "Ihr Login ist abgelaufen, bitte loggen Sie Sich erneut ein.",
              okAction: function () {
                this.$store.state.error.isVisible = false;
              },
            };
          }, millisecondsValid);
        }
      }
    },
  },
};
