<template>
  <v-container>

    <v-row>
      <v-col class="categories" xs="12" sm="12" md="4" lg="3">Aktives Abo</v-col>
      <v-col xs="12" sm="12" md="4" lg="4">
        <div v-if="!edit">
          <span v-if="this.abo != null && this.abo.aboPaket != null">{{this.abo.aboPaket.title}}</span>
          <span v-else>Kein Abo</span>
        </div>
        <div v-else>
          <v-select
            v-if="this.abo == null"
            v-model="aboPaket"
            :items="aboPakete"
            label="Abo-Pakete"
            outlined
            :item-text="aboTitle"
            :item-value="item => item.aboPaketId"
          ></v-select>
        </div>
      </v-col>
      <v-col xs="12" sm="12" md="4" lg="5">
        <a @click="changeEdit()" v-if="this.abo == null">{{edit ? "Änderung abschließen" : "Abo ändern"}}</a>
        <a v-if="this.abo != null" v-on:click="showPaketInfos = ! showPaketInfos">{{showPaketInfos ? 'Details Ausblenden' : 'Details Anzeigen'}}</a>
      </v-col>
    </v-row><v-row v-if="this.abo != null && this.abo.aboPaket != null && showPaketInfos">
      <v-col xs="12" sm="12" md="4" lg="4"></v-col>
      <v-col xs="12" sm="12" md="4" lg="3">
        Laufzeit:
      </v-col>
      <v-col xs="12" sm="12" md="4" lg="5">{{this.abo.aboPaket.laufzeitMonate}} Monate</v-col>
    </v-row>
    <v-row v-if="this.abo != null && this.abo.aboPaket != null && showPaketInfos">
      <v-col xs="12" sm="12" md="4" lg="4"></v-col>
      <v-col xs="12" sm="12" md="4" lg="3">
        Buchungsintervall
      </v-col>
      <v-col xs="12" sm="12" md="4" lg="5">{{this.abo.aboPaket.buchungMonate}} Monate</v-col>
    </v-row>
    <v-row v-if="this.abo != null && this.abo.aboPaket != null && showPaketInfos">
      <v-col xs="12" sm="12" md="4" lg="4"></v-col>
      <v-col xs="12" sm="12" md="4" lg="3">
        Kündigungsfrist
      </v-col>
      <v-col xs="12" sm="12" md="4" lg="5">{{this.abo.aboPaket.kuendigungsfristMonate}} Monate</v-col>
    </v-row>
    <v-row v-if="this.abo != null && this.abo.aboPaket != null && showPaketInfos">
      <v-col xs="12" sm="12" md="4" lg="4"></v-col>
      <v-col xs="12" sm="12" md="4" lg="3">
        Einzeltermine
      </v-col>
      <v-col xs="12" sm="12" md="4" lg="5">{{this.abo.aboPaket.einzelTermineProQuartal}} Stk / Buchungsintervall</v-col>
    </v-row>
    <v-row  v-if="this.abo != null && this.abo.aboPaket != null && showPaketInfos">
      <v-col xs="12" sm="12" md="4" lg="4"></v-col>
      <v-col xs="12" sm="12" md="4" lg="3">
        Gruppentermine
      </v-col>
      <v-col xs="12" sm="12" md="4" lg="5">{{this.abo.aboPaket.gruppenTermineProQuartal}} Stk / Buchungsintervall</v-col>
    </v-row>
    <v-row v-if="this.abo != null && this.abo.aboPaket != null && showPaketInfos">
      <v-col xs="12" sm="12" md="4" lg="4"></v-col>
      <v-col xs="12" sm="12" md="4" lg="3">
        Trainingspläne
      </v-col>
      <v-col xs="12" sm="12" md="4" lg="5">{{this.abo.aboPaket.trainingsplaene}} Stk / Laufzeit</v-col>
    </v-row>
    <v-row class="marginbottom30" v-if="this.abo != null && this.abo.aboPaket != null && showPaketInfos">
      <v-col xs="12" sm="12" md="4" lg="4"></v-col>
      <v-col xs="12" sm="12" md="4" lg="3">
        Befundtermine
      </v-col>
      <v-col xs="12" sm="12" md="4" lg="5">{{this.abo.aboPaket.befundtermine}} Stk / Laufzeit</v-col>
    </v-row>
    <v-row v-if="this.abo != null">
      <v-col xs="12" sm="12" md="4" lg="3"></v-col>
      <v-col xs="12" sm="12" md="4" lg="4">
        Bezahltes Abo:
      </v-col>
      <v-col xs="12" sm="12" md="4" lg="5">{{this.abo.bezahlabo ? "Ja" : "Nein"}}</v-col>
    </v-row>
    <v-row v-if="this.abo != null">
      <v-col xs="12" sm="12" md="4" lg="3"></v-col>
      <v-col xs="12" sm="12" md="4" lg="4">
        Buchungsdatum
      </v-col>
      <v-col xs="12" sm="12" md="4" lg="5">{{formateDate(this.abo.buchungsdatum)}}</v-col>
    </v-row>
    <v-row v-if="this.abo != null && this.abo.vertragsendeDatum != null">
      <v-col xs="12" sm="12" md="4" lg="3"></v-col>
      <v-col xs="12" sm="12" md="4" lg="4">
        Vertragsende
      </v-col>
      <v-col xs="12" sm="12" md="4" lg="5">{{formateDate(this.abo.vertragsendeDatum)}} ({{getAboEndeGrund(this.abo)}})</v-col>
    </v-row>
    <v-row class="marginbottom30" v-if="this.abo != null && !this.abo.bezahlabo && this.abo.vertragsendeDatum == null">
      <v-col xs="12" sm="12" md="4" lg="3"></v-col>
      <v-col xs="12" sm="12" md="4" lg="4">
        Abo Beenden
      </v-col>
      <v-col xs="12" sm="12" md="4" lg="5">
        <v-menu
          ref="vertragsendeDatumMenu"
          v-model="vertragsendeDatumMenu"
          :close-on-content-click="false"
          :return-value.sync="vertragsendeDatum"
          transition="scale-transition"
          offset-y
          min-width="290px"
        >
          <template v-slot:activator="{ on }">
            <v-text-field
              :value="formateDate(vertragsendeDatum)"
              label="Datum"
              :rules="datumRegeln"
              readonly
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="vertragsendeDatum"
            no-title
            scrollable
            locale="de-de"
          >
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="vertragsendeDatumMenu = false">Abbrechen</v-btn>
            <v-btn text color="primary" @click="storeNewDatum(vertragsendeDatum)">OK</v-btn>
          </v-date-picker>
        </v-menu>
        <v-btn @click="firmenAboBeenden()">Abo beenden</v-btn></v-col>
    </v-row>
    <v-row class="marginbottom30" v-if="this.abo != null && this.abo.vertragsendeDatum == null && this.abo.bezahlabo">
      <v-col xs="12" sm="12" md="4" lg="3"></v-col>
      <v-col xs="12" sm="12" md="4" lg="4">
        Abo Beenden
      </v-col>
      <v-col xs="12" sm="12" md="4" lg="5">
        <v-btn @click="bezahlAboBeenden()">Abo beenden</v-btn></v-col>
    </v-row>


    <v-dialog v-model="dialogEdit" persistent max-width="400">
      <v-card>
        <v-card-title class="headline">Änderungen Speichern?</v-card-title>
        <v-card-text>Möchten Sie die Änderungen speichern??</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="editSave">Speichern</v-btn>
          <v-btn color="red darken-1" text @click="editDiscard">Verwerfen</v-btn>
          <v-btn color="blue darken-1" text @click="editBack">Zurück</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogCancelBezahlAbo" persistent max-width="400">
      <v-card>
        <v-card-title class="headline">Abo beeden?</v-card-title>
        <v-card-text>Möchten Sie das Abo des Patienten beenden? Wählen Sie, zu wann das Abo beendet werden soll.</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="aboBeendenBezahlAboConfirmed(true)">Sofort</v-btn>
          <v-btn color="green darken-1" text @click="aboBeendenBezahlAboConfirmed(false)">Zum Laufzeitende</v-btn>
          <v-btn color="red darken-1" text @click="dialogCancelBezahlAbo = false">Abbrechen</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogCancelFirmenAbo" persistent max-width="400">
      <v-card v-if="this.vertragsendeDatumDate != null">
        <v-card-title class="headline">Abo beeden?</v-card-title>
        <v-card-text>Möchten Sie das Firmen-Abo des Patienten zum {{formateDate(vertragsendeDatum)}} beenden?</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="aboBeendenFirmenAboConfirmed()">Beenden</v-btn>
          <v-btn color="red darken-1" text @click="dialogCancelFirmenAbo = false">Abbrechen</v-btn>
        </v-card-actions>
      </v-card>
      <v-card v-else>
        <v-card-title class="headline">Beenden nicht möglich.</v-card-title>
        <v-card-text>Bitte wählen Sie ein Vertragsende.</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red darken-1" text @click="dialogCancelFirmenAbo = false">Schließen</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </v-container>  
</template>

<script>

import AboApi from '@/services/api/Abo.ts';
import AboBuchungDto from '@/dtos/AboBuchungDto.ts';

export default {

  data() {
    return {
      abo: AboBuchungDto,
      aboPakete: [],
      edit: false,
      aboPaket: null,
      dialogEdit: false,
      dialogCancelBezahlAbo: false,
      dialogCancelFirmenAbo: false,
      showPaketInfos: false,
      vertragsendeDatumMenu: false,
      vertragsendeDatum: "",
      vertragsendeDatumDate: null,
      datumRegeln: [(v) => !!v || "Das Datum darf nicht leer sein."],
    };
  },
  props: {
    patientId: {
      type: Number,
      default: null,
    },
  },
  created: function() {
    this.getPatientAbo(this.patientId);
    this.getAboPakete();
    const vertragsendeDatum = new Date();
    vertragsendeDatum.setDate(vertragsendeDatum.getDate()-1);
    this.vertragsendeDatum = vertragsendeDatum.getUTCFullYear() + "-" + (vertragsendeDatum.getUTCMonth()+1) + "-"+vertragsendeDatum.getUTCDate();
    this.vertragsendeDatumDate = vertragsendeDatum
  },
  methods: {
    getPatientAbo(patientId) {
      
      AboApi.ladeAktivesPatientenAboBetreiber(patientId).then((data) => {
        if(data == null) {
          this.abo = null
        } else {
          this.abo = data;

        }
      }).catch((error) => {
        console.log(error);
      });

    },
    getAboPakete() {
      AboApi.getBetreiberAllePakete().then((data) => {
        this.aboPakete = data.filter(paket => {
          return paket.aktiv;
        })
      }).catch((error) => {
        console.log(error)
      })
    },
    changeEdit() {
      if(this.edit) {
        this.dialogEdit = true;
      } else {
        this.edit = !this.edit;
      }
    },
    aboTitle: item => {
      let returnStr = item.title +" (";
      returnStr += "ET: " + item.einzelTermineProQuartal;
      returnStr += ", GT: " + item.gruppenTermineProQuartal;
      returnStr += ", TP: " + item.trainingsplaene;
      returnStr += ", BT: " + item.befundtermine;
      returnStr += ", LZ: " + item.laufzeitMonate;
      returnStr += ", KF: " + item.kuendigungsfristMonate;
      returnStr += ", BR: " + item.buchungMonate;
      returnStr += ")";
      return returnStr;
    },
    aboId: item => item.id,
    editBack() {
      this.dialogEdit = false;
    }, 
    editDiscard() {
      this.aboPaket = null;
      this.edit = false;
      this.dialogEdit = false;
    },
    editSave() {
      this.edit = false;
      this.dialogEdit = false;
      this.patientAboZuweisen();
    },
    patientAboZuweisen() {
      AboApi.abopaketZuweisen(this.aboPaket, this.patientId).then((aktivesAbo) => {
        this.abo = aktivesAbo
      })
      this.$emit("aboChanged");
    },
    formateDate(date) {
      if (date == "") return "";
      return new Date(date).toLocaleDateString();
    },
    storeNewDatum(obj) {
      const date = new Date(this.vertragsendeDatum);
      const dateParts = obj.split("-");
      date.setUTCFullYear(dateParts[0]);
      date.setUTCMonth(dateParts[1] - 1);
      date.setUTCDate(dateParts[2]);
      this.vertragsendeDatumDate = date;
      this.$refs.vertragsendeDatumMenu.save(obj);
    },
    bezahlAboBeenden() {
      this.dialogCancelBezahlAbo = true;
    },
    aboBeendenBezahlAboConfirmed(sofort) {
      this.dialogCancelBezahlAbo = false;
      AboApi.betreiberBezahlAboBeenden(this.patientId, sofort).then(() => {
        this.$emit("aboChanged");
      });
    },
    firmenAboBeenden() {
      this.dialogCancelFirmenAbo = true;
    },
    aboBeendenFirmenAboConfirmed() {
      this.dialogCancelFirmenAbo = false;
      if(this.vertragsendeDatumDate != null) {
        AboApi.betreiberFirmenAboBeenden(this.patientId, this.vertragsendeDatumDate).then(() => {
          this.$emit("aboChanged");
        });
      }
    },
    getAboEndeGrund(abo) {
      return abo.kuendigungVorgemerkt ? "Gekündigt" : (abo.kuendigungBetreiberVorgemerkt ? "Gekündigt durch DiHS" : (abo.widerrufen ? "Widerrufen" : "Unbekannt"));
    },
  }
}
</script>

<style scoped>

</style>