


































































import Api from "@/config/api";
import axios from "axios";
export default {
  data: () => ({
    befunde: [],
    einzelterminInhalte: [],
    rest: [],
    valuesUnchanged: [],
    newBefund: { type: "BEFUND", value: "" },
    newEinzelterminInhalt: { type: "EINZELTERMIN_INHALT", value: "" },
  }),
  methods: {
    getBefunde: function () {
      const url = "/betreiber/verwaltung/Befunde/get";
      return axios
        .get(url, Api.getRequestConfig())
        .then((response) => {
          this.befunde = response.data;
        })
        .catch((error) => {
          window.alert("Verbindungsfehler!")
          console.log(error);
        });
    },
    getEinzelterminInhalte: function () {
      const url = "/betreiber/verwaltung/Einzeltermine/Inhalte/get";
      return axios
        .get(url, Api.getRequestConfig())
        .then((response) => {
          this.einzelterminInhalte = response.data;
        })
        .catch((error) => {
          window.alert("Verbindungsfehler!")
          console.log(error);
        });
    },
    getRest: function () {
      const url = "/betreiber/verwaltung/Sonstige/get";
      return axios
        .get(url, Api.getRequestConfig())
        .then((response) => {
          this.rest = response.data;
          for (let i = 0; i < this.rest.length; i++) {
            this.valuesUnchanged[i] = this.rest[i].value;
          }
        })
        .catch((error) => {
          window.alert("Verbindungsfehler!")
          console.log(error);
        });
    },
    deleteItem(item: any) {
      const url = "/betreiber/verwaltung/delete";
      axios
        .post(url, item, Api.getRequestConfig())
        .then((response) => {
          if (response.status == 200) {
            if (item.type == "BEFUND") {
              const index = this.befunde.indexOf(item);
              if (index > -1) {
                this.befunde.splice(index, 1);
              }
            }
            if (item.type == "EINZELTERMIN_INHALT") {
              const index = this.einzelterminInhalte.indexOf(item);
              if (index > -1) {
                this.einzelterminInhalte.splice(index, 1);
              }
            }
          }
        })
        .catch((response) => {
          window.alert("Verbindungsfehler!")
          console.log(
            "Failed with Status: " +
              response.status +
              ". Message was: " +
              response.message
          );
        });
    },
    save: function (item: any) {
      const url = "/betreiber/verwaltung/save";
      this.newBefund = { type: "BEFUND", value: "" };
      this.newEinzelterminInhalt = { type: "EINZELTERMIN_INHALT", value: "" };
      axios.post(url, item, Api.getRequestConfig()).then((response) => {
        if (
          response.data !== null &&
          response.data !== undefined &&
          response.data !== ""
        ) {
          let insert = true;
          if (response.data.type == "BEFUND") {
            for (let befund of this.befunde) {
              if (befund.id == response.data.id) {
                insert = false;
              }
            }
            if (insert) {
              this.befunde.push(response.data);
            }
          } else if (response.data.type == "EINZELTERMIN_INHALT") {
            for (let inhalt of this.einzelterminInhalte) {
              if (inhalt.id == response.data.id) {
                insert = false;
              }
            }
            if (insert) {
              this.einzelterminInhalte.push(response.data);
            }
          } else {
            // insert into rest
            for (let i = 0; i < this.rest.length; i++) {
              if (this.rest[i].id == response.data.id) {
                this.rest[i] = response.data;
              }
            }
          }
        }
      })
      .catch(() => {
        window.alert("Verbindungsfehler!")
      });
    },
    revertMe(item: any, key: number) {
      item.value = this.valuesUnchanged[key];
    },
    scrollDownOneLine() {
      //This workaround ensures we are staying scrolled to the bottom on line breaks
      window.scrollBy(0, 28);
    },
  },
  created() {
    this.getBefunde();
    this.getEinzelterminInhalte();
    this.getRest();
  },
};
