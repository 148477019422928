

































import TherapeutApi from "@/services/api/Therapeut";
import TherapeutCardComponent from "./TherapeutCard.vue";
export default {
  components: {
    TherapeutCardComponent,
  },
  data: () => ({
    therapeuten: [],
  }),
  created() {
    this.getData();
  },
  methods: {
    getData() {
      TherapeutApi.getAllTherapeutenForPatient().then(response => {
        this.therapeuten = response?.data;
      });
    },
  },
};
