









































































































































import Api from "@/config/api";
import axios from "axios";
import Firma from "@/data/Firma";
export default {
  data: () => ({
    firmen: {
      type: Array,
      value: [],
    },
    loaded: false,
    uploadDialog: false,
    deleteDialog: false,
    editDialog: false,
    newDialog: false,
    editedFirma: null,
    newFirmaName: "",
    newFirmaHatLogo: false,
    newFirmaLogoLink: "",
    newFirmaLogoPath: "",
    newFirmaTagetLink: false,
    titleRegeln: [
      (v: string) => !!v || "Der Titel darf nicht leer sein.",
      (v: string) => /^.{0,50}$/.test(v) || "Maximale Länge beachten",
    ],
  }),
  methods: {
    getFirmen() {
      const url = "/firma/alle";
      return axios
        .get<Firma[]>(url, Api.getRequestConfig())
        .then((response) => {
          this.firmen = response.data;
        })
        .catch((error) => {
          window.alert("Verbindungsfehler!")
          console.log(error);
        });
    },
    deleteItem(id: any) {
      this.editedFirma = id;
      this.deleteDialog = true;
    },
    editItem(id: any) {
      this.editedFirma = id;
      this.editDialog = true;
    },
    cancelEditItem() {
      this.editedFirma = null;
      this.editDialog = false;
    },
    saveEditItem() {
      this.update(this.firmen[this.editedFirma]);
    },
    add() {
      const newFirma = {
        bezeichner: this.newFirmaName,
        hatLogo: this.newFirmaHatLogo,
        logoPath: this.newFirmaLogoPath,
        logoLink: this.newFirmaLogoLink,
        linkTarget: this.newFirmaTagetLink,
      };
      const url = "/firma/neu";
      axios
        .post(url, newFirma, Api.getRequestConfig())
        .then((response) => {
          if (response.status == 200) {
            this.getFirmen();
          }
          this.newDialog = false;
          this.editedFirma = null;
          this.newFirmaName = "";
        })
        .catch((error: Error) => {
          window.alert("Verbindungsfehler!")
          this.$store.commit("setNetworkError");
        });
    },
    update(firma: Firma) {
      const url = "/firma/update";
      axios
        .post(url, firma, Api.getRequestConfig())
        .then((response) => {
          this.editDialog = false;
          this.editedFirma = null;
        })
        .catch((error: Error) => {
          window.alert("Verbindungsfehler!")
          this.$store.commit("setNetworkError");
        });
    },
    remove(firma: Firma) {
      const url = "/firma/entfernen";
      axios
        .post(url, firma.id, Api.getRequestConfig())
        .then((response) => {
          if (response.status == 200) {
            this.getFirmen();
          }
          this.deleteDialog = false;
          this.editedFirma = null;
        })
        .catch((error: Error) => {
          window.alert("Verbindungsfehler!")
          this.$store.commit("setNetworkError");
        });
    },
    cancel() {
      this.uploadDialog = false;
      this.deleteDialog = false;
      this.newDialog = false;
      this.editedFirma = null;
    },
  },
  created() {
    this.getFirmen();
  },
};
