















































































































import Api from "@/config/api";
import axios from "axios";
import SecondFactor from '@/services/api/SecondFactor';
import ZweiFaktorInstallationsDto from '@/dtos/ZweiFaktorInstallationsDto';

export default {
  data() {
    return {
      isActivated: Boolean,
      showActivationDialog: false,
      showDeactivationDialog: false,
      secondFactorPassword: "",
      currentStep: 0,
      passwordErrorMessage: "",
      activationData: null,
      qrCodeData: "",
      secondFactorToken: "",
      tokenErrorMessage: "",
      deactivationErrorMessage: "",
    };
  },
  components: {

  },
  props: {

  },
  created: function() {
    this.loadCurrentStatus();
  },
  methods: {
    continue2FAActivation() {

      this.passwordErrorMessage = "";
      this.tokenErrorMessage = "";
      if(this.currentStep == 1) {

        // Validiere zunächst das Passwort und hole einen privaten Schlüssel
        SecondFactor.getActivationPrivateKey(this.secondFactorPassword).then(response => {
          
          this.activationData =	new ZweiFaktorInstallationsDto(
            response?.data.qrCode??"",
            response?.data.privateKey??""
            );
          this.currentStep = 2;

        })
        .catch(error => {
          if(error.response.status == 400) {
            this.passwordErrorMessage = error.response.data;
          } else {
            if(error.response.status == 401) {
              this.passwordErrorMessage = error.response.data.message;
            } else {
              this.tokenErrorMessage = "Fehler bei der Validierung";
            }
          }
        });

      } else if(this.currentStep == 2) {

        // Übermittle das Passwort zur erneuten Authentifizierung, den privaten Schlüssel und den eingegebenen Token,
        // um die Einrichtung abzuschließen.
        SecondFactor.activatePrivateKey(this.secondFactorPassword, this.activationData.privateKey, this.secondFactorToken).then(() => {
            this.currentStep = 3;
            this.secondFactorToken = "";
            this.secondFactorPassword = "";
            this.activationData = null;
            this.loadCurrentStatus();
        
        })
        .catch(error => {
          if(error.response.status == 400) {
            this.tokenErrorMessage = error.response.data;
          } else {
            if(error.response.status == 401) {
              this.tokenErrorMessage = error.response.data.message;
            } else {
              this.tokenErrorMessage = "Fehler bei der Validierung";
            }
          }
        });
      }
    },
    continue2FADeactivation() {

      this.deactivationErrorMessage = "";
      if(this.currentStep == 1) {

        // Validiere zunächst das Passwort und hole einen privaten Schlüssel
        SecondFactor.deactivate(this.secondFactorPassword, this.secondFactorToken).then(() => {
          
          this.currentStep = 2;
          this.loadCurrentStatus();

        })
        .catch(error => {
          if(error.response.status == 400) {
            this.deactivationErrorMessage = error.response.data;
          } else {
            if(error.response.status == 401) {
              this.deactivationErrorMessage = error.response.data.message;
            } else {
              this.deactivationErrorMessage = "Fehler bei der Validierung";
            }
          }
        });
      }
    },
    startActivation() {
      this.resetData();
      this.showActivationDialog = true;
      this.currentStep = 1;
    },
    startDeactivation() {
      this.resetData();
      this.showDeactivationDialog = true;
      this.currentStep = 1;
    },
    abord2FADeactivation() {
      this.resetData();
      this.showDeactivationDialog = false;
      
    },
    abord2FAActivation() {
      this.resetData();
      this.showActivationDialog = false;
      
    },
    resetData() {
      this.secondFactorPassword = "";
      this.secondFactorToken = "";
      this.activationData = "";
      this.currentStep = 0;
      this.passwordErrorMessage = "";
      this.tokenErrorMessage = "";
      this.deactivationErrorMessage = "";
    },
    loadCurrentStatus() {
      SecondFactor.getMyStatus()
        .then(response => {
          this.isActivated = response?.data;
        });
    }
  },
};
