import Api from "@/config/api";
import axios from "axios";
import Trainingsplan from '@/data/Trainingsplan';
import TrainingsplanVorlage from "@/data/TrainingsplanVorlage";

export default {

	getEmptySetForPatient(id: number) {
		const url: string =
			Api.getRequestConfig().baseURL + "/trainingsplan/therapeut/createset/" + id;
		return axios.get(url, {
			headers: Api.getRequestConfig().headers,
		})
		.catch(() => {
			window.alert("Verbindungsfehler!")
			return null
		});
	},
	getSetFromVorlageForPatient(id: number, plan: any) {
		const url: string =
			Api.getRequestConfig().baseURL + "/trainingsplan/therapeut/withvorlage/" + id;
		return axios.post(url, plan, {
			headers: Api.getRequestConfig().headers,
		}).then((response) => {
			console.log(response)
			return response.data
		}).catch(error => {
			window.alert("Verbindungsfehler!")
			console.log(error);
			return null
		});;
	},
	changeDateForThisSet(set: any) {
		const url: string =
			Api.getRequestConfig().baseURL + "/trainingsplan/therapeut/changedate";
		return axios
			.post(url, set, { headers: Api.getRequestConfig().headers })
			.then(() => {
			})
			.catch(error => {
				window.alert("Verbindungsfehler!")
				console.log(error);
				return null
			});
	},
	getTrainingsplanForPatient(id: number) {
		const url: string =
			Api.getRequestConfig().baseURL + "/trainingsplan/therapeut/" + id;
		return axios.get(url, {
			headers: Api.getRequestConfig().headers,
		})
		.catch(() => {
			return null
		});
	},
	getTrainingsplanSetsForPatient(id: number) {
		const url: string =
			Api.getRequestConfig().baseURL + "/trainingsplan/therapeut/list/" + id;
		return axios.get(url, {
			headers: Api.getRequestConfig().headers,
		})
		.catch(() => {
			window.alert("Verbindungsfehler!")
			return null
		});
	},
	getTrainingsplanBySetId(id: number) {
		const url: string =
			Api.getRequestConfig().baseURL + "/trainingsplan/therapeut/set/" + id;
		return axios.get(url, {
			headers: Api.getRequestConfig().headers,
		})
		.catch(() => {
			window.alert("Verbindungsfehler!")
			return null
		});
	},
	getMyTrainingsplan() {
		const url: string =
			Api.getRequestConfig().baseURL + "/trainingsplan/my";
		return axios.get(url, {
			headers: Api.getRequestConfig().headers,
		})
		.catch(() => {
			window.alert("Verbindungsfehler!")
			return null
		});
	},
	storeTrainingsplan(trainingsplan: Trainingsplan) {
		const url: string =
			Api.getRequestConfig().baseURL + "/trainingsplan/therapeut/save";
		return axios
			.post(url, trainingsplan, { headers: Api.getRequestConfig().headers })
			.then(response => {
				return response.data;
			})
			.catch(error => {
				window.alert("Verbindungsfehler!")
				console.log(error);
				return null
			});
	},
	deleteTrainingsplan(id: number) {
		const url: string =
			Api.getRequestConfig().baseURL + "/trainingsplan/therapeut/" + id;
		return axios
			.delete(url, { headers: Api.getRequestConfig().headers })
			.then(response => {
				return response.data;
			})
			.catch(error => {
				window.alert("Verbindungsfehler!")
				console.log(error);
				return null
			});
	},
	setPositionForUebung(id: number, position: number) {
		const url: string =
			Api.getRequestConfig().baseURL + "/trainingsplan/therapeut/position/" + id + "/" + position;
		return axios
			.post(url, "", { headers: Api.getRequestConfig().headers })
			.then(response => {
				return response.data;
			})
			.catch(error => {
				window.alert("Verbindungsfehler!")
				console.log(error);
				return null
			});
	},
	//Vorlagen
	getAllVorlagen() {
		const url: string =
			Api.getRequestConfig().baseURL + "/trainingsplan/vorlagen/all";
		return axios.get(url, {
			headers: Api.getRequestConfig().headers,
		}).then(response => {
			return response.data;
		})
			.catch(error => {
				window.alert("Verbindungsfehler!")
				console.log(error);
				return null
			});
	},
	getUebungenForVorlage(setId: number) {
		const url: string =
			Api.getRequestConfig().baseURL + "/trainingsplan/vorlagen/uebungen/" + setId;
		return axios.get(url, {
			headers: Api.getRequestConfig().headers,
		}).then(response => {
			return response.data;
		})
			.catch(error => {
				window.alert("Verbindungsfehler!")
				console.log(error);
				return null
			});
	},
	getAllPlusAnotherVorlagen() {
		const url: string =
			Api.getRequestConfig().baseURL + "/trainingsplan/vorlagen/new";
		return axios.get(url, {
			headers: Api.getRequestConfig().headers,
		}).then(response => {
			return response.data;
		})
			.catch(error => {
				window.alert("Verbindungsfehler!")
				console.log(error);
				return null
			});
	},
	renameVorlage(newName: string, id: number) {
		const url: string =
			Api.getRequestConfig().baseURL
			+ "/trainingsplan/vorlagen/rename/" + id;
		return axios.post(
			url, newName, { headers: Api.getRequestConfig().headers }
		).then(
			response => { return response.data }
		).catch(error => {
			window.alert("Verbindungsfehler!")
			console.log(error);
			return null
		});
	},
	deleteEntirePlan(deletePlan: any) {
		const url: string =
			Api.getRequestConfig().baseURL
			+ "/trainingsplan/vorlagen/delete";
		return axios.post(
			url, deletePlan, { headers: Api.getRequestConfig().headers }
		).then(
			response => { return response.data }
		).catch(error => {
			window.alert("Verbindungsfehler!")
			console.log(error);
			return null
		});
	},
	addUebungToVorlage(neueUebung: TrainingsplanVorlage) {
		const url: string =
			Api.getRequestConfig().baseURL
			+ "/trainingsplan/vorlagen/uebungen/add";
		return axios.post(
			url, neueUebung, { headers: Api.getRequestConfig().headers }
		).then(
			response => { return response.data }
		).catch(error => {
			window.alert("Verbindungsfehler!")
			console.log(error);
			return null
		});
	},
	updateUebungVorlage(updatedUebung: any) {
		const url: string =
			Api.getRequestConfig().baseURL
			+ "/trainingsplan/vorlagen/uebungen/update";
		return axios.post(
			url, updatedUebung, { headers: Api.getRequestConfig().headers }
		).then(
			response => { return response.data }
		).catch(error => {
			window.alert("Verbindungsfehler!")
			console.log(error);
			return null
		});
	},
	deleteUebungVorlage(deleteUebung: any) {
		const url: string =
			Api.getRequestConfig().baseURL
			+ "/trainingsplan/vorlagen/uebungen/delete";
		return axios.post(
			url, deleteUebung, { headers: Api.getRequestConfig().headers }
		).then(
			response => { return response.data }
		).catch(error => {
			window.alert("Verbindungsfehler!")
			console.log(error);
			return null
		});
	},
}
