
















import Vue from "vue";
export default {
  data: () => ({
    errorStatusNumber: "",
    errorStatusName: "Fehler",
    errorTitle: "",
    errorDescription: ""
  }),
  components: {
  },
  methods: {},
  created: function() {
    this.errorStatusNumber = this.$route.params.errorId;

console.log("this.errorStatusNumber: " + this.errorStatusNumber)
    switch(this.errorStatusNumber) {
      case "404": 
        this.errorTitle = "Seite nicht gefunden";
        this.errorDescription = "Die angeforderte Seite konnte nicht gefunden werden.";
        break;
      case "403": 
        this.errorTitle = "Fehlende Berechtigung";
        this.errorDescription = "Sie sind nicht berechtigt, die angeforderte Seite aufzurufen.";
        break;
      case "401": 
        this.errorTitle = "Fehlende Berechtigung";
        this.errorDescription = "Sie sind nicht berechtigt, die angeforderte Seite aufzurufen.";
        break;
      case "400": 
        this.errorTitle = "Fehler bei der Anfrage";
        this.errorDescription = "Die Anfrage war fehlerhaft und konnte nicht ausgeführt werden.";
        break;
      case "500": 
        this.errorTitle = "Serverfehler";
        this.errorDescription = "Ein Serverfehler ist aufgetreten. Bitte versuchen Sie es später erneut.";
        break;
      default:
        this.errorTitle = "Allgemeiner Fehler";
        this.errorDescription = "Ein Fehler ist aufgetreten. Bitte versuchen Sie es später erneut.";
        break;
    }
  }
};
