
















































































































































































































































import ProfileMailListItem from "./ProfileMailListItem.vue";
import ProfilePasswordListItem from "./ProfilePasswordListItem.vue";
import ProfileAddressListItem from "./ProfileAddressListItem.vue";
import ProfileCityListItem from "./ProfileCityListItem.vue";
import ProfilePhoneListItem from "./ProfilePhoneListItem.vue";
import ProfileCountryListItem from "./ProfileCountryListItem.vue";
import ProfileGeburtsdatumListItem from "./ProfileGeburtsdatumListItem.vue";
import ProfileNameListItem from "./ProfileNameListItem.vue";
import UserPrivilegesListItem from "./UserPrivilegesListItem.vue";
import ProfileBemerkungListItem from "./ProfileBemerkungListItem.vue";
import ProfileDokumentListItem from "./ProfileDokumentListItem.vue";
import { ProfilViewType } from "@/data/ProfilViewType";
import AdminMailPasswordListItem from "./AdminMailPasswordListItem.vue";
import ProfileAboStatus from "./ProfileAboStatus.vue";
import AboApi from "@/services/api/Abo";
import PatientenProfilAboVerwaltung from "./PatientenProfilAboVerwaltung.vue";
import PatientenProfilGuthabenVerwaltung from "./PatientenProfilGuthabenVerwaltung.vue";
import PatientProfilZweiFaktorVerwaltung from "./PatientProfilZweiFaktorVerwaltung.vue";
import FirmaDesPatientenItem from "./FirmaDesPatientenItem.vue";
import DokumentApi from "@/services/api/Dokument";
import Axios from "axios";
import Api from "@/config/api";

export default {
  data() {
    return {
      patientAbo: undefined,
      patientLoeschenDialog: false,
      patientLoeschenStep: false,
      loeschenTimeOut: false,
      patientLoeschenInputTest: "",
      loeschungsstatus: undefined,
      isGeloescht: undefined,
    };
  },
  components: {
    ProfileMailListItem,
    ProfilePasswordListItem,
    ProfileAddressListItem,
    ProfileCityListItem,
    ProfileCountryListItem,
    ProfileGeburtsdatumListItem,
    UserPrivilegesListItem,
    ProfileNameListItem,
    ProfileBemerkungListItem,
    ProfileDokumentListItem,
    AdminMailPasswordListItem,
    ProfileAboStatus,
    PatientenProfilAboVerwaltung,
    PatientenProfilGuthabenVerwaltung,
    PatientProfilZweiFaktorVerwaltung,
    FirmaDesPatientenItem,
    ProfilePhoneListItem,
  },
  props: {
    info: {
      type: Object,
      default: null,
    },
    user: {
      type: Object,
      default: null,
    },
    profilViewType: {
      type: Number,
      default: null,
    },
    dokumente: {
      type: Array,
      default: null,
    },
    patientId: {
      type: Number,
      default: null,
    },
    userIdProp: {
      type: Number,
      default: null,
    },
  },
  computed: {
    conditionalRendering: function () {
      return {
        dialogframeblue: !this.patientLoeschenStep,
        dialogframered: this.patientLoeschenStep,
      };
    },
  },
  methods: {
    formateDate(date: string) {
      if (date == "") return "";
      return new Date(date).toLocaleDateString();
    },
    goToFragebogen() {
      this.$router
        .push({
          name: "anamneseFragebogenPatient",
          params: { patientId: this.info.patientId },
        })
        .catch(() => {});
    },
    goToBefundFragebogen() {
      this.$router
        .push({
          name: "frageboegen",
          params: { patientId: this.info.patientId },
        })
        .catch(() => {});
    },
    goToTrainingsplan() {
      this.$router
        .push({
          name: "Therapeut: Trainingsplan",
          params: { patient: this.info.patientId },
        })
        .catch(() => {});
    },
    goToTerminplant() {
      this.$router
        .push({
          name: "Betreiber: Termine für Patient",
          params: { patient: this.info.patientId },
        })
        .catch(() => {});
    },
    goToRezepte() {
      this.$router
        .push("/betreiber/rezepte/" + this.info.patientId)
        .catch(() => {});
    },
    dataChanged() {
      this.$emit("dataChanged");
    },
    ladeAktivesAbo() {
      if (this.profilViewType === 0) {
        AboApi.ladeAktivesPatientenAbo().then((response) => {
          this.patientAbo = response;
        });
      }
    },
    aboChanged() {
      this.$router.go();
    },
    dokumentationHerunterladen() {
      DokumentApi.getPatientenDokumentation(this.info.patientId);
    },
    openPatientLoeschenDialog() {
      this.patientLoeschenDialog = true;
    },
    abortPatientLoeschenDialog() {
      this.patientLoeschenDialog = false;
      this.patientLoeschenStep = false;
      this.patientLoeschenInputTest = "";
      this.loeschenTimeOut = false;
      this.loeschungsstatus = undefined;
    },
    advancePatientLoeschenDialog() {
      this.patientLoeschenStep = true;
      setTimeout(() => {
        if (this.patientLoeschenStep) {
          this.loeschenTimeOut = true;
        }
      }, 6000);
    },
    confirmPatientLoeschenDialog() {
      if (
        this.patientLoeschenDialog &&
        this.patientLoeschenStep &&
        this.loeschenTimeOut
      ) {
        console.log("I would now delete this Patient forever...");
        this.issueDeleteOrderForPatient();
      }
    },
    getDeleteStatusForPatient() {
      const url: string =
        "profil/betreiber/istgeloescht/" + this.info.patientId + "/";
      Axios.get(url, Api.getRequestConfig()).then((response) => {
        this.isGeloescht = response.data;
      })
      .catch(() => {
        window.alert("Verbindungsfehler!")
      });
    },
    issueDeleteOrderForPatient() {
      //AUFPASSEN: Finale Löschung von Daten in der Datenbank.
      const url: string =
        "/profil/betreiber/deleteforever/" + this.info.patientId + "/";
      Axios.delete(url, Api.getRequestConfig())
        .then((response) => {
          if ((response.status == 200)) {
            console.log("Response: " + response.data);
            this.loeschungsstatus = response.data;
            switch (response.data) {
              case "gestartet":
                this.loeschungsstatus =
                  "Gestartet, es müssen aber noch manche Daten behalten werden.";
                break;
              case "abgeschlossen":
                this.loeschungsstatus =
                  "Abgeschlossen, alle Daten wurden entfernt.";
                break;
              default:
                this.loeschungsstatus =
                  "Unbekannt. (Vielleicht ist etwas schiefgelaufen)";
                break;
            }
          }
        })
        .catch(() => {
          window.alert("Verbindungsfehler!")
          this.loeschungsstatus =
            "Ein Fehler ist bei der Löschung Aufgetreten.";
        });
    },
    leaveProfileAfterDelete() {
      this.patientLoeschenDialog = false;
      this.patientLoeschenStep = false;
      this.patientLoeschenInputTest = "";
      this.loeschenTimeOut = false;
      this.loeschungsstatus = undefined;
      this.$router.push("/").catch(() => {});
    },
  },
  created() {
    this.ladeAktivesAbo();
    this.getDeleteStatusForPatient();
  },
};
