




























































































import SeiteDto from "@/data/inhaltsseite/SeiteDto";
import SeiteMetaDto from "@/data/inhaltsseite/SeiteMetaDto";
import SeiteResponseDto from "@/data/inhaltsseite/SeiteResponseDto";
import axios from "axios";
import Api from "@/config/api";
import privilege, { Privilege } from "@/data/user/Privilege";
import role from "@/data/user/Role";
import Vue from "vue";
import { AuthorityResponseDto } from "../../dtos/AuthorityResponseDto";
import { VueEditor } from "vue2-editor";
import toolbar from "@/config/editorToolbar";
import FeedbackComponent from "@/components/feedback/Feedback.vue";
import Summernote from "@/plugins/Summernote.vue";
import SimpleFirmaDto from "@/dtos/SimpleFirmaDto";
interface Data {
  titel: string;
  bild: string | undefined;
  inhalt: string;
  statischeUrl: boolean;
  authorities: AuthorityResponseDto | undefined;
  editMode: boolean;
  alleRechte: string[];
  alleRollen: string[];
  requestUrl: string;
  url: string;
  berechtigte: string[];
  disableBerechtigte: boolean;
  aktiv: boolean;
  oeffentlich: boolean;
  berechtigteRollen: string[];
  disableBerechtigteRollen: boolean;
  customToolbar: any;
  laden: boolean;
  firmen: SimpleFirmaDto[];
  firma: number | null;
}
export default Vue.extend({
  components: {
    VueEditor,
    FeedbackComponent,
    Summernote,
  },
  data(): Data {
    return {
      titel: "" /* Der Seitentitel */,
      bild: undefined /* Das Bild */,
      inhalt: "" /* Der Textinhalt der SeiteDto */,
      statischeUrl: false,
      authorities: undefined,
      editMode: false /* Wird die SeiteDto gerade editiert? */,
      alleRechte: privilege.allValues(),
      alleRollen: role.allValues(),
      requestUrl: "" /* Die Url an die ein Request gehen muss */,
      url: "" /* Die Url, die vom Nutzer eingestellt ist */,
      berechtigte: [] /* Liste der berechtigten Nutzer */,
      disableBerechtigte: false,
      aktiv: false /* Ist diese SeiteDto sichtbar */,
      oeffentlich: false,
      berechtigteRollen: [],
      disableBerechtigteRollen: false,
      customToolbar: toolbar,
      laden: true,
      firmen: [],
      firma: null,
    };
  },
  props: {
    seite: {
      type: String,
      default: null,
    },
    editable: {
      type: Boolean,
      default: true,
    },
  },
  head: {
    title: function () {
      return {
        inner: this.titel,
      };
    },
  },
  computed: {
    editNavigation() {
      return this.$store.getters.hasPrivilege(Privilege.NAVIGATION_EDITIEREN);
    },
  },
  watch: {
    seite() {
      this.getSeiteninhalt();
      this.editMode = false;
    },
    $route() {
      this.getSeiteninhalt();
      this.editMode = false;
    },
  },
  created() {
    this.getSeiteninhalt();
    this.getFirmen();
  },
  methods: {
    getSeiteninhalt() {
      this.laden = true;
      if (this.seite != null) {
        this.url = this.seite;
      } else {
        this.url = this.$route.path.replace("/inhalt", "").substr(1);
      }
      this.requestUrl = this.url;
      axios
        .get<SeiteDto>(this.getRequestUrl(), Api.getRequestConfig())
        .then((response) => {
          this.laden = false;
          this.titel = response.data.titel;
          this.bild = response.data.bild;
          this.inhalt = response.data.inhalt;
          this.statischeUrl = response.data.statischeUrl;
          this.$emit("updateHead");
        })
        .catch((e) => {
          window.alert("Verbindungsfehler!")
          console.log(e);
        });
    },
    getMetainformationen() {
      if (!this.statischeUrl) {
        axios
          .get<SeiteMetaDto>(this.getRequestUrl("edit"), Api.getRequestConfig())
          .then((response) => {
            this.berechtigte = privilege.numbersToStrings(
              response.data.berechtigte
            );
            this.aktiv = response.data.aktiv;
            this.oeffentlich = response.data.oeffentlich;
            this.firma = response.data.firmaId;
            this.berechtigteRollen = role.numbersToStrings(
              response.data.berechtigteRollen
            );
            this.setPrivileges();
          })
          .catch(() => {
            window.alert("Verbindungsfehler!")
          });
      }
      if (this.authorities === undefined) {
        axios
          .get<AuthorityResponseDto>("/authority", Api.getRequestConfig())
          .then((response) => {
            this.authorities = response.data;
            this.setPrivileges();
          })
          .catch(() => {
            window.alert("Verbindungsfehler!")
          });
      }
    },
    saveSeite() {
      const data: SeiteResponseDto = {
        titel: this.titel,
        bild: this.bild,
        inhalt: this.inhalt,
        url: this.url,
        aktiv: this.aktiv,
        berechtigte: privilege.stringsToNumbers(this.berechtigte),
        oeffentlich: this.oeffentlich,
        berechtigteRollen: role.stringsToNumbers(this.berechtigteRollen),
        firmaId: this.firma,
      };
      axios
        .post(this.getRequestUrl("edit"), data, Api.getRequestConfig())
        .then(() => {
          this.$store.state.menueChanged = true;
          this.requestUrl = this.url;
        })
        .catch(() => {
          window.alert("Verbindungsfehler!")
        });        
    },
    getRequestUrl(suffix?: string | undefined) {
      if (suffix !== undefined) {
        return "/inhalt/" + this.requestUrl + "/" + suffix;
      } else {
        return "/inhalt/" + this.requestUrl;
      }
    },
    edit() {
      this.editMode = true;
      this.getMetainformationen();
    },
    save() {
      this.editMode = false;
      this.saveSeite();
    },
    abort() {
      this.editMode = false;
      this.getSeiteninhalt();
    },
    rolesChanged() {
      this.setPrivileges();
      this.berechtigte = [];
    },
    setPrivileges() {
      let availablePrivileges: Privilege[] = [];
      for (const privilegeFor in this.authorities) {
        let notUsedRole = true;
        for (const role of this.berechtigteRollen) {
          if (
            // ein bisschen hacky ...
            privilegeFor.toLowerCase() ===
            role.toLowerCase() + "privileges"
          ) {
            notUsedRole = false;
          }
        }
        if (notUsedRole) {
          availablePrivileges = availablePrivileges.concat(
            this.authorities[privilegeFor]
          );
        }
      }
      this.alleRechte = privilege.numbersToStrings(
        // entferne doppelte einträge (kann passieren, falls ein Recht zu mehreren Rollen gehören kann)
        availablePrivileges.filter(function (item, pos) {
          return availablePrivileges.indexOf(item) == pos;
        })
      );
    },
    getFirmen() {
      const url = "/firma/alle/terminEditieren";
      return axios
        .get(url, Api.getRequestConfig())
        .then((response) => {
          this.firmen = response.data;
          this.firmen.unshift({ id: null, bezeichner: "Keine Firma" });
        })
        .catch((error) => {
          window.alert("Verbindungsfehler!")
          console.log(error);
        });
    },
    firmenName: (item: SimpleFirmaDto) => item.bezeichner,
  },
});
