



























































































































































































































































































































































import PatientTerminGuthabenBalken from "./PatientTerminGuthabenBalken.vue";
import PatientTerminAboBalken from "./PatientTerminAboBalken.vue";
import PatientTerminRezeptBalken from "./PatientTerminRezeptBalken.vue";
import PatientTerminExpansionPanels from "./PatientTerminExpansionPanels.vue";
import TerminApi from "@/services/api/Termin";
import { mapMutations } from "vuex";
import DokumentApi from "@/services/api/Dokument";
import PatientApi from "@/services/api/Patient";
import { addYears, formatISO } from "date-fns";
import Therapeut from "@/data/Therapeut";
import Api from "@/config/api";
import axios from "axios";
import GuthabenDto from "@/dtos/GuthabenDto";
import { Termintyp } from "@/services/api/Termintyp";

export default {
  components: {
    PatientTerminExpansionPanels,
    PatientTerminGuthabenBalken,
    PatientTerminRezeptBalken,
    PatientTerminAboBalken,
  },
  data: () => ({
    terminTypEinzel: Termintyp[Termintyp.EINZEL],
    terminTypGruppe: Termintyp[Termintyp.GRUPPE],
    terminTypTelefon: Termintyp[Termintyp.TELEFON],
    terminTypAnamnese: Termintyp[Termintyp.ANAMNESE],
    terminTypBefund: Termintyp[Termintyp.BEFUND],
    termine: [],
    user: { vorname: "", nachname: "" },
    gefilterteTermine: [],
    selectedTermin: null,
    filterTerminart: "frei",
    firmentermine: false,
    einzeltermine: true,
    gruppentermine: true,
    aboEinzelTermin: 0,
    aboGruppenTermin: 0,
    aboBefundTermin: 0,
    aboTrainingsplan: 0,
    bezahltEinzelTermin: 0,
    bezahltGruppenTermin: 0,
    bezahltBefundTermin: 0,
    bezahltTelefonTermin: 0,
    bezahltAnamneseTermin: 0,
    rezeptEinzelTermin: 0,
    rezeptGruppenTermin: 0,
    rezeptBefundTermin: 0,
    rezeptGuthabenVorhanden: false,
    bezahltGuthabenVorhanden: false,
    hatAbo: false,
    telefontermine: true,
    befundtermine: true,
    anamnesetermine: true,
    filterBefund: "",
    filterTherapeut: "",
    zeitraumMenu: false,
    zeitraum: [],
    tempZeitraum: [],
    urlId: undefined,
    today: new Date(Date.now()),
    panels: [],
    cart: [],
    checkbox: false,
    todayString: "",
    todayInOneYearString: "",
    terminBearbeiten: false,
    payConfirmation: false,
    cartId: undefined,
    redirectUrl: undefined,
    finalCart: { items: [] },
  }),
  created() {
    this.handleUrlParams();
    this.getData();
    this.getGuthaben();
    this.getPatientAboStatus();
  },
  watch: {
    termine() {
      this.updateFilter();
    },
    filterTerminart() {
      this.updateFilter();
    },
    firmentermine() {
      this.updateFilter();
    },
    telefontermine() {
      this.updateFilter();
    },
    einzeltermine() {
      this.updateFilter();
    },
    gruppentermine() {
      this.updateFilter();
    },
    anamnesetermine() {
      this.updateFilter();
    },
    befundtermine() {
      this.updateFilter();
    },
    zeitraum() {
      this.updateFilter();
    },
    filterBefund() {
      this.updateFilter();
    },
    filterTherapeut() {
      this.updateFilter();
    },
  },
  computed: {
    displayedZeitraum: {
      get() {
        if (!this.zeitraum) {
          return "";
        } else {
          const dateArray: string[] = [];
          for (const date of this.zeitraum) {
            dateArray.push(this.formatDate(date));
          }
          if (dateArray.length === 1) return dateArray[0];
          else return dateArray[0] + " - " + dateArray[1];
        }
      },
      set(newZeitraum: any) {
        this.zeitraum = newZeitraum;
      },
    },
    ...mapMutations(["filterSelectedTermine"]),
    ...mapMutations(["urlSelectedTermine"]),
  },
  methods: {
    getGuthaben: function () {
      const url = "/guthaben/patient/get";
      return axios
        .get<GuthabenDto>(url, Api.getRequestConfig())
        .then((response) => {
          //abo
          this.aboEinzelTermin = response.data.aboEinzelTermin;
          this.aboGruppenTermin = response.data.aboGruppenTermin;
          this.aboBefundTermin = response.data.aboBefundTermin;
          this.aboTrainingsplan = response.data.aboTrainingsplan;
          //bezahlt
          this.bezahltAnamneseTermin = response.data.bezahltAnamneseTermin;
          this.bezahltBefundTermin = response.data.bezahltBefundTermin;
          this.bezahltEinzelTermin = response.data.bezahltEinzelTermin;
          this.bezahltGruppenTermin = response.data.bezahltGruppenTermin;
          this.bezahltTelefonTermin = response.data.bezahltTelefonTermin;
          if (
            this.bezahltAnamneseTermin > 0 ||
            this.bezahltBefundTermin > 0 ||
            this.bezahltEinzelTermin > 0 ||
            this.bezahltGruppenTermin > 0 ||
            this.bezahltTelefonTermin > 0
          ) {
            this.bezahltGuthabenVorhanden = true;
          }
          //rezept
          this.rezeptBefundTermin = response.data.rezeptBefundTermin;
          this.rezeptEinzelTermin = response.data.rezeptEinzelTermin;
          this.rezeptGruppenTermin = response.data.rezeptGruppenTermin;
          if (
            this.rezeptBefundTermin > 0 ||
            this.rezeptEinzelTermin > 0 ||
            this.rezeptGruppenTermin > 0
          ) {
            this.rezeptGuthabenVorhanden = true;
          }
        })
        .catch((error) => {
          window.alert("Verbindungsfehler!")
          console.log(error);
        });
    },
    buchungsDatum(date: string) {
      if (date == "") return "";
      return new Date(date).toLocaleDateString();
    },
    getData() {
      if (this.zeitraum == null || this.zeitraum.length === 0) {
        this.todayString = formatISO(this.today, { representation: "date" });
        this.todayInOneYearString = formatISO(addYears(this.today, 1), {
          representation: "date",
        });
        this.displayedZeitraum = [this.todayString, this.todayInOneYearString];
      }
      if (this.$route.params.therapeut) {
        this.filterTherapeut = this.$route.params.therapeut;
      }
      TerminApi.getPatientenTerminList().then((response) => {
        this.termine = response?.data;
        PatientApi.getPatientByUserId(
          this.$store.state.authentication.userId
        ).then((response) => {
          this.user = response?.data;
          this.gefilterteTermine = this.termine;
          this.updateFilter();
        });
      });
    },
    getPatientAboStatus() {
      axios
        .get(
          Api.getRequestConfig().baseURL +
            "/user/abo/" +
            this.$store.state.authentication.userId +
            "/privileges",
          {
            headers: Api.getRequestConfig().headers,
          }
        )
        .then((response) => {
          this.hatAbo = response.data;
        })
        .catch(() => {
          window.alert("Verbindungsfehler!")
        });
    },
    cancelPayment(cartId: number) {
      const url = "/cart/patient/cancelPayment/" + cartId;
      axios.get(url, Api.getRequestConfig()).then(() => {
        this.getData();
        this.getGuthaben();
      })
      .catch(() => {
        window.alert("Verbindungsfehler!")
      });
    },
    addToCart(termin: any) {
      if (!this.cart.includes(termin)) {
        this.cart.push(termin);
      }
      this.checkbox = true;
      for (const termin of this.gefilterteTermine) {
        if (!termin.angemeldet && !this.cart.includes(termin)) {
          this.checkbox = false;
        }
      }
    },
    addAllToCart() {
      for (const termin of this.gefilterteTermine) {
        if (!this.cart.includes(termin) && !termin.angemeldet) {
          this.cart.push(termin);
        }
      }
    },
    removeFromCart(termin: any) {
      if (this.cart.includes(termin)) {
        this.cart.pop(termin);
      }
      const tempAll = [];
      for (const termin of this.gefilterteTermine) {
        if (!termin.angemeldet) {
          tempAll.push(termin);
        }
      }
      if (this.cart.length < tempAll.length) {
        this.checkbox = false;
      }
    },
    removeAllFromCart() {
      this.cart = [];
    },
    toggleBearbeiten() {
      this.terminBearbeiten = false;
    },
    updateFilter() {
      this.cart = [];
      this.gefilterteTermine = this.termine;
      if (this.filterTerminart === "url") {
        this.gefilterteTermine = this.gefilterteTermine.filter(
          (termin: any) => {
            if (termin.id === this.urlId) {
              this.$store.commit("urlSelectedTermine", termin);
            }
            return termin.id === this.urlId;
          }
        );
      } else {
        if (this.filterTerminart === "frei") {
          this.gefilterteTermine = this.gefilterteTermine.filter(
            (termin: any) => {
              const terminDate = new Date(termin.datum);
              const now = new Date();
              return (
                termin.maxTeilnehmer > termin.anzahlTeilnehmer &&
                termin.angemeldet === false &&
                terminDate.getTime() > now.getTime()
              );
            }
          );
        } else if (this.filterTerminart === "angemeldet") {
          this.gefilterteTermine = this.gefilterteTermine.filter(
            (termin: any) => {
              return termin.angemeldet === true && termin.bezahlt === true;
            }
          );
        } else {
          //ausgewählt: "Alle Termine"
          this.gefilterteTermine = this.gefilterteTermine.filter(
            (termin: any) => {
              const terminDate = new Date(termin.datum);
              const now = new Date();
              return (
                (termin.maxTeilnehmer > termin.anzahlTeilnehmer &&
                  termin.angemeldet === false &&
                  terminDate.getTime() > now.getTime()) ||
                (termin.angemeldet === true && termin.bezahlt === true)
              );
            }
          );
        }
        if (this.firmentermine) {
          this.gefilterteTermine = this.gefilterteTermine.filter(
            (termin: any) => {
              return termin.istFirmentermin;
            }
          );
        }
        if (!this.einzeltermine) {
          this.gefilterteTermine = this.gefilterteTermine.filter(
            (termin: any) => {
              return (
                termin.termintyp === this.terminTypGruppe ||
                termin.termintyp === this.terminTypTelefon ||
                termin.termintyp === this.terminTypAnamnese ||
                termin.termintyp === this.terminTypBefund
              );
            }
          );
        }
        if (!this.gruppentermine) {
          this.gefilterteTermine = this.gefilterteTermine.filter(
            (termin: any) => {
              return (
                termin.termintyp === this.terminTypEinzel ||
                termin.termintyp === this.terminTypTelefon ||
                termin.termintyp === this.terminTypAnamnese ||
                termin.termintyp === this.terminTypBefund
              );
            }
          );
        }
        if (!this.telefontermine) {
          this.gefilterteTermine = this.gefilterteTermine.filter(
            (termin: any) => {
              return (
                termin.termintyp === this.terminTypEinzel ||
                termin.termintyp === this.terminTypGruppe ||
                termin.termintyp === this.terminTypAnamnese ||
                termin.termintyp === this.terminTypBefund
              );
            }
          );
        }
        if (!this.befundtermine) {
          this.gefilterteTermine = this.gefilterteTermine.filter(
            (termin: any) => {
              return (
                termin.termintyp === this.terminTypEinzel ||
                termin.termintyp === this.terminTypGruppe ||
                termin.termintyp === this.terminTypAnamnese ||
                termin.termintyp === this.terminTypTelefon
              );
            }
          );
        }
        if (!this.anamnesetermine) {
          this.gefilterteTermine = this.gefilterteTermine.filter(
            (termin: any) => {
              return (
                termin.termintyp === this.terminTypEinzel ||
                termin.termintyp === this.terminTypGruppe ||
                termin.termintyp === this.terminTypTelefon ||
                termin.termintyp === this.terminTypBefund
              );
            }
          );
        }
        if (this.zeitraum) {
          this.gefilterteTermine = this.gefilterteTermine.filter(
            (termin: any) => {
              const terminDateString = termin.datum.substring(0, 10);
              if (this.zeitraum.length === 1) {
                return terminDateString === this.zeitraum[0];
              } else {
                return (
                  new Date(terminDateString) >= new Date(this.zeitraum[0]) &&
                  new Date(terminDateString) <= new Date(this.zeitraum[1])
                );
              }
            }
          );
        }
        if (this.filterBefund) {
          this.gefilterteTermine = this.gefilterteTermine.filter(
            (termin: any) => {
              return termin.befund === this.filterBefund;
            }
          );
        }
        if (this.filterTherapeut) {
          this.gefilterteTermine = this.gefilterteTermine.filter(
            (termin: any) => {
              return (
                termin.therapeut.vorname + " " + termin.therapeut.nachname ===
                this.filterTherapeut
              );
            }
          );
        }
      }
      this.checkbox = false;
      this.$store.commit("filterSelectedTermine", this.gefilterteTermine);
      this.panels = this.getPanelIndizes();
    },
    selectionChanged(selectedItemIndex: number) {
      if (this.termine.length > selectedItemIndex && selectedItemIndex >= 0) {
        this.selectedTermin = this.termine[selectedItemIndex];
      }
    },
    getAlleBefunde() {
      const befunde: string[] = [];
      for (const termin of this.termine) {
        if (!befunde.some((b) => b === termin.befund)) {
          befunde.push(termin.befund);
        }
      }
      return befunde;
    },
    getAlleTherapeuten() {
      const therapeuten: Therapeut[] = [];
      const therapeutenNamen: string[] = [];
      for (const termin of this.termine) {
        if (!therapeuten.some((t) => t.id === termin.therapeut.id)) {
          therapeuten.push(termin.therapeut);
          therapeutenNamen.push(
            termin.therapeut.vorname + " " + termin.therapeut.nachname
          );
        }
      }
      if (
        this.$route.params.therapeut &&
        !(therapeutenNamen.indexOf(this.$route.params.therapeut) > -1)
      ) {
        therapeutenNamen.push(this.$route.params.therapeut);
      }
      return therapeutenNamen;
    },
    checkboxClickAction() {
      this.checkbox = !this.checkbox;
      if (this.checkbox) {
        this.addAllToCart();
      } else {
        this.removeAllFromCart();
      }
    },
    clearFilter() {
      this.displayedZeitraum = [this.todayString, this.todayInOneYearString];
      this.gruppentermine = true;
      this.firmentermine = false;
      this.einzeltermine = true;
      this.telefontermine = true;
      this.anamnesetermine = true;
      this.befundtermine = true;
      this.filterBefund = "";
      this.filterTherapeut = "";
      this.filterTerminart = "frei";
    },
    setZeitraum() {
      this.tempZeitraum.sort((a: string, b: string) => {
        return new Date(a) > new Date(b);
      });
      this.zeitraum = this.tempZeitraum;
      this.zeitraumMenu = false;
    },
    zeitraumAbbrechen() {
      this.zeitraumMenu = false;
      this.tempZeitraum = this.zeitraum;
    },
    formatDate(date: string) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return `${day}.${month}.${year}`;
    },
    getPanelIndizes() {
      const indexArray: any[] = [];
      if (this.filterTerminart === "url") {
        indexArray.push(1);
      } else {
        for (const termin of this.gefilterteTermine) {
          if (this.$store.state.selectedTermine.indexOf(termin) > -1) {
            indexArray.push(this.gefilterteTermine.indexOf(termin) + 1);
          }
        }
      }
      return indexArray;
    },
    getStatus(termin: any) {
      if (termin.teilgenommen === false) {
        if (termin.angemeldet === true) {
          if (new Date(termin.datum) > new Date()) {
            return "gebucht";
          } else {
            return "nicht teilgenommen";
          }
        } else {
          if (new Date(termin.datum) > new Date()) {
            return "nicht gebucht";
          } else {
            return "nicht teilgenommen";
          }
        }
      } else {
        return "teilgenommen";
      }
    },
    document() {
      for (const termin of this.gefilterteTermine) {
        termin.status = this.getStatus(termin);
      }
      DokumentApi.getTerminliste(this.gefilterteTermine);
    },
    terminBuchen(termin: any) {
      this.removeAllFromCart();
      this.addToCart(termin);
      this.makeCart();
    },
    handleUrlParams() {
      /* Parameter:
       * id: die ID eins Termins, der angezeigt werden soll
       * ta: Terminart Filter; mögliche Werte: frei, angemeldet, alle
       * b: Befund
       * t: Therapeut (Vorname%20Nachname)
       * zaj, zam, zat: Zeitraum Anfang; Jahr, Monat, Tag
       * zej, zem, zet: Zeitraum Ende; Jahr, Monat, Tag
       * ket: Einzeltermine nicht anzeigen
       * kgt: Gruppentermine nicht anzeigen
       */
      if (this.$route.query.id) {
        this.urlId = parseInt(this.$route.query.id);
        this.filterTerminart = "url";
      }
      if (this.$route.query.ta) {
        this.filterTerminart = this.$route.query.ta;
      }
      if (this.$route.query.b) {
        this.filterBefund = this.$route.query.b;
      }
      if (this.$route.query.t) {
        this.filterTherapeut = this.$route.query.t;
      }
      if (
        this.$route.query.zaj &&
        this.$route.query.zam &&
        this.$route.query.zat &&
        this.$route.query.zej &&
        this.$route.query.zem &&
        this.$route.query.zet
      ) {
        try {
          this.displayedZeitraum = [
            new Date(
              parseInt(this.$route.query.zaj),
              parseInt(this.$route.query.zam) - 1,
              parseInt(this.$route.query.zat) + 1
            )
              .toISOString()
              .substring(0, 10),
            new Date(
              parseInt(this.$route.query.zej),
              parseInt(this.$route.query.zem) - 1,
              parseInt(this.$route.query.zet) + 1
            )
              .toISOString()
              .substring(0, 10),
          ];
        } catch (e) {
          this.displayedZeitraum = [];
        }
      } else if (
        this.$route.query.zaj &&
        this.$route.query.zam &&
        this.$route.query.zat
      ) {
        try {
          this.displayedZeitraum = [
            new Date(
              parseInt(this.$route.query.zaj),
              parseInt(this.$route.query.zam) - 1,
              parseInt(this.$route.query.zat) + 1
            )
              .toISOString()
              .substring(0, 10),
          ];
        } catch (e) {
          this.displayedZeitraum = [];
        }
      }
      this.gruppentermine = this.$route.query.kgt === undefined;
      this.einzeltermine =
        this.$route.query.ket === undefined || !this.gruppentermine;
    },
    // Von Terminbuchung kopiert
    makeCart: function () {
      const url = "/cart/create";
      axios.post(url, this.cart, Api.getRequestConfig()).then((response) => {
        if (Object.prototype.hasOwnProperty.call(response.data, "cartId")) {
          this.cartId = response.data.cartId;
        }
        if (
          Object.prototype.hasOwnProperty.call(response.data, "redirectUrl")
        ) {
          this.redirectUrl = response.data.redirectUrl;
        }
        this.getCart(this.cartId);
      })
      .catch(() => {
        window.alert("Verbindungsfehler!")
      });
    },
    getCart(cartId: number) {
      const url = "/cart/get/" + cartId;
      axios.get(url, Api.getRequestConfig()).then((response) => {
        this.finalCart = response.data;
        this.payConfirmation = true;
      })
      .catch(() => {
        window.alert("Verbindungsfehler!")
      });
    },
    pay: function () {
      window.open(this.redirectUrl);
    },
    close: function () {
      this.getData();
      this.getGuthaben();
      this.payConfirmation = false;
    },
    terminDataChanged() {
      this.getData();
      this.getGuthaben();
    },
    closeAndAbord: function () {
      this.payConfirmation = false;
      this.cancelPayment(this.finalCart.cartId);
    },
    showFirmaFilter() {
      return this.user.firmaId != null
    },
  },
};
