
































































































































































































import { add } from "date-fns";
import TerminApi from "@/services/api/Termin";
import DokumentApi from "@/services/api/Dokument";
import { mapMutations } from "vuex";
import format from "date-fns/format";
import deLocale from "date-fns/locale/de";
import axios from "axios";
import Api from "@/config/api";
import { Termintyp } from "@/services/api/Termintyp";
import PatientStornoConfirm from "./PatientStornoConfirm.vue";
import NutzerProfil from "@/components/profil/NutzerProfil.vue";
export default {
  components: {
    PatientStornoConfirm,
    NutzerProfil,
  },
  props: {
    termin: Object,
    cart: Array,
  },
  data: function () {
    return {
      terminTypEinzel: Termintyp[Termintyp.EINZEL],
      terminTypGruppe: Termintyp[Termintyp.GRUPPE],
      terminTypTelefon: Termintyp[Termintyp.TELEFON],
      terminTypAnamnese: Termintyp[Termintyp.ANAMNESE],
      terminTypBefund: Termintyp[Termintyp.BEFUND],

      checkbox: false,
      teilnahmebestaetigungError: "",
      cancelDialog: false,
      stornoPaket: null,
      dialogTherapeut: false,
      selectedTherapeutId: null,
    };
  },
  computed: {
    showStartButton() {
      return (
        this.isFullyAngemeldet() &&
        !this.termin.beendet &&
        !this.termin.herausgeworfen &&
        (!this.termin.zugeschlossen || this.termin.beigetreten) &&
        this.isDue &&
        this.termin.termintyp != this.terminTypTelefon
      );
    },
    isDue() {
      const sichtbarAb = new Date(this.termin.datum).getTime() - 1000 * 60 * 10;
      const sichtbarBis =
        new Date(this.termin.datum).getTime() + this.termin.dauer * 1000 * 60;
      return Date.now() > sichtbarAb && Date.now() < sichtbarBis;
    },
    istNachTermin() {
      const terminEnde =
        new Date(this.termin.datum).getTime() + this.termin.dauer * 1000 * 60;
      return Date.now() > terminEnde;
    },
    ...mapMutations(["selectedTermine"]),
  },
  watch: {
    cart: function (newVal: any) {
      if (newVal.includes(this.termin)) {
        this.checkbox = true;
      } else {
        this.checkbox = false;
      }
    },
  },
  methods: {
    removeFromCart() {
      this.$emit("update:removeFromCart", this.termin);
      this.checkbox = false;
    },
    addToCart() {
      this.$emit("update:addToCart", this.termin);
      this.checkbox = true;
    },
    checkboxClickAction() {
      if (this.checkbox) {
        this.$emit("update:addToCart", this.termin);
      } else {
        this.$emit("update:removeFromCart", this.termin);
      }
    },
    stopPropagation: function (event: any) {
      //verhindert, dass das Panel ein- und ausklappt.
      event.stopPropagation();
    },
    startSession() {
      this.$router
        .push({
          name: "videosessionPatient",
          params: { conferenceId: this.termin.id },
        })
        .catch(() => {});
    },
    formateDate(date: string) {
      if (date == "") return "";
      return new Date(date).toLocaleDateString();
    },
    formateTime(date: string) {
      if (date == "") return "";
      return format(new Date(date), "p", { locale: deLocale });
    },
    getEndTime(termin: any) {
      return add(new Date(termin.datum), { minutes: termin.dauer });
    },
    istHeute(date: any) {
      const today = new Date().toLocaleDateString();
      const givenDate = new Date(date).toLocaleDateString();
      if (today === givenDate) {
        return true;
      } else {
        return false;
      }
    },
    getStatus() {
      if (this.termin.teilgenommen === false) {
        if (this.isFullyAngemeldet() === true) {
          if (new Date(this.termin.datum) > new Date()) {
            return "gebucht";
          } else {
            return "nicht teilgenommen";
          }
        } else {
          if (new Date(this.termin.datum) > new Date()) {
            return "nicht gebucht";
          } else {
            return "nicht teilgenommen";
          }
        }
      } else {
        return "teilgenommen";
      }
    },
    addSelectedTermine() {
      this.$store.commit("selectedTermine", this.termin);
    },
    isSelected() {
      if (this.$store.state.selectedTermine.indexOf(this.termin) > -1) {
        return true;
      } else {
        return false;
      }
    },
    getColor() {
      if (this.$store.state.selectedTermine.indexOf(this.termin) > -1) {
        return "#f5f6f7";
      } else {
        return "white";
      }
    },
    getHeaderBorder() {
      if (this.$store.state.selectedTermine.indexOf(this.termin) > -1) {
        return "2px solid rgb(0,73,100)";
      } else {
        return "";
      }
    },
    showProfile(userId: number) {
      this.selectedTherapeutId = userId;
      this.dialogTherapeut = true;
      /*
      this.$router
        .push({
          name: "NutzerProfil",
          params: { userId: userId },
        })
        .catch(() => {});
        */
    },
    anmelden() {
      TerminApi.anmelden(this.termin.id)
        .then((response) => {
          this.termin = response?.data;
          this.$emit("dataChanged");
        })
        .catch((error: Error) => {
          console.log(error);
        });
    },
    terminBuchen() {
      this.$emit("terminBuchen", this.termin);
    },
    dokument() {
      if (new Date(this.termin.datum) > new Date()) {
        this.teilnahmebestaetigungError =
          "Der Termin hat noch nicht stattgefunden";
      } else if (!this.termin.teilgenommen) {
        this.teilnahmebestaetigungError =
          "Ihre Teilnahme am Termin wurde noch nicht bestätigt";
      } else {
        DokumentApi.getTeilnahmebestaetigung(this.termin.id);
      }
    },
    abmelden() {
      if (this.isFullyAngemeldet()) {
        if (this.isCancelable()) {
          this.stornoPaket = this.termin;
        } else {
          this.cancelDialog = true;
        }
      }
    },
    abmeldenBestaetigt() {
      if (this.isFullyAngemeldet()) {
        if (this.isCancelable()) {
          TerminApi.abmelden(this.termin.id)
            .then(() => {
              this.$emit("dataChanged");

              let alertText =
                "Der Termin " +
                this.termin.befund +
                " wurde erfolgreich storniert.";
              if (!this.termin.istFirmentermin) {
                alertText =
                  alertText +
                  " Das entsprechende Guthaben wurde Ihnen zugeschrieben.";
              }

              this.$store.state.alert = {
                isVisible: true,
                headline: "Termin storniert",
                text: alertText,
                okAction: null,
              };
            })
            .catch((error: Error) => {
              console.log(error);
              if (error.message.toString().indexOf("403") > -1) {
                this.$store.state.error = {
                  isVisible: true,
                  headline: "Verboten",
                  text: "Sie sind nicht zu dieser Aktion berechtigt.",
                  okAction: function () {},
                };
              }
            });
        }
      }
    },
    abmeldenAbgebrochen() {
      this.stornoPaket = null;
    },
    isCancelable() {
      return (
        new Date(this.termin.datum).getTime() - Date.now() > 24 * 3600 * 1000
      );
    },
    isFullyAngemeldet() {
      return this.termin.angemeldet === true && this.termin.bezahlt;
    },
  },
};
