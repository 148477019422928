export enum UrlType {
	// WICHTIG: Dieses Enum muss mit dem entsprechenden Enum im Backend übereinstimmen!
	SEITE,
	PROFIL,
	TERMIN_THERAPEUT,
	NAVIGATION_EDITIEREN,
	FRAGEBOGEN_EDITIEREN,
	DATEIEN_HOCHLADEN,
	TERMIN_BETREIBER,
	BENUTZER_VERWALTEN,
	TERMIN_PATIENT,
	TERMINLISTE_PATIENT,
	DOKUMENTE_PATIENT,
	FRAGEBOGEN_PATIENT,
	UEBUNGSPLAN_PATIENT,
	EINSTELLUNGEN_PATIENT,
	VERBINDUNGSTEST_PATIENT,
	UEBUNGEN_VERWALTEN,
	ANWENDUNG_VERWALTEN,
	ZAHLUNGEN_VERWALTEN,
	ABOPAKETE_VERWALTEN,
	ABO_BUCHEN,
	WARTERAUM_PATIENT,
	GUTHABEN_BUCHEN,
	FIRMEN_VERWALTEN,
	EXTERN,
	REZEPTE_PATIENT,
	REZEPTE_VERWALTEN,
	TRAININGSPLAN_VORLAGEN_VERWALTEN,
	VIDEOS,
}
