import WebsocketMessageOutgoing from "./WebsocketMessageOutgoing";

export default class JoinConferenceMessage implements WebsocketMessageOutgoing {
  command: string;
  bearer: string = "";
  conferenceId: string;

  constructor(bearer: string, conferenceId: string) {
    this.command = "joinConference";
    this.conferenceId = conferenceId;
    this.bearer = bearer;
  }
}
