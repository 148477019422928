
























































import rules from "@/config/rules";
import Axios from "axios";
import api from "@/config/api";
export default {
  data: () => ({
    send: false,
    rules: rules,
    email: "",
    feedback: "",
    valide: false,
    successful: undefined,
  }),
  methods: {
    submit() {
      this.send = true;
      this.successful = undefined;
      Axios.post(
        "/feedback",
        { email: this.email, feedback: this.feedback },
        api.getRequestConfig()
      )
        .then(() => {
          this.successful = true;
        })
        .catch(() => {
          window.alert("Verbindungsfehler!")
          this.successful = false;
        });
    },
  },
  created() {
    const email = this.$store.state.authentication.email;
    if (email) {
      this.email = email;
    }
  },
};
