
























import FragebogenApi from "@/services/api/Fragebogen";
import format from "date-fns/format";
import deLocale from "date-fns/locale/de";
export default {
  props: {
    fragebogenList:Array,
  },
  data: function() {
    return {
      items: [],
      hasDraftItem: false,
    };
  },
  methods: {
    changeSelection: function(item: any) {
      this.$emit("update:selection", item);
    },
    getDraftStatus: function(item: any) {
      if (item.veroeffentlicht === null) {
        this.hasDraftItem = true;
        return "(Entwurf)";
      }
      return "";
    },
    getGermanDate: function(item: any) {
      if (item.veroeffentlicht === null) {
        return "";
      }
      const date = new Date(item.veroeffentlicht);
      return format(date, "P", { locale: deLocale });
    },
    newFragebogen: function() {
      FragebogenApi.createNewFragebogen().then(response => {
        if (response.fragen === null) {
          response.fragen = [];
        }

        this.fragebogenList.push(response);
        this.fragebogenList.sort(function(x: any, y: any) {
          if (x.veroeffentlicht === null) {
            return -1;
          }
          if (x.veroeffentlicht < y.veroeffentlicht) {
            return 1;
          }
          if (x.veroeffentlicht > y.veroeffentlicht) {
            return -1;
          }
          return 0;
        });
        this.changeSelection(response);
      });
    },
  },
};
