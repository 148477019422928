
















































































import Axios from "axios";
import Api from "@/config/api";
import VideoFullDto from "@/dtos/videos/VideoFullDto";

export default {
  props: {
    video: {
      type: Object,
    },
    canEdit: {
      type: Boolean,
      default: false,
    },
  },
  created() {
    this.dateString = this.formatDate(this.video.datum);
    this.timeString = this.formatTime(this.video.datum);
  },
  watch: {
    video: function () {
      if (this.video != null) {
        this.dateString = this.formatDate(this.video.datum);
        this.timeString = this.formatTime(this.video.datum);
      }
    },
  },
  data() {
    return {
      editing: false,
      dateString: "",
      timeString: "",
      allFirmen: [],
      gotFirmen: false,
      editFirmen: [],
      editTitel: "",
      editKurzbeschreibung: "",
      editVimeolink: "",
      editLangtext: "",
      sending: false,
      success: false,
      failure: false,
      loeschen: false,
    };
  },
  methods: {
    startEdit() {
      this.resetValues();
      this.success = false;
      this.sending = false;
      this.failure = false;
      if (!this.gotFirmen) {
        const url = "firma/allevideo";
        Axios.get(url, Api.getRequestConfig())
          .then((response) => {
            //console.log(response);
            this.allFirmen = response.data;
            this.gotFirmen = true;
            this.editing = true;
          })
          .catch(() => {
				    window.alert("Verbindungsfehler!")
            console.log("Failure on getting Firmen.");
            this.failure = true;
          });
      } else {
        this.editing = true;
      }
    },
    saveEdit() {
      if (this.canEdit) {
        this.video.firmen = this.editFirmen;
        this.video.titel = this.editTitel;
        this.video.kurzbeschreibung = this.editKurzbeschreibung;
        this.video.vimeolink = this.editVimeolink;
        this.video.vimeobild = this.getImageUrl(this.editVimeolink);
        this.video.langtext = this.editLangtext;
        const url = "/video/betreiber/save";
        this.sending = true;
        Axios.post<VideoFullDto>(url, this.video, Api.getRequestConfig())
          .then((response) => {
            this.emitChanges(response.data);
            this.sending = false;
            this.success = true;
            this.editing = false;
          })
          .catch(() => {
				    window.alert("Verbindungsfehler!")
            console.log("Änderungen nicht übernommen....");
            this.failure = true;
            this.sending = false;
          });
      }
    },
    videoLoeschen() {
      if (this.canEdit) {
        const url = "/video/betreiber/delete";
        Axios.post(url, this.video, Api.getRequestConfig())
          .then((response) => {
            this.$emit("deletedVideo");
            this.sending = false;
            this.success = true;
            this.editing = false;
            this.loeschen = false;
          })
          .catch(() => {
				    window.alert("Verbindungsfehler!")
            console.log("Änderungen nicht übernommen....");
            this.failure = true;
            this.sending = false;
            this.loeschen = false;
          });
      }
    },
    cancelEdit() {
      this.resetValues();
      this.loeschen = false;
      this.editing = false;
    },
    resetValues() {
      this.editFirmen = this.video.firmen;
      this.editTitel = this.video.titel;
      this.editKurzbeschreibung = this.video.kurzbeschreibung;
      this.editVimeolink = this.video.vimeolink;
      this.editLangtext = this.video.langtext;
    },
    formatDate(date: string) {
      if (date == "") return "";
      return new Date(date).toLocaleDateString();
    },
    formatTime(date: string) {
      if (date == "") return "";
      return new Date(date).toLocaleTimeString();
    },
    emitChanges(video: any) {
      this.$emit("changevideo", video);
    },
    getImageUrl(link: string) {
      if (link !== undefined) {
        return "https://player.vimeo.com/video/" + link.split("/")[3];
      } else {
        return "";
      }
    },
  },
  computed: {
    url() {
      if (this.video !== undefined) {
        return (
          "https://player.vimeo.com/video/" + this.video.vimeolink.split("/")[3]
        );
      } else {
        return null;
      }
    },
  },
};
