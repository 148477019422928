import WebsocketMessageOutgoing from "./WebsocketMessageOutgoing";

export default class UserMutedMessage implements WebsocketMessageOutgoing {
  command: string;
  bearer: string = "";
  mute: boolean;

  constructor(bearer: string, mute: boolean) {
    this.command = "userMuted";
    this.mute = mute;
    this.bearer = bearer;
  }
}
