













import Vue from 'vue'
import PaymentApi from "../../services/api/Payment"

export default{
    data() {
        return {
            mostRecent: null
        };
    },
    created() {
        PaymentApi.getMostRecent().then(response =>{
            this.mostRecent = response;
        })
    },
};
