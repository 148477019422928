





























import { mapMutations } from "vuex";
import api from "../../config/api";
import Axios from "axios";
import ProfilBenutzerDto from "@/data/ProfilBenutzerDto";
export default {
  props: {
    info: {
      type: Object,
      default: null,
    },
    user: {
      type: Object,
      default: null,
    },
  },
  data: () => ({
    bemerkungModel: "",
    dialogEditBemerkung: false,
  }),
  computed: {
    ...mapMutations(["setNetworkError"])
  },
  created() {
    this.bemerkungModel = this.info.beschreibung;
  },
  methods: {
    editBemerkungSave() {
      this.info.beschreibung = this.bemerkungModel;
      this.dialogEditBemerkung = false;

      const requestUrl = "/profil/other/" + this.info.patientId + "/bemerkung";
      Axios.post(requestUrl, this.info.beschreibung, api.getRequestConfig())
      .catch((error: Error) => {
        window.alert("Verbindungsfehler!")
        this.$store.commit("setNetworkError");
      })
    },
    editBemerkungDiscard() {
      this.bemerkungModel = this.info.beschreibung;
      this.dialogEditBemerkung = false;
      },
    editBemerkungBack() {
      this.dialogEditBemerkung = false;
    },
  }
}
