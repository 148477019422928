






















import FragebogenApi from "@/services/api/Fragebogen";
import BefundFragebogenListeComponent from "./BefundFragebogenListe.vue";
import BefundFragebogenAnzeigenComponent from "./BefundFragebogenAnzeigen.vue";

// Bugticket Redmine: Bug #4239
export default {
  components: {
    BefundFragebogenListeComponent,
    BefundFragebogenAnzeigenComponent,
  },
  data() {
    return {
      currentSelection: null,
      items: [],
      showList: true,
    };
  },
  created() {
    FragebogenApi.getFragebogenList().then((response) => {
      response.sort(function (x: any, y: any) {
        if (x.veroeffentlicht === null) {
          return -1;
        }
        if (x.veroeffentlicht < y.veroeffentlicht) {
          return 1;
        }
        if (x.veroeffentlicht > y.veroeffentlicht) {
          return -1;
        }
        return 0;
      });
      this.items = response;
    });
  },
  methods: {
    changeSelection: function (event: any) {
      this.currentSelection = event;
    },

    changeDraftStatus: function (event: any) {
      for (const item of this.items) {
        if (item.id == event.id) {
          item.veroeffentlicht = event.veroeffentlicht;
        }
        this.showList = false;
        setTimeout(() => {
          this.showList = true;
        }, 1);
      }
      for (let item of this.items) {
        if (item.id == event.id) {
          item = event;
        }
      }
    },
  },
};
