import StreamObject from "./StreamObject"

export default class Stream{
    public stream: StreamObject|undefined
    public name: string
    public video: MediaStream | undefined
    public muted: boolean
    public show:boolean|undefined

    constructor(stream: StreamObject|undefined, name:string, video:MediaStream| undefined, muted:boolean){
        this.stream=stream;
        this.name=name;
        this.video=video;
        this.muted=muted
    }
}