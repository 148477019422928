import WebsocketMessageOutgoing from "./WebsocketMessageOutgoing";

export default class UnlockConferenceMessage
  implements WebsocketMessageOutgoing {
  command: string = "";
  bearer: string = "";
  constructor(bearer: string) {
    this.command = "unlockConference";
    this.bearer = bearer;
  }
}
