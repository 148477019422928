








































































import ProfileBemerkungListItem from "./ProfileBemerkungListItem.vue";
import ProfileDokumentListItem from "./ProfileDokumentListItem.vue";
export default {
  components: {
    ProfileBemerkungListItem,
    ProfileDokumentListItem,
  },
  props: {
    info: {
      type: Object,
      default: null,
    },
    user: {
      type: Object,
      default: null,
    },
    dokumente: {
      type: Array,
      default: null,
    },
    profilViewType: {
      type: Number,
      default: null,
    },
    patientId: {
      type: Number,
      default: null,
    },
    userIdProp: {
      type: Number,
      default: null,
    },
  },
  methods: {
    formateDate(date: string) {
      if (date == "") return "";
      return new Date(date).toLocaleDateString();
    },
    formateTime(date: string) {
      if (date == "") return "";
      return new Date(date).toLocaleTimeString();
    },
    goToFragebogen() {
      this.$router
        .push({
          name: "anamneseFragebogenPatient",
          params: { patientId: this.info.patientId },
        })
        .catch(() => {});
    },
    goToBefundFragebogen() {
      this.$router
        .push({
          name: "frageboegen",
          params: { patientId: this.info.patientId },
        })
        .catch(() => {});
    },
    goToTrainingsplan() {
      this.$router
        .push({
          name: "Therapeut: Trainingsplan",
          params: { patient: this.info.patientId },
        })
        .catch(() => {});
    },
    goToRezepte() {
      this.$router
        .push("/betreiber/rezepte/" + this.info.patientId)
        .catch(() => {});
    },
    dataChanged() {
      this.$emit("dataChanged");
    },
  },
};
