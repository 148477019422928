










































































import ProfileMailListItem from "./ProfileMailListItem.vue";
import ProfilePasswordListItem from "./ProfilePasswordListItem.vue";
import ProfileAddressListItem from "./ProfileAddressListItem.vue";
import ProfileCityListItem from "./ProfileCityListItem.vue";
import ProfileCountryListItem from "./ProfileCountryListItem.vue";
import ProfileTherapeutBeschreibungItemList from "./ProfileTherapeutBeschreibungItemList.vue";
import ProfileImageItemList from "./ProfileImageItemList.vue";
import ProfileNameListItem from "./ProfileNameListItem.vue";
import UserPrivilegesListItem from "./UserPrivilegesListItem.vue";
import { ProfilViewType } from "../../data/ProfilViewType";
import AdminMailPasswordListItem from "./AdminMailPasswordListItem.vue";
import Files from "../../services/api/Files";
import FirmaDesTherapeutenItem from "./FirmaDesTherapeutenItem.vue";
import Axios from "axios";
import ProfilBenutzerDto from "@/data/ProfilBenutzerDto";
import api from "../../config/api";

export default {
  components: {
    ProfileMailListItem,
    ProfilePasswordListItem,
    ProfileAddressListItem,
    ProfileCityListItem,
    ProfileCountryListItem,
    ProfileTherapeutBeschreibungItemList,
    ProfileImageItemList,
    UserPrivilegesListItem,
    ProfileNameListItem,
    AdminMailPasswordListItem,
    FirmaDesTherapeutenItem,
  },
  props: {
    info: {
      type: Object,
      default: null,
    },
    user: {
      type: Object,
      default: null,
    },
    profilViewType: {
      type: Number,
      default: null,
    },
  },
  data: () => ({
    editImg: false,
    editListenanzeige: false,
    listenanzeige: {
      type: Boolean
    },
    dialogEditImg: false,
    profilePhoto: "",
  }),
  created() {
    const profileId: string = this.myProfil ? "mine" : this.info.profilbild;
    Files.getProfilePhoto(profileId).then((response) => {
      this.profilePhoto = response;
    });
    this.listenanzeige = this.info.listenanzeige;
  },
  computed: {
    betreiberView(): boolean {
      return this.profilViewType === ProfilViewType.BETREIBER_VIEW;
    },
    myProfil(): boolean {
      return this.profilViewType === ProfilViewType.MY_PROFIL;
    },
  },
  methods: {
    formateDate(date: string) {
      if (date == "") return "";
      return new Date(date).toLocaleDateString();
    },
    changeListenanzeige() {
      if (!this.editListenanzeige) {
        this.editListenanzeige = true;
      } else {
       
        this.editListenanzeigeSave();
      }
    },
    editListenanzeigeSave() {
       this.editListenanzeige = false;
        this.info.listenanzeige = this.listenanzeige;

      const profil: ProfilBenutzerDto = new ProfilBenutzerDto(
        this.info.vorname,
        this.info.nachname,
        "",
        "",
        this.info.hausnummer,
        this.info.straße,
        this.info.plz,
        this.info.ort,
        this.info.land,
        this.info.profilbild,
        this.info.ausbildung,
        this.info.schwerpunkte,
        this.info.beschreibung,
        this.info.geburtsdatum,
        this.info.telefon,
        this.info.listenanzeige
      );
      let requestUrl = "/profil/change";
      if (this.user !== null) {
        requestUrl = "/profil/other/" + this.user.id + "/betreiber";
      }
      Axios.post(requestUrl, profil, api.getRequestConfig())
      .catch(() => {
        window.alert("Verbindungsfehler!")
        this.$store.commit("setNetworkError");
      })
    },
  },
};
