



















































import { mapMutations } from "vuex";
import api from "../../config/api";
import Axios from "axios";
import rules from "@/config/rules";
export default {
  props: {
    info: {
      type: Object,
      default: null,
    },
  },
  data: () => ({
    rules: rules,
    editPassword: false,
    dialogEditPassword: false,
    passwordModel: "",
    passwortUeberpruefen: "",
    passwortAnzeigen: false,
  }),
  computed: {
    passwortUeberpruefenRegel() {
      return (
        (this.passwortAnzeigen && this.passwortUeberpruefen === "") ||
        this.passwordModel === this.passwortUeberpruefen ||
        "Die Passwörter stimmen nicht überein."
      );
    },
    ...mapMutations(["setNetworkError"])
  },
  methods: {
    changePasswort() {
      if (!this.editPassword) {
        this.editPassword = true;
      } else {
        this.dialogEditPassword = true;
      }
    },
    editPasswordSave() {
      this.editPassword = false;
      this.dialogEditPassword = false;
      const requestUrl = "/patient/passwort/aendern";
      Axios.post(
        requestUrl,
        { passwort: this.passwordModel },
        api.getRequestConfig()
      ).catch((error: Error) => {
        window.alert("Verbindungsfehler!")
        this.$store.commit("setNetworkError");
      })
    },
    editPasswordDiscard() {
      this.passwordModel = "";
      this.editPassword = false;
      this.dialogEditPassword = false;
    },
  }
}
