


































































export default {
  props: {
    videos: Array,
    showCreate: {
      type: Boolean,
      default: false,
    },
  },
  data: () => {
    return {
      filter: "",
      page: 1,
      entriesPerPage: 50,
      pageCount: 0,
    };
  },
  watch: {
    filteredRows(newvalue: any) {
      let listLength: number = 0;
      if (this.videos !== undefined && this.videos !== null) {
        listLength = this.videos.length;
      }
      if (this.filter) {
        listLength = newvalue.length + 1;
      }
      this.pageCount = Math.ceil(listLength / this.entriesPerPage);
      if (this.page > this.pageCount) {
        this.page = this.pageCount;
      }
    },
  },
  computed: {
    filteredRows() {
      if (this.videos === undefined || this.videos === null) {
        return [];
      }
      const filteredList = this.videos.filter(
        (row: {
          titel: { toString: () => string };
          kurzbeschreibung: { toString: () => string };
        }) => {
          const title = row.titel.toString().toLowerCase();
          const kurzbeschreibung = row.kurzbeschreibung
            .toString()
            .toLowerCase();
          return (
            !this.filter ||
            "" == this.filter ||
            title.indexOf(this.filter.toLowerCase()) > -1 ||
            kurzbeschreibung.indexOf(this.filter.toLowerCase()) > -1
          );
        }
      );

      return filteredList.slice(
        (this.page - 1) * this.entriesPerPage,
        this.page * this.entriesPerPage
      );
    },
  },
  methods: {
    callAddDialog(item: any) {
      this.$emit("selection", item);
    },
    clearFilter() {
      this.filter = "";
    },
    emitCreateCommand() {
      this.$emit("createCommand");
    },
  },
};
