























































































































import RezeptApi from "@/services/api/Rezept";

export default {
  data() {
    return {
      dateiListe: [],
      filterListe: [],
      filterStatus: "ALL",
      dynamicTitle: "Alle hochgeladenen Rezepte",
    };
  },
  created() {
    this.getAlleRezepte();
  },
  methods: {
    getAlleRezepte() {
      RezeptApi.getAllRezepte().then((data) => {
        this.dateiListe = data;
        this.filterListe = data;
      });
    },
    resetFilter() {
      this.filterListe = this.dateiListe;
      this.dynamicTitle = "Alle hochgeladenen Rezepte";
      this.filterStatus = "ALL";
    },
    getNewRezepte() {
      this.filterListe = this.dateiListe;
      this.filterListe = this.filterListe.filter((rezept: any) => {
        return rezept.status == "NEU";
      });
      this.dynamicTitle = "Alle neuen Rezepte";
      this.filterStatus = "NEU";
    },
    getInBearbeitungRezepte() {
      this.filterListe = this.dateiListe;
      this.filterListe = this.filterListe.filter((rezept: any) => {
        return rezept.status == "INBEARBEITUNG";
      });
      this.dynamicTitle = "Alle Rezepte in Bearbeitung";
      this.filterStatus = "INBEARBEITUNG";
    },
    getAbgerechnetRezepte() {
      this.filterListe = this.dateiListe;
      this.filterListe = this.filterListe.filter((rezept: any) => {
        return rezept.status == "ABGERECHNET";
      });
      this.dynamicTitle = "Alle abgerechneten Rezepte";
      this.filterStatus = "ABGERECHNET";
    },
    getAbgelehntRezepte() {
      this.filterListe = this.dateiListe;
      this.filterListe = this.filterListe.filter((rezept: any) => {
        return rezept.status == "ABGELEHNT";
      });
      this.dynamicTitle = "Alle abgelehnten Rezepte";
      this.filterStatus = "ABGELEHNT";
    },
    getGeloeschtRezepte() {
      this.filterListe = this.dateiListe;
      this.filterListe = this.filterListe.filter((rezept: any) => {
        return rezept.status == "GELOESCHT";
      });
      this.dynamicTitle = "Alle gelöschten Rezepte";
      this.filterStatus = "GELOESCHT";
    },
    goToPatient(patientId: number) {
      this.$router
        .push({
          path: "/betreiber/rezepte/" + patientId,
        })
        .catch(() => {
          console.log("Woops: something went wrong with the router...");
        });
    },
    formatDate: (item: any) => {
      if (!item || item == undefined || item.eingereicht == undefined) {
        return "";
      }
      const dateOnly: string = item.eingereicht.split("T")[0];
      const timeOnly: string = item.eingereicht.split("T")[1];
      const [year, month, day] = dateOnly.split("-");
      const h: string = timeOnly.split(":")[0];
      const m: string = timeOnly.split(":")[1];
      return `${day}.${month}.${year} um ${h}:${m}Uhr`;
    },
  },
};
