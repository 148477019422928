import Api from "@/config/api";
import axios from "axios";

export default {
  getProfilePhoto(profileId:string) {
    const url: string =
      Api.getRequestConfig().baseURL + "/files/profilePhoto/"+profileId;
      const headers = Api.getRequestConfig().headers;
      headers['Content-type']= 'image/jpeg'
    return axios
      .get(url, { headers: headers })
      .then(response => {
        var imageBase64 : string = "";
        imageBase64 = 'data:image/jpg;base64,'.concat(imageBase64.concat(response.data))
        return imageBase64;
      })
      .catch(error => {
				window.alert("Verbindungsfehler!")
        console.log(error);
        return null
      });
  },

  getFileList() {
    const url: string =
      Api.getRequestConfig().baseURL + "/files/";
      const headers = Api.getRequestConfig().headers;
      headers['Content-type']= 'image/jpeg'
    return axios
      .get(url, { headers: headers })
      .then(response => {
        return response.data;
      })
      .catch(error => {
				window.alert("Verbindungsfehler!")
        console.log(error);
        return null
      });
  },
  
};
