




















































































import { mapMutations } from "vuex";
import api from "../../config/api";
import Axios from "axios";
import ProfilBenutzerDto from "@/data/ProfilBenutzerDto";
export default {
  props: {
    info: {
      type: Object,
      default: null,
    },
    user: {
      type: Object,
      default: null,
    },
  },
  data: () => ({
    editDesc: false,
    dialogEditDesc: false,
    ausbildungModel: null,
    schwerpunkteModel: null,
    beschreibungModel: null,
  }),
  created() {
    this.ausbildungModel = this.info.ausbildung;
    this.schwerpunkteModel = this.info.schwerpunkte;
    this.beschreibungModel = this.info.beschreibung;
  },
  computed: {
    ...mapMutations(["setNetworkError"]),
  },
  methods: {
    changeDesc() {
      if (!this.editDesc) {
        this.editDesc = true;
      } else {
        this.dialogEditDesc = true;
      }
    },
    editDescSave() {
      this.info.ausbildung = this.ausbildungModel;
      this.info.schwerpunkte = this.schwerpunkteModel;
      this.info.beschreibung = this.beschreibungModel;
      this.dialogEditDesc = false;
      this.editDesc = false;

      const profil: ProfilBenutzerDto = new ProfilBenutzerDto(
        this.info.vorname,
        this.info.nachname,
        "",
        "",
        this.info.hausnummer,
        this.info.straße,
        this.info.plz,
        this.info.ort,
        this.info.land,
        this.info.profilbild,
        this.info.ausbildung,
        this.info.schwerpunkte,
        this.info.beschreibung,
        this.info.geburtsdatum,
        this.info.telefon,
        this.info.listenanzeige
      );
      let requestUrl = "/profil/change";
      if (this.user !== null) {
        requestUrl = "/profil/other/" + this.user.id + "/betreiber";
      }
      Axios.post(requestUrl, profil, api.getRequestConfig()).catch(
        (error: Error) => {
          window.alert("Verbindungsfehler!")
          this.$store.commit("setNetworkError");
        }
      );
    },
    editDescDiscard() {
      this.ausbildungModel = this.info.ausbildung;
      this.schwerpunkteModel = this.info.schwerpunkte;
      this.beschreibungModel = this.info.beschreibung;
      this.dialogEditDesc = false;
      this.editDesc = false;
    },
    editDescBack() {
      this.dialogEditDesc = false;
    },
  },
};
