








































































































































































































































































import Vue from "vue";
import Api from "@/config/api";
import RezeptApi from "@/services/api/Rezept";
import GuthabenDto from "@/dtos/GuthabenDto";
import axios from "axios";
import { Privilege } from "@/data/user/Privilege";
import { Role } from "@/data/user/Role";


export default {
  data() {
    return {
      toggleAblehnen: false,
      toggleAblehnenTimeout: false,
      toggleAbrechnen: false,
      toggleAbrechnenTimeout: false,
      toggleLoeschen: false,
      toggleLoeschenTimeout: false,
      loeschenConfirmation: "",
      showMe: false,
      editingGuthaben: false,
      guthaben: null,
      editRezeptGuthaben: false,
      editRezeptGuthabenEinzel: 0,
      editRezeptGuthabenGruppe: 0,
      editRezeptGuthabenBefund: 0,
    };
  },
  created() {
    if (this.show) {
      this.showMe = true;
    }
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    file: {
      type: Object,
      default: null,
    },
    vorname: {
      type: String,
      default: "",
    },
    nachname: {
      type: String,
      default: "",
    },
    patientId: {
      type: String,
      default: undefined,
    },
  },
  watch: {
    show: function (newVal: boolean) {
      this.showMe = newVal;
    },
  },
  methods: {
    getGuthaben: function () {
      const url = "/guthaben/betreiber/get/" + this.patientId;
      return axios
        .get<GuthabenDto>(url, Api.getRequestConfig())
        .then((response) => {
          this.guthaben = response.data;
        })
        .catch((error) => {
          window.alert("Verbindungsfehler!")
          console.log(error);
        });
    },
    startEditRezeptGuthaben() {
      this.editRezeptGuthaben = true;
      this.editRezeptGuthabenEinzel = this.guthaben.rezeptEinzelTermin;
      this.editRezeptGuthabenGruppe = this.guthaben.rezeptGruppenTermin;
      this.editRezeptGuthabenBefund = this.guthaben.rezeptBefundTermin;
      this.setStatusInBearbeitung();
    },
    setStatusInBearbeitung() {
      const url = "/rezepte/bearbeitung/" + this.file.id;
      axios
        .get(url, Api.getRequestConfig())
        .then((response) => {
          this.file.status = response.data;
        })
        .catch((error) => {
          window.alert("Verbindungsfehler!")
          console.log("Something went wrong: " + error);
        });
    },
    cancelEditRezeptGuthaben() {
      this.editRezeptGuthaben = false;
    },
    saveEditRezeptGuthaben() {
      this.guthaben.rezeptEinzelTermin = this.editRezeptGuthabenEinzel;
      this.guthaben.rezeptGruppenTermin = this.editRezeptGuthabenGruppe;
      this.guthaben.rezeptBefundTermin = this.editRezeptGuthabenBefund;
      this.editRezeptGuthaben = false;
      this.storeNewGuthaben();
    },
    storeNewGuthaben() {
      const url = "/guthaben/betreiber/store/" + this.patientId;
      return axios
        .post<GuthabenDto>(url, this.guthaben, Api.getRequestConfig())
        .then((response) => {
          this.guthaben = response.data;
          //status is set manually by BETREIBER
        })
        .catch((error) => {
          window.alert("Verbindungsfehler!")
          this.$store.state.error = {
            isVisible: true,
            headline: "Fehler bei der Änderung",
            text: error.response.data,
            okAction: function () {},
          };
        });
    },
    setStatusAbgerechnet() {
      const url = "/rezepte/abgerechnet/" + this.file.id;
      axios
        .get(url, Api.getRequestConfig())
        .then((response) => {
          this.file.status = response.data;
        })
        .catch((error) => {
          window.alert("Verbindungsfehler!")
          console.log("Something went wrong: " + error);
        });
    },
    setStatusAbgelehnt() {
      const url = "/rezepte/abgelehnt/" + this.file.id;
      axios
        .get(url, Api.getRequestConfig())
        .then((response) => {
          this.file.status = response.data;
        })
        .catch((error) => {
          window.alert("Verbindungsfehler!")
          console.log("Something went wrong: " + error);
        });
    },
    deleteRezept() {
      const url = "/rezepte/loeschen/" + this.file.id;
      axios
        .get(url, Api.getRequestConfig())
        .then((response) => {
          this.file.status = response.data;
          this.file.originalFilename = "Gelöscht.";
          this.file.randomFilename = "Gelöscht.";
          this.file.anmerkung = "Gelöscht.";
        })
        .catch((error) => {
          window.alert("Verbindungsfehler!")
          console.log("Something went wrong: " + error);
        });
    },
    canEditGuthaben() {
      return this.$store.getters.hasPrivilege(Privilege.GUTHABEN_AENDERN);
    },
    isBetreiber() {
      return this.$store.getters.isRole(Role.BETREIBER);
    },
    formatEingereicht: (item: any) => {
      if (!item || item == undefined || item.eingereicht == undefined) {
        return "Nein";
      }
      const dateOnly: string = item.eingereicht.split("T")[0];
      const timeOnly: string = item.eingereicht.split("T")[1];
      const [year, month, day] = dateOnly.split("-");
      const h: string = timeOnly.split(":")[0];
      const m: string = timeOnly.split(":")[1];
      return `${day}.${month}.${year} um ${h}:${m}Uhr`;
    },
    formatGebucht: (item: any) => {
      if (!item || item == undefined || item.gebucht == undefined) {
        return "Nein";
      }
      const dateOnly: string = item.gebucht.split("T")[0];
      const timeOnly: string = item.gebucht.split("T")[1];
      const [year, month, day] = dateOnly.split("-");
      const h: string = timeOnly.split(":")[0];
      const m: string = timeOnly.split(":")[1];
      return `${day}.${month}.${year} um ${h}:${m}Uhr`;
    },
    closeMe() {
      this.$emit("closeme");
    },
    offerDownload() {
      let fileName = "";
      for (
        let i = 0;
        i < this.file.originalFilename.split(".").length - 1;
        i++
      ) {
        fileName += this.file.originalFilename.split(".")[i];
      }
      RezeptApi.downloadRezeptFile(
        this.file.id,
        fileName,
        this.file.originalFilename.split(".")[
          this.file.originalFilename.split(".").length - 1
        ]
      );
    },
    startAblehnenTimer() {
      this.toggleAblehnen = true;
      setTimeout(() => {
        if (this.toggleAblehnen) {
          this.toggleAblehnenTimeout = true;
        }
      }, 3000);
    },
    rezeptAblehnen() {
      this.setStatusAbgelehnt();
      this.toggleAblehnen = false;
      this.toggleAblehnenTimeout = false;
    },
    abortAblehnen() {
      this.toggleAblehnen = false;
      this.toggleAblehnenTimeout = false;
    },
    startAbrechnenTimer() {
      this.toggleAbrechnen = true;
      setTimeout(() => {
        if (this.toggleAbrechnen) {
          this.toggleAbrechnenTimeout = true;
        }
      }, 3000);
    },
    rezeptAbrechnen() {
      this.setStatusAbgerechnet();
      this.toggleAbrechnen = false;
      this.toggleAbrechnenTimeout = false;
    },
    abortAbrechnen() {
      this.toggleAbrechnen = false;
      this.toggleAbrechnenTimeout = false;
    },

    startLoeschenTimer() {
      this.toggleLoeschen = true;
      setTimeout(() => {
        if (this.toggleLoeschen) {
          this.toggleLoeschenTimeout = true;
        }
      }, 3000);
    },
    rezeptLoeschen() {
      this.deleteRezept();
      this.toggleLoeschen = false;
      this.toggleLoeschenTimeout = false;
    },
    abortLoeschen() {
      this.toggleLoeschen = false;
      this.toggleLoeschenTimeout = false;
    },
  },
};
