export default class GuthabenAngebot {
	private guthabenAngebotId: number | undefined;
	private titel: String;
	private centPreis: number;
	private buchbar: boolean;
	private einzelTermine: number;
	private gruppenTermine: number;
	private telefonTermine: number;
	private anamnese: number;
	private befunde: number;

	constructor(
		guthabenAngebotId: number | undefined,
		titel: String,
		centPreis: number,
		buchbar: boolean,
		einzelTermine: number,
		gruppenTermine: number,
		telefonTermine: number,
		anamnese: number,
		befunde: number) {
		this.guthabenAngebotId = guthabenAngebotId;
		this.titel = titel;
		this.centPreis = centPreis;
		this.buchbar = buchbar;
		this.einzelTermine = einzelTermine;
		this.gruppenTermine = gruppenTermine;
		this.telefonTermine = telefonTermine;
		this.anamnese = anamnese;
		this.befunde = befunde;
	}

	public getId() {
		return this.guthabenAngebotId;
	}
	public getTitel() {
		return this.titel;
	}
	public getCentPreis() {
		return this.centPreis;
	}
	public getBuchbar() {
		return this.buchbar;
	}
	public getEinzeltermine() {
		return this.einzelTermine;
	}
	public getGruppentermine() {
		return this.gruppenTermine;
	}
	public getAnamnese() {
		return this.anamnese;
	}
	public getBefunde() {
		return this.befunde;
	}
	public getTelefontermine() {
		return this.telefonTermine;
	}


}
