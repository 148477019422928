/*deprecated*/
export default class Button{
  imgActive: string = "";
  imgInactive: string = "";

  imgActiveHover: string = "";

  imgInactiveHover : string ="";
  name : string = "";
  clickable:boolean = true;
  active : boolean = true;
  hover: boolean = false;
  activateAction:any;
  deactivateAction:any;

  constructor(imgActive: string, imgActiveHover:string, imgInactive:string, imgInactiveHover:string, name:string, clickable: boolean) {
    this.imgActive = imgActive;
    this.imgInactive = imgInactive;
    this.imgActiveHover = imgActiveHover;
    this.imgInactiveHover = imgInactiveHover;
    this.name = name;
    this.clickable = clickable;
  }

  getImage(){
    if(this.active){
      if(this.hover){
        return this.imgActiveHover;
      }
      else{
        return this.imgActive
      }
    }
    else{
      if(this.hover){
        return this.imgInactiveHover;
      }
      else{
        return this.imgInactive
      }
    }
  }
  setHover(hover : boolean){
    this.hover=hover;
  }
  click(){
    if(this.clickable){
      if(this.active){
      this.deactivateAction();
      }
      else{
        this.activateAction();
      }
      this.active=!this.active;
    }

  }
  setActivateAction(action:() =>void){
    this.activateAction = action;
  }
  setDeactivateAction(action: () =>void){
    this.deactivateAction = action;
  }
  public setActive(state: boolean){
    this.active = state;
  }
  getName(){
    return this.name;
  }
  setClickable(isClickable: boolean) {
    this.clickable = isClickable;
  }
}
