



































import { mapMutations } from "vuex";
import api from "../../config/api";
import Axios from "axios";
import ProfilBenutzerDto from "@/data/ProfilBenutzerDto";
export default {
  props: {
    info: {
      type: Object,
      default: null,
    },
    user: {
      type: Object,
      default: null,
    },
    profilViewType: {
      type: Number,
      default: null,
    },
  },
  data: () => ({
    editBirthday: false,
    dialogEditBirthday: false,
    birthdayModel: null,
  }),
  created() {
    this.birthdayModel = this.info.geburtsdatum;
  },
  computed: {
    ...mapMutations(["setNetworkError"])
  },
  methods: {
    formateDate(date: string) {
      if (date == "") return "";
      return new Date(date).toLocaleDateString();
    },
    changeBirthday() {
      if (!this.editBirthday) {
        this.editBirthday = true;
      } else {
        this.dialogEditBirthday = true;
      }
    },
    editBirthdaySave() {
      this.info.geburtsdatum = this.birthdayModel;
      this.dialogEditBirthday = false;
      this.editBirthday = false;

      const profil: ProfilBenutzerDto = new ProfilBenutzerDto(
        this.info.vorname,
        this.info.nachname,
        "",
        "",
        this.info.hausnummer,
        this.info.straße,
        this.info.plz,
        this.info.ort,
        this.info.land,
        this.info.profilbild,
        this.info.ausbildung,
        this.info.schwerpunkte,
        this.info.beschreibung,
        this.info.geburtsdatum,
        this.info.telefon,
        this.info.listenanzeige
      );
      let requestUrl = "/profil/change";
      if (this.user !== null) {
        requestUrl = "/profil/other/" + this.user.id + "/betreiber";
      }
      Axios.post(requestUrl, profil, api.getRequestConfig())
      .catch((error: Error) => {
        window.alert("Verbindungsfehler!")
        this.$store.commit("setNetworkError");
      })
    },
    editBirthdayDiscard() {
      this.birthdayModel = this.info.geburtsdatum;
      this.dialogEditBirthday = false;
      this.editBirthday = false;
    },
    editBirthdayBack() {
      this.dialogEditBirthday = false;
    },
  }
}
