


































































































import UebungApi from "@/services/api/Uebung";
import Uebung from "@/data/Uebung";
export default {
  props: {
    uebung: Number,
    uebungBearbeiten: Boolean,
  },
  data: function() {
    return {
      valide: false,
      showDialog: false,
      showErrorDialog: false,
      selectedUebung: null,
      fehlerNachrichtLoeschen: "",
      titelRegeln: [
        (v: string) => !!v || "Der Titel darf nicht leer sein.",
        (v: string) => /^.{0,50}$/.test(v) || "Maximale Länge beachten",
      ],
      beschreibungRegeln: [
        (v: string) => !!v || "Die Beschreibung darf nicht leer sein.",
        (v: string) => /^.{0,999}$/s.test(v) || "Maximale Länge beachten",
      ],
      videoUrlRegeln: [
        (v: string) => !!v || "Die Video-Url darf nicht leer sein.",
        (v: string) => /^.{0,100}$/.test(v) || "Maximale Länge beachten",
        (v: string) =>
          /(http|https)?:\/\/(www\.)?vimeo.com\/([A-Za-z0-9_/-?]*)/.test(v) ||
          "Gültige Url eingeben",
      ],
    };
  },
  watch: {
    uebung: function(newVal: number) {
      if (newVal != null && newVal > 0) {
        UebungApi.getUebung(newVal)
          .then(response => {
            if(response !== null){
              const uebung = response.data;
              UebungApi.getVideoInfo(uebung.videoUrl).then(responseImg => {
                
                const createUebungDto = new Uebung(
                  uebung.id,
                  uebung.titel,
                  uebung.beschreibung,
                  uebung.videoUrl,
                  responseImg?.data.thumbnail_url
                );
                this.selectedUebung = createUebungDto;
                this.showDialog = true;
              });
            }
            
          })
          .catch((error: any) => {
            console.log(error);
          });
      } else {
        this.selectedUebung = Uebung.emptyInstance();
      }
      if (newVal != null && newVal >= 0) {
        this.showDialog = true;
      } else {
        this.showDialog = false;
      }
      if (this.$refs.form != undefined) {
        this.$refs.form.resetValidation();
      }
    },
  },
  methods: {
    submit() {
      UebungApi.storeUebung(this.selectedUebung)
        .then(() => {
          this.$emit("submitted");
          this.selectedUebung = Uebung.emptyInstance();
          this.showDialog = false;
        })
        .catch((error: any) => {
          console.log(error);
        });
    },
    onBlur() {
      if (this.selectedUebung.videoUrl) {
        UebungApi.getVideoInfo(this.selectedUebung.videoUrl).then(
          responseImg => {
            this.selectedUebung.videoVorschauBildUrl =
              responseImg?.data.thumbnail_url;
          }
        );
      }
    },
    deleteUebung() {
      UebungApi.deleteUebung(this.selectedUebung.id)
        .then(() => {
          this.$emit("deleted");
        })
        .catch((error: any) => {
          console.log(error.response);
          this.fehlerNachrichtLoeschen = error.response.data.message.substring(
            4
          );
          this.showErrorDialog = true;
        });
    },
    back() {
      this.showDialog = false;
      this.$emit("dialogBack");
    },
    empty() {
      this.$refs.form.reset();
      this.selectedUebung.videoVorschauBildUrl = "";
    },
  },
};
