
















































































import Vue from "vue";
import axios from "axios";
import Api from "@/config/api";
import SeiteComponent from "@/components/inhalt/Seite.vue";

export default Vue.extend({
  data() {
    return {
      vorname: "" as String,
      nachname: "" as String,
      gotName: false as boolean,
      sturzClicked: false as boolean,
      upperTextFirst:
        "Es hat alles geklappt: Sie sind gut verbunden! In kurzer Zeit wird Ihre Therapeutin oder Ihr Therapeut die Sitzung beginnen. Sie brauchen nichts weiter zun tun.",
      upperTextSecond:
        "Nutzen Sie die Zeit und vergewissern Sie sich, dass die Kamera gut ausgerichtet ist und die Lautstärke für Sie angenehm eingestellt ist.",
      lowerText:
        "Bitte schaffen sie sich für die Therapiesitzung ausreichend Platz und stellen Sie sicher, dass keine „Stolperfallen“ bestehen.",
      bottomLargeText: "Wir wünschen viel Spass und Erfolg!",
    };
  },
  components: {
    SeiteComponent,
  },

  created() {
    this.getUsername();
  },

  methods: {
    activateSturzDialog() {
      this.sturzClicked = !this.sturzClicked;
    },
    getUsername() {
      axios
        .get("profil/nameonly", Api.getRequestConfig())
        .then(response => {
          console.log("Name Getting Status:" + response.status);
          if (response != null && response.status === 200) {
            this.nachname = response.data.nachname;
            this.vorname = response.data.vorname;
            this.gotName = true;
          }
        })
        .catch(err => {
          window.alert("Verbindungsfehler!")
          console.error("Error fetching name: " + err);
        });
    },
  },
});
