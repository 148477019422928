import Api from "@/config/api";
import AboPaket from '@/data/AboPaket';
import AboBuchungDto from '@/dtos/AboBuchungDto';
import AboNoticeDto from '@/dtos/AboNoticeDto';
import AboPaketDto from '@/dtos/AboPaketDto';
import CreateAboPaketDto from '@/dtos/CreateAboPaketDto';
import axios from "axios";
import ZweiFaktorInstallationsDto from '@/dtos/ZweiFaktorInstallationsDto';

export default {
	getMyStatus() {
		const url = Api.getRequestConfig().baseURL + "/secondFactor/status";
    return axios
        .get<boolean>(url, { headers: Api.getRequestConfig().headers })
		.catch(() => {
			window.alert("Verbindungsfehler!")
			return null
		});
	},
	getStatusForUser(userId: number) {
		const url = Api.getRequestConfig().baseURL + "/secondFactor/betreiber/status/"+userId;
    return axios
        .get<boolean>(url, { headers: Api.getRequestConfig().headers })
		.catch(() => {
			window.alert("Verbindungsfehler!")
			return null
		});
	},
	getActivationPrivateKey(userPassword : string) {
		const url: string = Api.getRequestConfig().baseURL + "/secondFactor/activate";
		return axios
			.post<ZweiFaktorInstallationsDto>(url, {"password": userPassword}, { headers: Api.getRequestConfig().headers })
			.catch(() => {
				window.alert("Verbindungsfehler!")
				return null
			});
	},
	getActivationPrivateKeyForUser(userId : number) {
		const url: string = Api.getRequestConfig().baseURL + "/secondFactor/betreiber/activate/"+userId;
		return axios
			.post<ZweiFaktorInstallationsDto>(url, {}, { headers: Api.getRequestConfig().headers })
			.catch(() => {
				window.alert("Verbindungsfehler!")
				return null
			});
	},
	activatePrivateKey(userPassword : string, privateKey: string, token: string) {
		const url: string = Api.getRequestConfig().baseURL + "/secondFactor/activate";
		const obj = {
			"password": userPassword,
			"privateKey": privateKey,
			"token": token
		};
		return axios
			.post<boolean>(url, obj, { headers: Api.getRequestConfig().headers })
			.catch(() => {
				window.alert("Verbindungsfehler!")
				return null
			});
	},
	activatePrivateKeyForUser(userId : number, privateKey: string, token: string) {
		const url: string = Api.getRequestConfig().baseURL + "/secondFactor/betreiber/activate/"+userId;
		const obj = {
			"privateKey": privateKey,
			"token": token
		};
		return axios
			.post<boolean>(url, obj, { headers: Api.getRequestConfig().headers })
			.catch(() => {
				window.alert("Verbindungsfehler!")
				return null
			});
	},
	deactivate(userPassword : string, token: string) {
		const url: string = Api.getRequestConfig().baseURL + "/secondFactor/deactivate";
		const obj = {
			"password": userPassword,
			"token": token
		};
		return axios
			.post<string>(url, obj, { headers: Api.getRequestConfig().headers })
			.catch(() => {
				window.alert("Verbindungsfehler!")
				return null
			});
	},
	deactivateForUser(userId: number) {
		const url: string = Api.getRequestConfig().baseURL + "/secondFactor/betreiber/deactivate/"+userId;
		return axios
			.post<string>(url, {}, { headers: Api.getRequestConfig().headers })
			.catch(() => {
				window.alert("Verbindungsfehler!")
				return null
			});
	},
};
