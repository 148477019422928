


















































import FragebogenApi from "../../services/api/Fragebogen";
import DokumentApi from "@/services/api/Dokument";
export default {
  props: ["selectedElement"],
  data() {
    return {
      showFragebogen: true,
    };
  },
  computed: {
    getSelectedElement() {
      return this.selectedElement;
    },
  },
  methods: {
    isConditionMet: function(item: any) {
      if (
        item.frage.frageAbhaengigVonFrage !== null &&
        item.frage.frageAnzeigenWennBedingung !== null
      ) {
        const booleanAntworten = this.getBooleanAntworten();
        if (
          booleanAntworten[item.frage.frageAbhaengigVonFrage].antwort ==
            item.frage.frageAnzeigenWennBedingung.toString() ||
          booleanAntworten[item.frage.frageAbhaengigVonFrage].antwort ==
            item.frage.frageAnzeigenWennBedingung
        ) {
          return true;
        }
        return false;
      }
      return true;
    },
    isFormReady: function() {
      for (const item of this.selectedElement.antworten) {
        if (this.isConditionMet(item) && item.antwort === null) {
          return false;
        }
      }
      return true;
    },
    isVeroeffentlicht: function() {
      return !(this.selectedElement.veroeffentlicht === null);
    },
    getBooleanAntworten: function() {
      const booleanAntworten = [];
      for (const antwort of this.selectedElement.antworten) {
        if (antwort.frage.typ == "WAHRHEITSWERT") {
          booleanAntworten[antwort.frage.id] = antwort;
        }
      }
      return booleanAntworten;
    },

    publish: function() {
      this.save();
      FragebogenApi.publishPatientFragebogen(this.selectedElement).then(
        response => {
          this.$emit("update:draftStatus", response);
          this.$emit("update:selection", response);
        }
      );
    },
    save: function() {
      FragebogenApi.savePatientFragebogen(this.selectedElement);
    },
    reloadFragebogenView: function() {
      this.showFragebogen = false;
      this.showFragebogen = true;
    },
    getPdf: function() {
      DokumentApi.getBefundfragebogen(this.selectedElement.id);
    }
  },
};
